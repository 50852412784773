import React, { useLayoutEffect, useState } from 'react'
import { BrowserRouterProps, Router } from 'react-router-dom'
import { BrowserHistory } from 'history'

type TProps = BrowserRouterProps & {
  history: BrowserHistory
}

export const CustomRouter: React.FC<TProps> = ({
  basename,
  history,
  children,
}) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  useLayoutEffect(() => history.listen(setState), [history])

  return (
    <Router
      navigator={history}
      location={state.location}
      navigationType={state.action}
      basename={basename}
    >
      {children}
    </Router>
  )
}
