import { Option, OptionType } from 'components/Option'
import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useContext, useMemo, useState } from 'react'
import { PageId } from 'root-constants/pages'
import { QuestionButton } from 'components/QuestionButton'
import { StyledOption } from 'components/Option.styles'
import { FixedContainer } from 'components/FixedContainer'
import { Button } from 'components/Button'
import { StyledReasons as S } from './Reasons.styles'

const OPTIONS_VALUES = {
  peace: 'To have peace of mind',
  safe: 'To ensure they are safe',
  notification: 'To be notified if they need help',
  all: 'All of the above',
}

const Component: TDynamicPageComponent = () => {
  const { onNextStep, currentPageId } = useContext(FlowContext)

  const [answers, setAnswers] = useState<string[]>([])

  const optionProps = useMemo(
    () => ({
      name: currentPageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked && value === OPTIONS_VALUES.all) {
          setAnswers([OPTIONS_VALUES.all])
          return
        }

        if (isChecked) {
          const newValues = [...answers, value].filter(
            (item) => item !== OPTIONS_VALUES.all,
          )
          setAnswers(newValues)
          return
        }

        const newValues = answers.filter((item) => item !== value)
        setAnswers(newValues)
      },
    }),
    [answers, currentPageId],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>Why do you need to keep an eye on them?</S.Title>
        <StyledOption.OptionsContainer style={{ paddingBottom: 4 }}>
          <Option
            {...optionProps}
            value={OPTIONS_VALUES.peace}
            checked={answers.includes(OPTIONS_VALUES.peace)}
          >
            <QuestionButton hasCheckboxIcon>
              {OPTIONS_VALUES.peace}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTIONS_VALUES.safe}
            checked={answers.includes(OPTIONS_VALUES.safe)}
          >
            <QuestionButton hasCheckboxIcon>
              {OPTIONS_VALUES.safe}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTIONS_VALUES.notification}
            checked={answers.includes(OPTIONS_VALUES.notification)}
          >
            <QuestionButton hasCheckboxIcon>
              {OPTIONS_VALUES.notification}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTIONS_VALUES.all}
            checked={answers.includes(OPTIONS_VALUES.all)}
          >
            <QuestionButton hasCheckboxIcon>
              {OPTIONS_VALUES.all}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <FixedContainer>
          <Button onClick={onNextStep} disabled={!answers.length}>
            Next
          </Button>
        </FixedContainer>
      </S.Column>
    </S.Wrapper>
  )
}

Component.id = PageId.REASONS_2
Component.hasHeader = true

export const ReasonsVariant2 = Component
