import React, { ButtonHTMLAttributes } from 'react'
import { StyledButton } from './Button.styles'

export const Button = React.forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(({ children, ...props }, ref) => (
  <StyledButton ref={ref} {...props}>
    {children}
  </StyledButton>
))
