import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOptimizeExperimentIdByVariantIdMap } from 'root-redux/actions/common'
import { selectOptimizeExperimentMap } from 'root-redux/selects/common'

export const useSetOptimizeVariantId = (): void => {
  const dispatch = useDispatch()
  const optimizeExperimentMap = useSelector(selectOptimizeExperimentMap)

  useEffect(() => {
    if (!optimizeExperimentMap) return

    const setOptimizePageId = (variantId: string, experimentId: string) =>
      dispatch(
        setOptimizeExperimentIdByVariantIdMap({ variantId, experimentId }),
      )

    Object.values(optimizeExperimentMap)
      .filter(Boolean)
      .forEach((experimentId) => {
        window.gtag('event', 'optimize.callback', {
          name: experimentId,
          callback: setOptimizePageId,
        })
      })
  }, [dispatch, optimizeExperimentMap])
}
