import { Option, OptionType } from 'components/Option'
import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'root-constants/pages'
import { setAnswersAction } from 'root-redux/actions/common'
import { QuestionButton } from 'components/QuestionButton'
import { StyledOption } from 'components/Option.styles'
import { GENDER_PARAM_KEY, UrlDataGender } from 'root-constants/urlData'
import { useURLSearchData } from 'hooks/useURLSearchData'
import { StyledGender as S } from './Gender.styles'

const PAGE_NAME = 'Gender'
const QUESTION = `What’s your gender?`
const OPTIONS_VALUES = {
  male: 'Male',
  female: 'Female',
  other: 'Other',
}

const OPTION_VALUE_TO_GENDER_MAP = {
  [OPTIONS_VALUES.male]: UrlDataGender.MALE,
  [OPTIONS_VALUES.female]: UrlDataGender.FEMALE,
  [OPTIONS_VALUES.other]: UrlDataGender.MALE,
}

const Component: TDynamicPageComponent = () => {
  const { onNextStep, currentPageId } = useContext(FlowContext)
  const dispatch = useDispatch()
  const { setDataToURL } = useURLSearchData()

  const handleContinue = useCallback(
    (answer) => {
      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: answer,
          pageId: currentPageId,
          pageName: PAGE_NAME,
        }),
      )
      onNextStep()
    },
    [currentPageId, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      name: currentPageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        // Doesn't work inside setTimeout
        setDataToURL(GENDER_PARAM_KEY, OPTION_VALUE_TO_GENDER_MAP[value])
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue, setDataToURL],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option {...optionProps} value={OPTIONS_VALUES.male}>
            <QuestionButton>{OPTIONS_VALUES.male}</QuestionButton>
          </Option>
          <Option {...optionProps} value={OPTIONS_VALUES.female}>
            <QuestionButton>{OPTIONS_VALUES.female}</QuestionButton>
          </Option>
          <Option {...optionProps} value={OPTIONS_VALUES.other}>
            <QuestionButton>{OPTIONS_VALUES.other}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}

Component.id = PageId.GENDER_1
Component.hasHeader = true
Component.hasProgressBar = true

export const GenderVariant1 = Component
