import { StickyButton } from 'components/StickyButton'
import { baseColumnStyles, basePageStyles } from 'components/styles'
import { HEADER_HEIGHT } from 'root-constants/common'
import styled from 'styled-components'

export const StyledDownloadAppPage = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.main`
    ${baseColumnStyles};
    padding-bottom: 41px;
  `,
  Title: styled.h1`
    position: absolute;
    top: 96px;
    font-size: 25px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
  `,
  List: styled.ul`
    margin-bottom: 24px;

    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
  `,
  ListLabel: styled.div`
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    margin-right: 14px;
    font-size: 16px;
    font-weight: 800;
    line-height: 28px;
    text-align: center;
    border-radius: 8px;
    color: #bcc9d0;
    background-color: #eff5f7;
  `,
  ListText: styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    opacity: 0.8;
  `,
  Image: styled.img`
    margin: 40px 0 32px;
  `,
  StickyButton: styled(StickyButton)`
    @media (max-height: 620px) {
      position: static;
    }
  `,
}
