import { Button } from 'components/Button'
import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
  BottomStickyBase,
} from 'components/styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'
import styled from 'styled-components'

export const StyledPartnersData = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    font-size: 22px;
    margin-bottom: 18px;

    @media (min-width: ${MediaBreakpoint.DEFAULT_PHONE}px) {
      font-size: 24px;
      margin: 0 -11px 18px;
    }
  `,
  Button: styled(Button)`
    @media (min-height: 670px) {
      ${BottomStickyBase};
    }
  `,
}
