import husbandCallImg from 'assets/images/subscription-husband-call.jpg'
import wifeCallImg from 'assets/images/subscription-wife-call.jpg'
import { FlowContext } from 'components/FlowContext'
import { ReviewsSlider } from 'components/ReviewSlider'
import { getProductId } from 'helpers/getProductId'
import { useURLSearchData } from 'hooks/useURLSearchData'
import { TDynamicPageComponent } from 'models/common.model'
import { selectSubscription } from 'modules/payment/redux/selects'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PageId } from 'root-constants/pages'
import { GENDER_PARAM_KEY, UrlDataGender } from 'root-constants/urlData'
import { setIsPaidTrialModalShownAction } from 'root-redux/actions/common'
import {
  selectCurrentVariantSubscriptions,
  selectIsPaidTrialModalShown,
} from 'root-redux/selects/common'
import { eventLogger, ScreenName } from 'services/eventLogger.service'
import { Guarantee } from '../components/Guarantee'
import { PaidTrialModal } from '../components/paid-trial/PaidTrialModal'
import { TrialPlanBlock } from '../components/paid-trial/TrialPlanBlock'
import { Security } from '../components/Security'
import { SubheaderWithTimer } from '../components/SubheaderWithTimer'
import { Timer } from '../components/Timer'
import { SubscriptionsWrapper } from '../SubscriptionsWrapper'
import { ISubscriptionsPageProps } from '../types'
import { StyledSubscriptions as S } from './Subscriptions.styles'

const PaidTrialSubscriptionsPageVariant1Component: React.FC<
  ISubscriptionsPageProps
> = ({ onButtonClick, onSubscriptionSelect }) => {
  const dispatch = useDispatch()
  const { currentPageId } = useContext(FlowContext)
  const scrollToElement = useRef<HTMLDivElement>(null)
  const timerTriggerElementRef = useRef(null)
  const currentVariantSubscriptions = useSelector(
    selectCurrentVariantSubscriptions,
  )
  const isTrialModalShown = useSelector(selectIsPaidTrialModalShown)
  const selectedSubscription = useSelector(selectSubscription)
  const { getDataFromURL } = useURLSearchData()

  const gender = useMemo(
    () => getDataFromURL(GENDER_PARAM_KEY),
    [getDataFromURL],
  )

  useEffect(() => {
    if (isTrialModalShown) return

    eventLogger.logSalePageShown({
      productIds: currentVariantSubscriptions.map(
        ({ priceId, trialPriceId, trialPeriodDays }) =>
          getProductId({
            priceId,
            trialPriceId,
            trialPeriodDays,
          }),
      ),
      pageName: ScreenName.ONBOARDING,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTrialModalShown])

  const handleCloseModal = useCallback(() => {
    dispatch(setIsPaidTrialModalShownAction(false))
    eventLogger.logTrialPricePageCompleted(
      `$${selectedSubscription?.trialCurrentPrice}`,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageId, dispatch, selectedSubscription?.trialCurrentPrice])

  const handleTimerButtonClick = useCallback(() => {
    scrollToElement.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }, [])

  return (
    <>
      <PaidTrialModal
        isShown={isTrialModalShown}
        onClose={handleCloseModal}
        onSubscriptionSelect={onSubscriptionSelect}
      />
      <SubheaderWithTimer
        elemForComparisonRef={timerTriggerElementRef}
        onClick={handleTimerButtonClick}
      />
      <S.Wrapper>
        <S.Column style={{ paddingTop: 40, marginBottom: 40 }}>
          <S.Title style={{ marginBottom: 12 }}>
            {gender === UrlDataGender.MALE
              ? 'Keep an eye on your loved one to make sure she is faithful'
              : 'Make Sure Your Partner Stays Loyal'}
          </S.Title>
          <S.Text style={{ marginBottom: 28 }}>
            Monitor Your Partner with Famio
          </S.Text>
          <Timer />
        </S.Column>
        <img
          ref={timerTriggerElementRef}
          src={gender === UrlDataGender.MALE ? husbandCallImg : wifeCallImg}
          alt="calling partner"
        />
        <S.Column data-has-bg>
          <S.Subtitle style={{ marginBottom: 16, marginTop: 56 }}>
            {gender === UrlDataGender.MALE
              ? 'Famio benefits'
              : 'With Famio you can:'}
          </S.Subtitle>
          <ul style={{ marginBottom: 28 }}>
            <li>
              <S.ContentItem>
                {gender === UrlDataGender.MALE ? (
                  <strong>24/7 Monitoring</strong>
                ) : (
                  <>
                    <strong>Track</strong> your partner’s movement history
                  </>
                )}
              </S.ContentItem>
            </li>
            <li>
              <S.ContentItem>
                {gender === UrlDataGender.MALE ? (
                  <strong>Arrives or departs alerts</strong>
                ) : (
                  <>
                    <strong>Get real-time alerts</strong> of suspicious activity
                  </>
                )}
              </S.ContentItem>
            </li>
            <li>
              <S.ContentItem>
                Instantly <strong>detect SOS notifications</strong>
              </S.ContentItem>
            </li>
            <li>
              <S.ContentItem>
                {gender === UrlDataGender.MALE ? (
                  <strong>Driving mode</strong>
                ) : (
                  <>
                    <strong>Reduce</strong> anxiety and fear of infidelity
                  </>
                )}
              </S.ContentItem>
            </li>
            {gender === UrlDataGender.MALE && (
              <li>
                <S.ContentItem>
                  <strong>No one can make you look like a fool</strong>
                </S.ContentItem>
              </li>
            )}
          </ul>
          <TrialPlanBlock ref={scrollToElement} />
          <S.Button onClick={onButtonClick} style={{ marginBottom: 32 }}>
            Start My Plan
          </S.Button>
          <Guarantee style={{ marginBottom: 36 }} />
          <ReviewsSlider style={{ marginBottom: 24 }} />
          <S.Button onClick={onButtonClick} style={{ marginBottom: 24 }}>
            Start My Plan
          </S.Button>
          <Security />
        </S.Column>
      </S.Wrapper>
    </>
  )
}

const Component: TDynamicPageComponent = () => (
  <SubscriptionsWrapper>
    {(props: ISubscriptionsPageProps) => (
      <PaidTrialSubscriptionsPageVariant1Component {...props} />
    )}
  </SubscriptionsWrapper>
)

Component.hasHeader = true
Component.id = PageId.PAID_TRIAL_SUBSCRIPTIONS_1

export const PaidTrialSubscriptionsVariant1 = Component
