import { TIdToPageMap } from 'models/common.model'
import { Account } from 'modules/account/Account'
import { EmailVariant1 } from 'modules/email/pages/EmailVariant1'
import { PaymentVariant1 } from 'modules/payment/pages/PaymentVariant1'
import { PaidTrialSubscriptionsVariant1 } from 'modules/subscriptions/pages/PaidTrialSubscriptionsVariant1'
import { SubscriptionsVariant1 } from 'modules/subscriptions/pages/SubscriptionsVariant1'
import { SubscriptionsVariant2 } from 'modules/subscriptions/pages/SubscriptionsVariant2'
import { SubscriptionsVariant3 } from 'modules/subscriptions/pages/SubscriptionsVariant3'
import { BehaviourChangesVariant1 } from 'pages/behaviour-changes/BehaviourChangesVariant1'
import { CallsLateVariant1 } from 'pages/calls-late/CallsLateVariant1'
import { CatchOnALieVariant1 } from 'pages/catch-on-a-lie/CatchOnALieVariant1'
import { CheatingVariant1 } from 'pages/cheating/CheatingVariant1'
import { ChildInTroubleVariant1 } from 'pages/child-in-trouble/ChildInTroubleVariant1'
import { ChildMovesVariant1 } from 'pages/child-moves/ChildMovesVariant1'
import { ConcernsVariant1 } from 'pages/concerns/ConcernsVariant1'
import { ConnectionVariant1 } from 'pages/connection/ConnectionVariant1'
import { DataVariant1 } from 'pages/data/DataVariant1'
import { DataVariant2 } from 'pages/data/DataVariant2'
import { DaysDisclosureVariant1 } from 'pages/days-disclosure/DaysDisclosureVariant1'
import { DeleteCallsVariant1 } from 'pages/delete-calls/DeleteCallsVariant1'
import { FearVariant1 } from 'pages/fear/FearVariant1'
import { FearVariant2 } from 'pages/fear/FearVariant2'
import { FeelingsVariant1 } from 'pages/feelings/FeelingsVariant1'
import { FeelingsVariant2 } from 'pages/feelings/FeelingsVariant2'
import { GenderVariant1 } from 'pages/gender/GenderVariant1'
import { GetInTouchVariant1 } from 'pages/get-in-touch/GetInTouchVariant1'
import { GoingOutVariant1 } from 'pages/going-out/GoingOutVariant1'
import { InconsistenciesVariant1 } from 'pages/inconsistencies/InconsistenciesVariant1'
import { InconsistenciesVariant2 } from 'pages/inconsistencies/InconsistenciesVariant2'
import { InterestInPeopleVariant1 } from 'pages/interest-in-people/InterestInPeopleVariant1'
import { IntroVariant1 } from 'pages/intro/IntroVariant1'
import { IntroVariant2 } from 'pages/intro/IntroVariant2'
import { IntroVariant3 } from 'pages/intro/IntroVariant3'
import { LocationVariant1 } from 'pages/location/LocationVariant1'
import { LocatorAppVariant1 } from 'pages/locator-app/LocatorAppVariant1'
import { PartnersDataVariant1 } from 'pages/partners-data/PartnersDataVariant1'
import { PhoneHabitsVariant1 } from 'pages/phone-habits/PhoneHabitsVariant1'
import { PreparingFlowVariant1 } from 'pages/preparing-flow/PreparingFlowVariant1'
import { ProtectiveOfPhoneVariant1 } from 'pages/protective-of-phone/ProtectiveOfPhoneVariant1'
import { PutPhoneAwayVariant1 } from 'pages/put-phone-away/PutPhoneAwayVariant1'
import { ReasonsVariant1 } from 'pages/reasons/ReasonsVariant1'
import { ReasonsVariant2 } from 'pages/reasons/ReasonsVariant2'
import { RoutinesChangesVariant1 } from 'pages/routines-changes/RoutinesChangesVariant1'
import { SocialProofVariant1 } from 'pages/social-proof/SocialProofVariant1'
import { SocialProofVariant2 } from 'pages/social-proof/SocialProofVariant2'
import { SocialProofVariant3 } from 'pages/social-proof/SocialProofVariant3'
import { SocialProofVariant4 } from 'pages/social-proof/SocialProofVariant4'
import { SocialProofVariant5 } from 'pages/social-proof/SocialProofVariant5'
import { SocialProofVariant6 } from 'pages/social-proof/SocialProofVariant6'
import { StayOutVariant1 } from 'pages/stay-out/StayOutVariant1'
import { SuspiciousActivityVariant1 } from 'pages/suspicious-activity/SuspiciousActivityVariant1'
import { SuspiciousActivityVariant2 } from 'pages/suspicious-activity/SuspiciousActivityVariant2'
import { TakeCallsVariant1 } from 'pages/take-calls/TakeCallsVariant1'
import { TrackingFrequencyVariant1 } from 'pages/tracking-frequency/TrackingFrequencyVariant1'
import { DownloadApp } from 'pages/download-app/DownloadApp'
import { StayConnectedVariant1 } from 'pages/stay-connected/StayConnectedVariant1'
import { FamilySafetyVariant1 } from 'pages/family-safety/FamilySafetyVariant1'
import { OnePlanForEveryoneVariant1 } from 'pages/one-plan-for-everyone/OnePlanForEveryoneVariant1'
import { PreparingFlowVariant2 } from 'pages/preparing-flow/PreparingFlowVariant2'
import { PreloaderVariant1 } from 'pages/preloader/PreloaderVariant1'
import { SubscriptionsVariant4 } from 'modules/subscriptions/pages/SubscriptionsVariant4'
import { PaymentVariant2 } from 'modules/payment/pages/PaymentVariant2'

export const enum PageId {
  INTRO_1 = 'intro_1',
  INTRO_2 = 'intro_2',
  INTRO_3 = 'intro_3',
  CATCH_ON_A_LIE_1 = 'catch_on_a_lie_1',
  BEHAVIOUR_CHANGES_1 = 'behaviour_changes_1',
  ROUTINES_CHANGES_1 = 'routines_changes_1',
  DAYS_DISCLOSURE_1 = 'days_disclosure_1',
  INCONSISTENCIES_1 = 'inconsistencies_1',
  INCONSISTENCIES_2 = 'inconsistencies_2',
  INTEREST_IN_PEOPLE_1 = 'interest_in_people_1',
  PHONE_HABITS_1 = 'phone_habits_1',
  SOCIAL_PROOF_1 = 'social_proof_1',
  PROTECTIVE_OF_PHONE_1 = 'protective_of_phone_1',
  TAKE_CALLS_1 = 'take_calls_1',
  CALLS_LATE_1 = 'calls_late_1',
  DELETE_CALLS_1 = 'delete_calls_1',
  SOCIAL_PROOF_2 = 'social_proof_2',
  PUT_PHONE_AWAY_1 = 'put_phone_away_1',
  SUSPICIOUS_ACTIVITY_1 = 'suspicious_activity_1',
  SUSPICIOUS_ACTIVITY_2 = 'suspicious_activity_2',
  GOING_OUT_1 = 'going_out_1',
  STAY_OUT_1 = 'stay_out_1',
  STAY_CONNECTED_1 = 'stay_connected_1',
  FAMILY_SAFETY_1 = 'family_safety_1',
  ONE_PLAN_FOR_EVERYONE_1 = 'one_plan_for_everyone_1',
  CHEATING_1 = 'cheating_1',
  GENDER_1 = 'gender_1',
  SOCIAL_PROOF_3 = 'social_proof_3',
  LOCATOR_APP_1 = 'locator_app_1',
  PARTNERS_DATA_1 = 'partners_data_1',
  DATA_1 = 'data_1',
  DATA_2 = 'data_2',
  TRACKING_FREQUENCY_1 = 'tracking_frequency_1',
  REASONS_1 = 'reasons_1',
  REASONS_2 = 'reasons_2',
  LOCATION_1 = 'location_1',
  CHILD_MOVES_1 = 'child_moves_1',
  FEAR_1 = 'fear_1',
  FEAR_2 = 'fear_2',
  CHILD_IN_TROUBLE_1 = 'child_in_trouble_1',
  SOCIAL_PROOF_4 = 'social_proof_4',
  CONCERNS_1 = 'concerns_1',
  CONNECTION_1 = 'connection_1',
  FEELINGS_1 = 'feelings_1',
  FEELINGS_2 = 'feelings_2',
  SOCIAL_PROOF_5 = 'social_proof_5',
  SOCIAL_PROOF_6 = 'social_proof_6',
  GET_IN_TOUCH_1 = 'get_in_touch_1',
  PREPARING_FLOW_1 = 'preparing_flow_1',
  PREPARING_FLOW_2 = 'preparing_flow_2',
  EMAIL_1 = 'email_1',
  PRELOADER_1 = 'preloader_1',
  SUBSCRIPTIONS_1 = 'subscriptions_1',
  SUBSCRIPTIONS_2 = 'subscriptions_2',
  SUBSCRIPTIONS_3 = 'subscriptions_3',
  SUBSCRIPTIONS_4 = 'subscriptions_4',
  PAID_TRIAL_SUBSCRIPTIONS_1 = 'paid_trial_subscriptions_1',
  PAYMENT_1 = 'payment_1',
  PAYMENT_2 = 'payment_2',
  ACCOUNT = 'account',
  DOWNLOAD = 'download',
}

export const ID_TO_PAGE_MAP: TIdToPageMap = {
  intro_1: IntroVariant1,
  intro_2: IntroVariant2,
  intro_3: IntroVariant3,
  behaviour_changes_1: BehaviourChangesVariant1,
  catch_on_a_lie_1: CatchOnALieVariant1,
  routines_changes_1: RoutinesChangesVariant1,
  days_disclosure_1: DaysDisclosureVariant1,
  inconsistencies_1: InconsistenciesVariant1,
  inconsistencies_2: InconsistenciesVariant2,
  interest_in_people_1: InterestInPeopleVariant1,
  phone_habits_1: PhoneHabitsVariant1,
  social_proof_1: SocialProofVariant1,
  protective_of_phone_1: ProtectiveOfPhoneVariant1,
  take_calls_1: TakeCallsVariant1,
  calls_late_1: CallsLateVariant1,
  delete_calls_1: DeleteCallsVariant1,
  social_proof_2: SocialProofVariant2,
  put_phone_away_1: PutPhoneAwayVariant1,
  suspicious_activity_1: SuspiciousActivityVariant1,
  suspicious_activity_2: SuspiciousActivityVariant2,
  going_out_1: GoingOutVariant1,
  stay_out_1: StayOutVariant1,
  stay_connected_1: StayConnectedVariant1,
  family_safety_1: FamilySafetyVariant1,
  one_plan_for_everyone_1: OnePlanForEveryoneVariant1,
  cheating_1: CheatingVariant1,
  gender_1: GenderVariant1,
  social_proof_3: SocialProofVariant3,
  locator_app_1: LocatorAppVariant1,
  partners_data_1: PartnersDataVariant1,
  data_1: DataVariant1,
  data_2: DataVariant2,
  tracking_frequency_1: TrackingFrequencyVariant1,
  reasons_1: ReasonsVariant1,
  reasons_2: ReasonsVariant2,
  location_1: LocationVariant1,
  child_moves_1: ChildMovesVariant1,
  fear_1: FearVariant1,
  fear_2: FearVariant2,
  child_in_trouble_1: ChildInTroubleVariant1,
  social_proof_4: SocialProofVariant4,
  concerns_1: ConcernsVariant1,
  connection_1: ConnectionVariant1,
  feelings_1: FeelingsVariant1,
  feelings_2: FeelingsVariant2,
  social_proof_5: SocialProofVariant5,
  social_proof_6: SocialProofVariant6,
  get_in_touch_1: GetInTouchVariant1,
  preparing_flow_1: PreparingFlowVariant1,
  preparing_flow_2: PreparingFlowVariant2,
  email_1: EmailVariant1,
  preloader_1: PreloaderVariant1,
  subscriptions_1: SubscriptionsVariant1,
  subscriptions_2: SubscriptionsVariant2,
  subscriptions_3: SubscriptionsVariant3,
  subscriptions_4: SubscriptionsVariant4,
  paid_trial_subscriptions_1: PaidTrialSubscriptionsVariant1,
  payment_1: PaymentVariant1,
  payment_2: PaymentVariant2,
  account: Account,
  download: DownloadApp,
}
