import { Elements } from '@stripe/react-stripe-js'
import { Stripe } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import { Spinner } from 'components/Spinner'
import { useProductId } from 'hooks/useProductId'
import { TDynamicPageComponent } from 'models/common.model'
import {
  select3DSecureIframeUrl,
  selectIsPaymentFlowsShown,
} from 'modules/payment/redux/selects'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PageId } from 'root-constants/pages'
import { selectStripePublicKey } from 'root-redux/selects/common'
import { selectEmail, selectHasUserSubscription } from 'root-redux/selects/user'
import { eventLogger } from 'services/eventLogger.service'
import { PaymentFlows } from '../components/PaymentFlows'
import { StripePaymentProcessing } from '../components/StripePaymentProcessing'
import { STRIPE_ELEMENTS_OPTIONS } from '../constants'
import { StyledPayment as S } from './Payment.styles'

const Component: TDynamicPageComponent = () => {
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const stripePublicKey = useSelector(selectStripePublicKey)
  const isPaymentFlowsShown = useSelector(selectIsPaymentFlowsShown)
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null)
  const productId = useProductId()
  const email = useSelector(selectEmail)
  const hasUserSubscription = useSelector(selectHasUserSubscription)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' as any })
    document.body.scrollTo({ top: 0, behavior: 'instant' as any })
  }, [])

  useEffect(() => {
    if (hasUserSubscription) return
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        // isCancelOfferApplied,
      })
      window.ttq.identify({ email })
      window.ttq.track('AddToCart')
    }
  }, [email, hasUserSubscription, productId])

  useEffect(() => {
    if (stripePublicKey) {
      // loadStripe should be called once
      setStripePromise(loadStripe(stripePublicKey))
    }
  }, [stripePublicKey])

  return (
    <Elements stripe={stripePromise} options={STRIPE_ELEMENTS_OPTIONS}>
      <S.Wrapper>
        {threeDSecureIframeUrl ? (
          <S.ThreeDSecureIframe
            title="3DSecure"
            src={threeDSecureIframeUrl}
            scrolling="yes"
          />
        ) : (
          <>
            <StripePaymentProcessing />
            <S.Content>
              <PaymentFlows />
            </S.Content>
          </>
        )}
        {!isPaymentFlowsShown && <Spinner />}
      </S.Wrapper>
    </Elements>
  )
}

Component.hasHeader = true
Component.id = PageId.PAYMENT_1

export const PaymentVariant1 = Component
