import styled from 'styled-components'

export const StyledHeader = {
  HeaderWrapper: styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-bottom: 1px solid #ccdce5;
  `,
  Header: styled.header`
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    padding: 16px 0;
    color: #5c90ff;
  `,
}
