import React, { useCallback, useRef } from 'react'
import peopleImg from 'assets/images/subscriptions-people.webp'
import { TDynamicPageComponent } from 'models/common.model'
import { PageId } from 'root-constants/pages'
import { BenefitsVariant2 } from 'modules/subscriptions/components/BenefitsVariant2'
import { SubheaderWithTimer } from '../components/SubheaderWithTimer'
import { Timer } from '../components/Timer'
import { PlanItemVariant1 } from '../components/variant1/PlanItemVariant1'
import { SubscriptionsWrapper } from '../SubscriptionsWrapper'
import { ISubscriptionsPageProps } from '../types'
import { StyledSubscriptions as S } from './Subscriptions.styles'

const SubscriptionsPageVariant3Component: React.FC<ISubscriptionsPageProps> = ({
  onButtonClick,
  onSubscriptionSelect,
}) => {
  const scrollToElement = useRef<HTMLDivElement>(null)
  const timerTriggerElementRef = useRef(null)

  const handleTimerButtonClick = useCallback(() => {
    scrollToElement.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }, [])

  return (
    <>
      <SubheaderWithTimer
        elemForComparisonRef={timerTriggerElementRef}
        onClick={handleTimerButtonClick}
      />
      <S.Wrapper>
        <S.Column>
          <S.Title2>Keep Your Family Safe</S.Title2>
          <S.Subtitle2>
            Keep calm and stay connected with your family
          </S.Subtitle2>
          <Timer />
        </S.Column>

        <S.ImageContainer>
          <img ref={timerTriggerElementRef} src={peopleImg} alt="people" />
        </S.ImageContainer>

        <S.Column>
          <BenefitsVariant2 />
          <S.SubscriptionsTitle>Invest in Your Peace</S.SubscriptionsTitle>
          <S.SelectPlanBlock
            ref={scrollToElement}
            onSelect={onSubscriptionSelect}
            SelectPlanItem={PlanItemVariant1}
          />
          <S.Button onClick={onButtonClick}>Start My Plan</S.Button>
          <S.SubscriptionDescription />
          <S.Guarantee />
          <S.Button onClick={onButtonClick}>Start My Plan</S.Button>
          <S.Security />
        </S.Column>
      </S.Wrapper>
    </>
  )
}

const Component: TDynamicPageComponent = () => (
  <SubscriptionsWrapper>
    {(props: ISubscriptionsPageProps) => (
      <SubscriptionsPageVariant3Component {...props} />
    )}
  </SubscriptionsWrapper>
)

Component.hasHeader = true
Component.id = PageId.SUBSCRIPTIONS_3

export const SubscriptionsVariant3 = Component
