import { StripeFieldName } from 'root-constants/common'
import { PageId } from 'root-constants/pages'
import { EMPTY_FIELD_ERROR, THREEDS_REDIRECT_SEARCH_PARAM } from './constants'
import { TPaymentErrorState } from './types'

export const getPaymentErrorStateBySubmitWithUntouchedFields = (
  errors: TPaymentErrorState,
): TPaymentErrorState =>
  Object.entries(errors).reduce(
    (result, error) => ({
      ...result,
      [error[0]]: {
        ...error[1],
        ...(!error[1].isTouched && {
          isTouched: true,
          error: EMPTY_FIELD_ERROR,
        }),
      },
    }),
    {} as TPaymentErrorState,
  )

export const getDefaultPaymentErrorsState = (): Record<
  StripeFieldName,
  {
    error: string
    isTouched: boolean
    isShown: boolean
    isComplete: boolean
  }
> => ({
  [StripeFieldName.NAME]: {
    error: '',
    isTouched: true,
    isShown: true,
    isComplete: true,
  },
  [StripeFieldName.NUMBER]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
  },
  [StripeFieldName.EXPIRY]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
  },
  [StripeFieldName.CVC]: {
    error: '',
    isTouched: false,
    isShown: true,
    isComplete: false,
  },
})

export const getRedirectUrl = (paymentPageId: PageId): string =>
  `https://${window.location.hostname}/${paymentPageId}?${THREEDS_REDIRECT_SEARCH_PARAM}`
