import { Button } from 'components/Button'
import { FlowContext } from 'components/FlowContext'
import { useURLSearchData } from 'hooks/useURLSearchData'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'root-constants/pages'
import { GOALS_PARAM_KEY, UrlDataGoals } from 'root-constants/urlData'
import { setAnswersAction } from 'root-redux/actions/common'
import { StyledSocialProof as S } from './SocialProof.styles'

const PAGE_NAME = 'Social proof famio'

const Component: TDynamicPageComponent = () => {
  const { onNextStep, onDataQuestionPage, currentPageId } =
    useContext(FlowContext)
  const dispatch = useDispatch()
  const { getDataFromURL } = useURLSearchData()

  const isPartnerGoal = useMemo(
    () => getDataFromURL(GOALS_PARAM_KEY) === UrlDataGoals.PARTNER,
    [getDataFromURL],
  )

  const handleContinue = useCallback(() => {
    dispatch(
      setAnswersAction({
        question: '',
        answers: '',
        pageId: currentPageId,
        pageName: PAGE_NAME,
      }),
    )
    isPartnerGoal ? onNextStep() : onDataQuestionPage()
  }, [currentPageId, dispatch, isPartnerGoal, onDataQuestionPage, onNextStep])

  return (
    <S.Wrapper2>
      <S.Column>
        <S.Description style={{ marginBottom: 29 }}>
          <strong>73%</strong> of our users admit that they
          <br /> gained peace of mind when they
          <br /> started to use Famio
        </S.Description>
        <Button onClick={handleContinue}>Continue</Button>
      </S.Column>
    </S.Wrapper2>
  )
}

Component.id = PageId.SOCIAL_PROOF_5
Component.hasHeader = true

export const SocialProofVariant5 = Component
