import styled from 'styled-components'
import checkboxViolet from 'assets/images/checkbox-violet.svg'
import { baseTitleStyles } from 'components/styles'

export const StyledBenefits = {
  Wrapper: styled.div`
    margin: 41px 0 28px;
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 27px;
  `,
  ListItem: styled.li`
    position: relative;
    margin-bottom: 18px;
    padding-left: 35px;
    font-size: 16px;
    line-height: 24px;

    ::before {
      content: url(${checkboxViolet});
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 23px;
      height: 23px;
    }
  `,
}
