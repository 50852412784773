import { CloseButton } from 'components/styles'
import styled, { css } from 'styled-components'

const dividerTextStyles = css`
  position: relative;
  padding: 0 25px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;

  ::before,
  ::after {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    width: 500px;
    top: 10px;
  }

  ::before {
    right: 100%;
  }

  ::after {
    left: 100%;
  }
`

export const StyledPaymentFlows = {
  TitleWrapper: styled.div`
    width: 100%;
    margin: 48px 0 8px;
    position: relative;
  `,
  CloseButton: styled(CloseButton)`
    position: absolute;
    top: -32px;
    right: -16px;
  `,
  Title: styled.h3`
    font-size: 24px;
    line-height: 28px;
    font-weight: 800;
    text-align: center;
  `,
  Divider: styled.div`
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
  `,
  DividerText: styled.div`
    ${dividerTextStyles};
    color: #7b8898;

    ::before,
    ::after {
      background-color: #bcc9d0;
    }
  `,
  DividerText2: styled.div`
    ${dividerTextStyles};

    ::before,
    ::after {
      background-color: #362f99;
    }
  `,
  Form: styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 25px;
  `,
  PaymentServiceImage: styled.img`
    margin: 32px 0 16px;
  `,
  Guarantee: styled.span`
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    opacity: 0.7;
    margin-bottom: 48px;
  `,
  Description: styled.span`
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    color: #7b8898;
    margin-bottom: 44px;

    a {
      color: inherit;
    }
  `,
}
