import { BASE_COLUMN_WIDTH } from 'root-constants/common'
import styled from 'styled-components'

export const StyledSecurity = {
  SecureImage: styled.img`
    margin: 0 auto 26px;
    border-radius: 100px;
  `,
  SecureCard: styled.div`
    margin-bottom: 32px;
    padding: 22px 24px 0;
    width: ${BASE_COLUMN_WIDTH}px;
    background-color: #eff5f7;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
  `,
  SecureItemTitle: styled.h4`
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
  `,
  SecureItemText: styled.p`
    margin-bottom: 32px;
    font-size: 13px;
    line-height: 20px;

    > a {
      color: #0091ff;
      font-weight: 700;
    }
  `,
}
