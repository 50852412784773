import logo from 'assets/images/logo.png'
import { getDinamicPageComponent } from 'helpers/getDinamicPageComponent'
import { IVariant } from 'models/variant.model'
import React, { useContext, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { MAIN_COHORT } from 'root-constants/common'
import {
  selectCurrentVariant,
  selectCurrentVariantSteps,
} from 'root-redux/selects/common'
import { PageId } from '../constants/pages'
import { FlowContext } from './FlowContext'
import { StyledHeader as S } from './Header.styles'
import { ProgressBar } from './ProgressBar'

export const Header: React.FC = () => {
  const { currentPageId } = useContext(FlowContext)
  const steps = useSelector(selectCurrentVariantSteps)
  const variant = useSelector(selectCurrentVariant) as IVariant

  const { cohort } = variant

  const pagesIds = useMemo(() => {
    const stepsIds = steps.map(({ id }) => id)
    return [...stepsIds, PageId.ACCOUNT, PageId.DOWNLOAD]
  }, [steps])

  const pagesWithHeader = useMemo(() => {
    return pagesIds.filter((id) => getDinamicPageComponent(id)?.hasHeader)
  }, [pagesIds])

  const currentPageIndex = useMemo(
    () => pagesWithHeader.findIndex((id) => id === currentPageId),
    [currentPageId, pagesWithHeader],
  )

  return currentPageIndex < 0
    ? null
    : ReactDOM.createPortal(
        // eslint-disable-next-line react/jsx-indent
        <S.HeaderWrapper>
          <S.Header>
            <img height="38" src={logo} alt="logo" />
          </S.Header>
          {cohort !== MAIN_COHORT && <ProgressBar />}
        </S.HeaderWrapper>,
        document.getElementById('root') as HTMLElement,
      )
}
