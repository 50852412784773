import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'root-constants/pages'
import { setAnswersAction } from 'root-redux/actions/common'
import { useURLSearchData } from 'hooks/useURLSearchData'
import { GENDER_PARAM_KEY, UrlDataGender } from 'root-constants/urlData'
import { Button } from 'components/Button'
import { StyledSocialProof as S } from './SocialProof.styles'

const PAGE_NAME_MALE = 'Social proof 3 male'
const PAGE_NAME_FEMALE = 'Social proof 3 female'

const Component: TDynamicPageComponent = () => {
  const { onNextStep, currentPageId } = useContext(FlowContext)
  const dispatch = useDispatch()
  const { getDataFromURL } = useURLSearchData()

  const gender = useMemo(
    () => getDataFromURL(GENDER_PARAM_KEY),
    [getDataFromURL],
  )

  const pageName = useMemo(
    () => (gender === UrlDataGender.MALE ? PAGE_NAME_MALE : PAGE_NAME_FEMALE),
    [gender],
  )

  const handleContinue = useCallback(() => {
    dispatch(
      setAnswersAction({
        question: '',
        answers: '',
        pageId: currentPageId,
        pageName,
      }),
    )
    onNextStep()
  }, [currentPageId, dispatch, onNextStep, pageName])

  return (
    <S.Wrapper3 data-gender={gender}>
      <S.Column>
        <S.Title>Can you believe that ...</S.Title>
        <S.Text style={{ marginBottom: 14 }}>
          <strong>57%</strong> of people who say they have ever cheated say that
          at least one{' '}
          <strong>partner never found out about their cheating</strong>
        </S.Text>
        <S.Text style={{ marginBottom: 14 }}>
          <strong>71%</strong> of people who have ever cheated say they
          didn&#39;t want their partners{' '}
          <strong>to find out about their cheating</strong>
        </S.Text>
        <S.Note style={{ marginBottom: 6 }}>
          *according to YouGov survey in 2022
        </S.Note>
        <Button onClick={handleContinue}>Continue</Button>
      </S.Column>
    </S.Wrapper3>
  )
}

Component.id = PageId.SOCIAL_PROOF_3
Component.hasHeader = true

export const SocialProofVariant3 = Component
