import { createMachine, StateMachine } from 'xstate'
import { IUserStatus } from 'models/user.model'

type TSteps =
  | { type: 'NEXT_FLOW_STEP' }
  | { type: 'PREVIOUS_FLOW_STEP' }
  | { type: 'CANCEL_OFFER_SUBSCRIPTIONS_PAGE' }
  | { type: 'SKIP_FLOW_STEPS' }
  | { type: 'DATA_QUESTION_PAGE' }

export interface IUserFlowContext {
  userStatus: IUserStatus
}

export const createUserFlowStateMachine = (
  { userStatus }: IUserFlowContext,
  currentXStateConfig: any,
): StateMachine<IUserFlowContext, any, TSteps> =>
  createMachine<IUserFlowContext, TSteps>({
    predictableActionArguments: true,
    id: 'userFlow',
    initial: 'initial',
    context: {
      userStatus,
    },
    states: currentXStateConfig,
  })
