import {
  baseColumnStyles,
  basePageStyles,
  BottomStickyBase,
} from 'components/styles'
import { BASE_COLUMN_WIDTH, HEADER_HEIGHT } from 'root-constants/common'
import styled from 'styled-components'

export const StyledAccount = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  Title: styled.h1`
    margin-bottom: 12px;
    font-size: 26px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
  `,
  Text: styled.p`
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #7b8898;
  `,
  Buttons: styled.div`
    ${BottomStickyBase};
    width: ${BASE_COLUMN_WIDTH}px;
  `,
}
