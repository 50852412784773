import { ISubscription } from 'models/variant.model'
import React, { useEffect } from 'react'
import { eventLogger } from 'services/eventLogger.service'
import logo from 'assets/images/logo.png'
import { StyledPaidTrialModal as S } from './PaidTrialModal.styles'
import { SelectPlanBlockModal } from './SelectPlanBlockModal'

type TProps = {
  isShown: boolean
  onClose: () => void
  onSubscriptionSelect: (selectedSubscription?: ISubscription) => void
}

export const PaidTrialModal: React.FC<TProps> = ({
  isShown,
  onClose,
  onSubscriptionSelect,
}) => {
  useEffect(() => {
    isShown && eventLogger.logTrialPricePageShown()
  }, [isShown])

  useEffect(() => {
    document.body.style.overflow = isShown ? 'hidden' : 'unset'
  }, [isShown])

  useEffect(
    () => () => {
      document.body.style.overflow = 'unset'
    },
    [],
  )

  return (
    <S.Wrapper isShown={isShown}>
      <S.HeaderWrapper>
        <S.Header>
          <img height="38" src={logo} alt="logo" />
        </S.Header>
      </S.HeaderWrapper>
      <S.Content>
        <S.Title>Choose Your Trial Price</S.Title>
        <S.Text style={{ lineHeight: '22px' }}>
          We’ve helped thousands of people to improve their relationship and
          we’d love to help you too!
        </S.Text>
        <S.Text2>
          Money shouldn’t stand in the way of your happiness. Choose the amount
          you think is reasonable for a one-week trial.
        </S.Text2>
        <S.Text2 style={{ color: '#6E1FFF' }}>
          It costs us $9.99 to compensate our Famio employees for the trial, but
          please choose the amount you are comfortable with.
        </S.Text2>
        <SelectPlanBlockModal onSelect={onSubscriptionSelect} />
        <S.StickyButton onClick={onClose} type="button">
          Continue
        </S.StickyButton>
      </S.Content>
    </S.Wrapper>
  )
}
