import { IVariant } from 'models/variant.model'
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useOutlet } from 'react-router-dom'
import { STRIPE_COHORT } from 'root-constants/common'
import { PageId } from 'root-constants/pages'
import { selectCurrentVariant } from 'root-redux/selects/common'
import { FlowContext } from './FlowContext'
import { Header } from './Header'
import { StripeFlowHeader } from './StripeFlowHeader'

type TProps = {
  firstPageId: string
  flowContextValue: {
    onNextStep: () => void
    onPreviousStep: () => void
    onSkipSteps: () => void
    onCancelOfferSubscriptions: () => void
    onDataQuestionPage: () => void
    currentPageId: PageId
  }
}

export const MainWrapper: React.FC<TProps> = ({
  firstPageId,
  flowContextValue,
}) => {
  const variant = useSelector(selectCurrentVariant) as IVariant
  const outlet = useOutlet()

  const { cohort } = variant

  return outlet ? (
    <FlowContext.Provider value={flowContextValue}>
      {cohort === STRIPE_COHORT ? <StripeFlowHeader /> : <Header />}
      {outlet}
    </FlowContext.Provider>
  ) : (
    <Navigate to={`/${firstPageId}`} />
  )
}
