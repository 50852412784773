import {
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { PaymentRequest } from '@stripe/stripe-js/types/stripe-js/payment-request'
import { FlowContext } from 'components/FlowContext'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CENTS_IN_DOLLAR } from 'root-constants/common'
import { resetErrorAction } from 'root-redux/actions/common'
import { purchaseAction, setIsPaymentFlowsShownAction } from '../redux/actions'
import {
  selectCurrency,
  selectSubscriptionCurrentPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodPrice,
} from '../redux/selects'
import { StyledPaymentRequestButton as S } from './PaymentRequestButton.styles'

export const PaymentRequestButton: React.FC = () => {
  const dispatch = useDispatch()
  const { currentPageId } = useContext(FlowContext)
  const stripe = useStripe()
  const elements = useElements()
  const trialCurrentPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const selectedPrice = useSelector(selectSubscriptionCurrentPrice)
  const currency = useSelector(selectCurrency)
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  )

  const calculatedPrice = useMemo(
    () => +((trialCurrentPrice || selectedPrice) * CENTS_IN_DOLLAR).toFixed(),
    [trialCurrentPrice, selectedPrice],
  )

  useEffect(() => {
    if (!stripe || !elements) {
      return
    }

    const pr = stripe.paymentRequest({
      currency,
      country: 'GB',
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: `${periodQuantity}-${periodName} plan`,
        amount: calculatedPrice,
      },
    })

    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr)
      }
      dispatch(setIsPaymentFlowsShownAction(true))
    })

    pr.on('paymentmethod', (event) => {
      dispatch(resetErrorAction())
      dispatch(
        purchaseAction({
          stripe,
          paymentPageId: currentPageId,
          createPaymentResFromDigitalWallet: event,
        }),
      )
    })
  }, [
    dispatch,
    currency,
    stripe,
    elements,
    calculatedPrice,
    currentPageId,
    periodQuantity,
    periodName,
  ])

  return paymentRequest ? (
    <S.Wrapper>
      <PaymentRequestButtonElement
        options={{
          paymentRequest,
          style: {
            paymentRequestButton: {
              height: '56px',
            },
          },
        }}
      />
    </S.Wrapper>
  ) : null
}
