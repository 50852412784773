export const GENDER_PARAM_KEY = 'gender'

export const enum UrlDataGender {
  MALE = 'male',
  FEMALE = 'female',
}

export const GOALS_PARAM_KEY = 'goals'

export const enum UrlDataGoals {
  PARTNER = 'partner',
  KID = 'kid',
  FRIEND = 'friend',
}
