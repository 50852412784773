import { Option, OptionType } from 'components/Option'
import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'root-constants/pages'
import { setAnswersAction } from 'root-redux/actions/common'
import { QuestionButton } from 'components/QuestionButton'
import { StyledOption } from 'components/Option.styles'
import { StyledInconsistencies as S } from './Inconsistencies.styles'

const PAGE_NAME = 'Inconsistencies in stories'
const QUESTION = `Are there any inconsistencies in your partner's stories?`
const OPTIONS_VALUES = {
  yes: 'Yes',
  no: 'No',
  cantRemember: 'Can’t remember',
}

const Component: TDynamicPageComponent = () => {
  const { onNextStep, currentPageId } = useContext(FlowContext)
  const dispatch = useDispatch()

  const handleContinue = useCallback(
    (answer) => {
      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: answer,
          pageId: currentPageId,
          pageName: PAGE_NAME,
        }),
      )
      onNextStep()
    },
    [currentPageId, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      name: currentPageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option {...optionProps} value={OPTIONS_VALUES.yes}>
            <QuestionButton>{OPTIONS_VALUES.yes}</QuestionButton>
          </Option>
          <Option {...optionProps} value={OPTIONS_VALUES.no}>
            <QuestionButton>{OPTIONS_VALUES.no}</QuestionButton>
          </Option>
          <Option {...optionProps} value={OPTIONS_VALUES.cantRemember}>
            <QuestionButton>{OPTIONS_VALUES.cantRemember}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}

Component.id = PageId.INCONSISTENCIES_1
Component.hasHeader = true
Component.hasProgressBar = true

export const InconsistenciesVariant1 = Component
