import { TermsOfUseLink } from 'components/TermsOfUseLink'
import {
  selectSubscriptionCurrentPrice,
  selectSubscriptionPeriodQuantity,
} from 'modules/payment/redux/selects'
import React, { HTMLAttributes, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { PERIOD_QUANTITY_TO_MARKUP_MAP } from '../constants'
import { StyledSubscriptionDescription } from './SubscriptionDescription.styles'

type TSubscriptionDescriptionProps = HTMLAttributes<HTMLParagraphElement>

export const SubscriptionDescription: React.FC<
  TSubscriptionDescriptionProps
> = ({ ...props }) => {
  const currentPrice = useSelector(selectSubscriptionCurrentPrice)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)

  const currentPriceFormatted = useMemo(
    () => +currentPrice.toFixed(2),
    [currentPrice],
  )

  return (
    <StyledSubscriptionDescription {...props}>
      To avoid any disruption of the service, you will be automatically charged
      ${currentPriceFormatted} after the payment confirmation, and the
      subscription will then be auto-renewed on a{' '}
      {PERIOD_QUANTITY_TO_MARKUP_MAP[periodQuantity as number]} basis at the
      price of ${currentPriceFormatted}. To learn more, visit our{' '}
      <TermsOfUseLink /> or contact us at{' '}
      <a href="mailto:famio@harmonybit.com">famio@harmonybit.com</a>
    </StyledSubscriptionDescription>
  )
}
