import { TermsOfUseLink } from 'components/TermsOfUseLink'
import React, { HTMLAttributes } from 'react'
import { GuaranteeCard } from './Guarantee.styles'

export const Guarantee: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...props
}) => (
  <GuaranteeCard {...props}>
    <h4>Money-Back Guarantee</h4>
    <span>
      We are confident in the quality of our plan — tens of thousands of clients
      worldwide have already leveled up their loved one’s security. We are ready
      to return your money if you don’t find any changes and can demonstrate
      that you used all product’s features. For more information, please read
      our <TermsOfUseLink />.
    </span>
  </GuaranteeCard>
)
