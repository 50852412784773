import { Color } from 'root-constants/common'
import styled, { css } from 'styled-components'

const progressValueStyles = css`
  background-color: ${Color.ACCENT_VIOLET};
  border-radius: 2px;
  transition: all 0.2s ease-out;
`

export const StyledProgressBar = {
  Wrapper: styled.div`
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  `,
  Progress: styled.progress`
    width: 100%;
    height: 1px;
    appearance: none;
    border: none;
    display: block;
    border-radius: 2px;
    overflow: hidden;
    background-color: #ccdce5;

    &::-webkit-progress-bar {
      background-color: #ccdce5;
    }
    &::-webkit-progress-value {
      ${progressValueStyles}
    }
    &::-moz-progress-bar {
      ${progressValueStyles}
    }
    &::-ms-fill {
      ${progressValueStyles}
    }
  `,
}
