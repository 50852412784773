import React, { HTMLAttributes } from 'react'
import { StyledPoliciesGroup as S } from './PoliciesGroup.styles'
import { PrivacyPolicyLink } from './PrivacyPolicyLink'
import { TermsOfUseLink } from './TermsOfUseLink'

export const PoliciesGroup: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...props
}) => (
  <S.Wrapper {...props}>
    <div>By continuing you agree to our</div>
    <S.Links>
      <PrivacyPolicyLink />
      <span>|</span>
      <TermsOfUseLink />
    </S.Links>
  </S.Wrapper>
)
