import { StyledOption } from 'components/Option.styles'
import { QuestionButton } from 'components/QuestionButton'
import { baseColumnStyles, basePageStyles } from 'components/styles'
import styled from 'styled-components'
import appImg from 'assets/images/intro.webp'
import { Color } from 'root-constants/common'

export const StyledIntro = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100%;
    background-image: url(${appImg});
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    width: 100vw;
    position: fixed;
    bottom: 0;
    padding: 100px 0 18px;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 3%,
      #fff 24%
    );
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
  `,
  Title: styled.h1`
    width: 310px;
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    margin-bottom: 10px;

    @media (min-height: 550px) {
      margin-bottom: 31px;
    }
  `,
  OptionsContainer: styled(StyledOption.OptionsContainer)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;

    > * {
      width: 150px;
      flex-shrink: 0;
    }
  `,
  QuestionButton: styled(QuestionButton)`
    justify-content: center;
  `,
  NotSureButton: styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #7b8898;

    input:checked + & {
      color: ${Color.ACCENT_VIOLET};
    }

    @media (min-height: 550px) {
      margin-bottom: 35px;
    }
  `,
}
