import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import { paymentApi } from 'api'
import { Modal } from 'components/Modal'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserStatusAction } from 'root-redux/actions/user'
import { selectPayPalClientId } from 'root-redux/selects/common'
import { selectEmail, selectUUID } from 'root-redux/selects/user'
import { eventLogger } from 'services/eventLogger.service'
import { PaymentMethod } from '../constants'
import { logFailedPayment } from '../helpers/logFailedPayment'
import { logSuccessfulPayment } from '../helpers/logSuccessfulPayment'
import {
  selectCurrency,
  selectPayPalPlanId,
  selectSubscriptionCurrentPrice,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from '../redux/selects'
import { PaymentSuccess } from './PaymentSuccess'
import { StyledPayPalContainer as S } from './PayPalContainer.styles'

type TProps = {
  onClick: () => void
}

export const PayPalContainer: React.FC<TProps> = ({ onClick }) => {
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const paypalPlanId = useSelector(selectPayPalPlanId)
  const paypalClientId = useSelector(selectPayPalClientId)
  const currentPrice = useSelector(selectSubscriptionCurrentPrice)
  const trialPeriodPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const currentUUID = useSelector(selectUUID)
  const currency = useSelector(selectCurrency)
  const email = useSelector(selectEmail)
  const [isPaymentStatusShown, setIsPaymentStatusShown] = useState(false)
  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  const handleResetError = useCallback(() => {
    setIsPaymentStatusShown(false)
    setIsErrorModalShown(false)
  }, [])

  const handlePaymentApprove = useCallback(
    async (data) => {
      logSuccessfulPayment({
        email,
        subscriptionId: data.subscriptionID,
        productId: paypalPlanId,
        uuid: currentUUID,
        price: currentPrice,
        trialPrice: trialPeriodPrice,
        trialPeriodDays,
        currency,
        paymentMethod: PaymentMethod.PAYPAL,
        // isCancelOfferApplied,
      })

      const response = await paymentApi.createPaypalSubscription({
        uuid,
        paypalPlanId,
      })

      if (response.status) {
        setIsPaymentStatusShown(true)
        setIsErrorModalShown(false)
        dispatch(getUserStatusAction(uuid))
      }
    },
    [
      email,
      paypalPlanId,
      currentUUID,
      currentPrice,
      trialPeriodPrice,
      trialPeriodDays,
      currency,
      uuid,
      dispatch,
    ],
  )

  const handlePaymentError = useCallback(
    (error) => {
      setIsErrorModalShown(true)
      logFailedPayment({
        email,
        productId: paypalPlanId,
        price: currentPrice,
        currency,
        paymentResponse: {
          type: error?.name || '',
          code: error?.debug_id,
          message: error?.message,
        },
        paymentMethod: PaymentMethod.PAYPAL,
        isTrialActive: !!trialPeriodPrice,
        // isCancelOfferApplied,
      })
    },
    [email, paypalPlanId, currentPrice, currency, trialPeriodPrice],
  )

  const handleButtonClick = useCallback(() => {
    onClick()
    eventLogger.logPurchaseStarted({
      email,
      productId: paypalPlanId,
      priceDetails: {
        price: currentPrice,
        trial: !!trialPeriodPrice,
        currency,
      },
      paymentMethod: PaymentMethod.PAYPAL,
      // isCancelOfferApplied,
    })
  }, [onClick, email, paypalPlanId, currentPrice, trialPeriodPrice, currency])

  return (
    <>
      <S.ButtonsContainer>
        {paypalPlanId && paypalClientId && (
          <PayPalScriptProvider
            options={{
              'client-id': paypalClientId,
              vault: true,
              'disable-funding': 'credit',
            }}
          >
            <PayPalButtons
              style={{
                label: 'paypal',
                tagline: false,
                layout: 'horizontal',
                height: 55,
              }}
              forceReRender={[paypalPlanId]}
              createSubscription={
                (data, actions) =>
                  actions.subscription.create({
                    plan_id: paypalPlanId, // Creates the subscription
                    custom_id: uuid,
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
              onApprove={(data) => handlePaymentApprove(data)}
              onError={(error) => handlePaymentError(error)}
              onClick={handleButtonClick}
            />
          </PayPalScriptProvider>
        )}
      </S.ButtonsContainer>
      {isPaymentStatusShown && <PaymentSuccess />}
      <Modal onClose={handleResetError} isShown={isErrorModalShown}>
        There was an error processing your payment information
      </Modal>
    </>
  )
}
