import styled from 'styled-components'

export const StyledPaymentRequestButton = {
  Wrapper: styled.div`
    margin-top: 24px;
    width: 100%;

    & * {
      border-radius: 28px;
    }
  `,
}
