export const EMAIL_DOMENS = [
  '@gmail.com',
  '@hotmail.com',
  '@yahoo.com',
  '@outlook.com',
  '@aol.com',
  '@icloud.com',
  '@live.com',
  '@msn.com',
  '@me.com',
  '@mac.com',
  '@googlemail.com',
  '@facebook.com',
  '@verizon.net',
  '@rediffmail.com',
  '@zohomail.com',
  '@zoho.com',
  '@mail.com',
]
