import { getProductId } from 'helpers/getProductId'
import {
  selectSubscriptionPriceId,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPriceId,
} from 'modules/payment/redux/selects'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useProductId = () => {
  const priceId = useSelector(selectSubscriptionPriceId)
  const trialPriceId = useSelector(selectSubscriptionTrialPriceId)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)

  const productId = useMemo(
    () => getProductId({ priceId, trialPriceId, trialPeriodDays }),
    [priceId, trialPeriodDays, trialPriceId],
  )

  return productId
}
