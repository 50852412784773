import styled from 'styled-components'

export const StyledTimer2 = {
  TimerContainer: styled.div`
    display: flex;
    gap: 3px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    padding: 1px 7px;
    background-image: linear-gradient(268deg, #5a57fc 9%, #fc5774 100%);
    border-radius: 4px;
  `,
}
