import { Button } from 'components/Button'
import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
  BottomStickyBase,
} from 'components/styles'
import { SvgImage } from 'components/SvgImage'
import { BASE_COLUMN_WIDTH, Color, HEADER_HEIGHT } from 'root-constants/common'
import styled from 'styled-components'

export const StyledEmailVariant1 = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    padding: 23px 0 17px;
  `,
  TitleImg: styled.img`
    margin-bottom: 37px;
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 17px;
  `,
  InputWrapper: styled.div`
    width: 100%;
    position: relative;
  `,
  AutoCompleteWrapper: styled.div`
    width: 100%;
    max-height: 160px;
    padding: 0 15px;
    position: absolute;
    top: 56px;
    left: 0;
    border-radius: 12px;
    overflow-y: auto;
    background-color: #fff;
    filter: drop-shadow(0px 7px 20px #c8d4d8);
  `,
  AutoCompleteButton: styled.button`
    height: 50px;
    width: 100%;
    padding: 0;
    display: block;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 50px;
    text-decoration: none;
    text-align: left;
    color: ${Color.DEFAULT_TEXT};
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    direction: rtl;

    :not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  `,
  AutoCompleteButtonTextWrapper: styled.span`
    unicode-bidi: plaintext;
  `,
  Note: styled.div`
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    margin-bottom: 20px;
  `,
  LockImg: styled(SvgImage)`
    width: 22px;
    margin-left: 3px;
  `,
  Description: styled.p`
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #7b8898;
  `,
  Button: styled(Button)`
    margin-bottom: 22px;
  `,
  Footer: styled.footer`
    ${BottomStickyBase};
    width: ${BASE_COLUMN_WIDTH}px;
    bottom: 16px;

    @media (max-height: 640px) {
      position: relative;
      bottom: 0;
    }
  `,
}
