import downloadAppImg from 'assets/images/download-app.svg'
import { Spinner } from 'components/Spinner'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CUSTOM_TOKEN_LOCAL_STORAGE_KEY } from 'root-constants/common'
import { PageId } from 'root-constants/pages'
import { selectUserAccountLoginMethod } from 'root-redux/selects/user'
import { eventLogger } from 'services/eventLogger.service'
import { ONE_LINK_URL } from './constants'
import { StyledDownloadAppPage as S } from './DownloadApp.styles'

const Component: TDynamicPageComponent = () => {
  const loginMethod = useSelector(selectUserAccountLoginMethod)

  const downloadAppLink = useMemo(() => {
    const customToken = localStorage.getItem(CUSTOM_TOKEN_LOCAL_STORAGE_KEY)

    return customToken
      ? `${ONE_LINK_URL}&deep_link_value=${customToken}`
      : ONE_LINK_URL
  }, [])

  useEffect(() => {
    eventLogger.logGettingAppShown()
  }, [])

  const handleContinue = useCallback(() => {
    window.location.href = downloadAppLink
  }, [downloadAppLink])

  const handleClick = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault()
      eventLogger.logDownloadClicked(handleContinue)
    },
    [handleContinue],
  )

  return (
    <>
      {!loginMethod && <Spinner />}
      {loginMethod && (
        <S.Wrapper>
          <S.Title>
            Your account has been
            <br />
            created!
          </S.Title>
          <S.Image src={downloadAppImg} alt="App screen" />
          <S.Column>
            <S.List>
              <li>
                <S.ListLabel>1</S.ListLabel>
                <S.ListText>
                  Click the button below to download Famio
                </S.ListText>
              </li>
              <li>
                <S.ListLabel>2</S.ListLabel>
                <S.ListText>
                  Open the app and choose the same login method you’ve just used
                  to start your journey.
                </S.ListText>
              </li>
            </S.List>

            <S.StickyButton onClick={handleClick}>
              Download Famio
            </S.StickyButton>
          </S.Column>
        </S.Wrapper>
      )}
    </>
  )
}

Component.hasHeader = true
Component.id = PageId.DOWNLOAD

export const DownloadApp = Component
