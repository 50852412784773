import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'components/styles'
import { HEADER_HEIGHT } from 'root-constants/common'
import styled from 'styled-components'
import sadGirlImg from 'assets/images/proof-1.webp'
import coupleImg from 'assets/images/proof-4.webp'

export const StyledSocialProof = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100%;
    padding-top: ${HEADER_HEIGHT}px;
    background-repeat: no-repeat;
    background-position: top ${HEADER_HEIGHT}px center;
    background-size: contain;

    &[data-has-concerns='true'] {
      background-image: url(${sadGirlImg});
    }
    &[data-has-concerns='false'] {
      background-image: url(${coupleImg});
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    position: fixed;
    bottom: 0;

    @media (min-height: 620px) {
      padding-bottom: 35px;
    }
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    line-height: 24px;
    margin-bottom: 7px;

    @media (min-height: 620px) {
      line-height: 33px;
      margin-bottom: 14px;
    }
  `,
  Text: styled.p`
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 8px;

    @media (min-height: 620px) {
      margin-bottom: 16px;
    }
  `,
  Note: styled.span`
    font-style: italic;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #bcc9d0;
    margin-bottom: 10px;

    @media (min-height: 620px) {
      margin-bottom: 21px;
    }
  `,
}
