import { Button } from 'components/Button'
import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import { useHasConcernsAboutPartnersSafety } from 'pages/hooks/useHasConcernsAboutPartnersSafety'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'root-constants/pages'
import { setAnswersAction } from 'root-redux/actions/common'
import { StyledSocialProof as S } from './SocialProofVariant6.styles'

const Component: TDynamicPageComponent = () => {
  const { onNextStep, currentPageId } = useContext(FlowContext)
  const dispatch = useDispatch()

  const hasConcernsAboutPartnersSafety = useHasConcernsAboutPartnersSafety()

  const pageName = useMemo(
    () =>
      hasConcernsAboutPartnersSafety
        ? 'Social proof cheating 1'
        : 'Social proof cheating 2',
    [hasConcernsAboutPartnersSafety],
  )
  const handleContinue = useCallback(() => {
    dispatch(
      setAnswersAction({
        question: '',
        answers: '',
        pageId: currentPageId,
        pageName,
      }),
    )
    onNextStep()
  }, [currentPageId, dispatch, onNextStep, pageName])

  return (
    <S.Wrapper data-has-concerns={hasConcernsAboutPartnersSafety}>
      <S.Column>
        <S.Title>Can you believe that ...</S.Title>
        {hasConcernsAboutPartnersSafety ? (
          <S.Text>
            <strong>54%</strong> of those who have ever been <br />
            in a monogamous relationship say they{' '}
            <strong>have been cheated</strong> on — either physically,
            emotionally, or both*
          </S.Text>
        ) : (
          <>
            <S.Text>
              <strong>57%</strong> of people who say they have ever cheated say
              that at least one{' '}
              <strong>partner never found out about their cheating</strong>
            </S.Text>
            <S.Text style={{ marginBottom: 8 }}>
              <strong>71%</strong> of people who have ever cheated say they
              didn&#39;t want their partners{' '}
              <strong>to find out about their cheating</strong>
            </S.Text>
          </>
        )}
        <S.Note>*according to YouGov survey in 2022</S.Note>
        <Button onClick={handleContinue}>Continue</Button>
      </S.Column>
    </S.Wrapper>
  )
}

Component.id = PageId.SOCIAL_PROOF_6
Component.hasHeader = true

export const SocialProofVariant6 = Component
