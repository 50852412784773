import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DEFAULT_COHORT } from 'root-constants/common'
import { getVariantAction } from 'root-redux/actions/common'

export const useSetVariant = (): void => {
  const dispatch = useDispatch()
  const URLParams = new URLSearchParams(document.location.search)
  const cohortFromUrl = URLParams.get('cohort')

  useEffect(() => {
    dispatch(getVariantAction({ cohort: cohortFromUrl || DEFAULT_COHORT }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
