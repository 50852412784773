import {
  selectSubscriptionCurrentPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
} from 'modules/payment/redux/selects'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DISCOUNT_PERCENT } from 'root-constants/common'
import { StyledExtendedTotalAmount as S } from './ExtendedTotalAmount2.styles'

export const ExtendedTotalAmount2: React.FC = () => {
  const selectedPrice = useSelector(selectSubscriptionCurrentPrice)
  const selectedSubscriptionPeriodName = useSelector(
    selectSubscriptionPeriodName,
  )
  const selectedSubscriptionPeriodQuantity = useSelector(
    selectSubscriptionPeriodQuantity,
  )

  const oldPrice = useMemo(
    () => Math.ceil((selectedPrice / (100 - DISCOUNT_PERCENT)) * 100),
    [selectedPrice],
  )

  const discountDollarsAmount = useMemo(
    () => +(oldPrice - selectedPrice).toFixed(2),
    [oldPrice, selectedPrice],
  )

  return (
    <S.Wrapper>
      <S.PriceContainer>
        <span>
          {selectedSubscriptionPeriodQuantity}-
          <S.Period>{selectedSubscriptionPeriodName}</S.Period> Famio Plan:
        </span>
        <span>${oldPrice}</span>
      </S.PriceContainer>
      <S.PriceContainer>
        <b>Code Famio23 applied!</b>
        <b>-${discountDollarsAmount}</b>
      </S.PriceContainer>
      <S.PriceContainer>
        <strong>Total:</strong>
        <strong>${selectedPrice}</strong>
      </S.PriceContainer>
    </S.Wrapper>
  )
}
