import { Option, OptionType } from 'components/Option'
import { ISubscription } from 'models/variant.model'
import { selectSubscription } from 'modules/payment/redux/selects'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentVariantSubscriptions } from 'root-redux/selects/common'
import { StyleSelectPlanBlockModal as S } from './SelectPlanBlockModal.styles'

type TProps = {
  onSelect: (subscription?: ISubscription) => void
}

export const SelectPlanBlockModal: React.FC<TProps> = ({ onSelect }) => {
  const subscriptions = useSelector(selectCurrentVariantSubscriptions)
  const selectedSubscription = useSelector(selectSubscription)

  const isSubscriptionSelected = useCallback(
    (subscription) =>
      selectedSubscription?.trialPriceId === subscription.trialPriceId,
    [selectedSubscription?.trialPriceId],
  )

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(
        (subscription) => subscription.trialPriceId === value,
      )
      onSelect(checkedSubscription)
    },
    [onSelect, subscriptions],
  )

  return (
    <S.SelectPlanCard>
      {subscriptions.map((subscription) => (
        <Option
          type={OptionType.RADIO}
          name="subscription"
          value={subscription.trialPriceId as string}
          key={subscription.trialPriceId}
          onChange={handleChange}
        >
          <S.PlanItem
            data-is-selected={isSubscriptionSelected(subscription)}
            data-is-default={subscription.isDefault}
          >
            ${+subscription.trialCurrentPrice.toFixed(2)}
          </S.PlanItem>
        </Option>
      ))}
    </S.SelectPlanCard>
  )
}
