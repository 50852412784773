import { IAppState } from 'models/store.model'
import { ISubscription, TPriceId, TTrialPriceId } from 'models/variant.model'
import { DEFAULT_CURRENCY, TimeInterval } from 'root-constants/common'

export const selectSubscriptionPriceId = (state: IAppState): TPriceId | null =>
  state.payment.selectedSubscription?.priceId || null
export const selectSubscriptionPeriodQuantity = (
  state: IAppState,
): number | null => state.payment.selectedSubscription?.periodQuantity || null
export const selectSubscriptionPeriodName = (
  state: IAppState,
): TimeInterval | null => state.payment.selectedSubscription?.periodName || null
export const selectSubscription = (state: IAppState): ISubscription | null =>
  state.payment.selectedSubscription || null
export const selectSubscriptionCurrentPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.currentPrice || 0
export const selectSubscriptionOldPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.oldPrice || 0
export const selectSubscriptionTrialPriceId = (
  state: IAppState,
): TTrialPriceId | null =>
  state.payment.selectedSubscription?.trialPriceId || null
export const selectPayPalPlanId = (state: IAppState): string =>
  state.payment.selectedSubscription?.payPalPlanId || ''
export const selectCurrency = (state: IAppState): string =>
  state.payment.selectedSubscription?.currency || DEFAULT_CURRENCY
export const selectSubscriptionTrialPeriodPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialCurrentPrice || 0
export const selectSubscriptionTrialPeriodDays = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPeriodDays || 0
export const select3DSecureIframeUrl = (state: IAppState): string =>
  state.payment.threeDSecureIframeURL
export const selectPaymentClientSecret = (state: IAppState): string =>
  state.payment.paymentClientSecret
export const selectSubscriptionId = (state: IAppState): string =>
  state.payment.subscriptionId
export const selectIsPaymentFlowsShown = (state: IAppState): boolean =>
  state.payment.isPaymentFlowsShown
