import { Button } from 'components/Button'
import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'root-constants/pages'
import { setAnswersAction } from 'root-redux/actions/common'
import { StyledSocialProof as S } from './SocialProofVariant4.styles'

const PAGE_NAME = 'Social proof kids'

const Component: TDynamicPageComponent = () => {
  const { onNextStep, currentPageId } = useContext(FlowContext)
  const dispatch = useDispatch()

  const handleContinue = useCallback(() => {
    dispatch(
      setAnswersAction({
        question: '',
        answers: '',
        pageId: currentPageId,
        pageName: PAGE_NAME,
      }),
    )
    onNextStep()
  }, [currentPageId, dispatch, onNextStep])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Text>
          Every year, approximately <strong>eight million </strong>children are
          reported missing worldwide.*
        </S.Text>
        <S.Text>
          In the US, <strong>2300 children</strong> are reported{' '}
          <strong>missing per day.</strong>
        </S.Text>
        <S.Note>
          {' '}
          *The International Centre for Missing
          <br /> and Exploited Children
        </S.Note>
        <Button onClick={handleContinue}>Continue</Button>
      </S.Column>
    </S.Wrapper>
  )
}

Component.id = PageId.SOCIAL_PROOF_4
Component.hasHeader = true

export const SocialProofVariant4 = Component
