import { Option, OptionType } from 'components/Option'
import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useContext, useMemo } from 'react'
import { PageId } from 'root-constants/pages'
import { QuestionButton } from 'components/QuestionButton'
import { StyledOption } from 'components/Option.styles'
import { StyledGetInTouch as S } from './GetInTouch.styles'

const OPTIONS_VALUES = {
  yes: 'Yes',
  no: 'No',
  cantRemember: 'Can’t remember',
}

const Component: TDynamicPageComponent = () => {
  const { onNextStep, currentPageId } = useContext(FlowContext)

  const optionProps = useMemo(
    () => ({
      name: currentPageId,
      type: OptionType.RADIO,
      onChange: () => {
        setTimeout(() => {
          onNextStep()
        }, 500)
      },
    }),
    [currentPageId, onNextStep],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          Have you ever been in situation when you couldn&apos;t get in touch
          with your loved one?
        </S.Title>
        <StyledOption.OptionsContainer>
          <Option {...optionProps} value={OPTIONS_VALUES.yes}>
            <QuestionButton>{OPTIONS_VALUES.yes}</QuestionButton>
          </Option>
          <Option {...optionProps} value={OPTIONS_VALUES.no}>
            <QuestionButton>{OPTIONS_VALUES.no}</QuestionButton>
          </Option>
          <Option {...optionProps} value={OPTIONS_VALUES.cantRemember}>
            <QuestionButton>{OPTIONS_VALUES.cantRemember}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}

Component.id = PageId.GET_IN_TOUCH_1
Component.hasHeader = true

export const GetInTouchVariant1 = Component
