import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectTiktokPixelId } from 'root-redux/selects/common'
import { selectEmail } from 'root-redux/selects/user'

export const useTiktokPixelInitialization = (): void => {
  const tiktokPixelId = useSelector(selectTiktokPixelId)
  const email = useSelector(selectEmail)

  useLayoutEffect(() => {
    if (!tiktokPixelId) return
    window.ttq.load(tiktokPixelId)
    window.ttq.identify({ email })
    window.ttq.page()
  }, [email, tiktokPixelId])
}
