import React, { HTMLAttributes, useRef } from 'react'
import { useTimerForTarget } from '../hooks/useTimerForTarget'
import { StyledTimer as S } from './Timer.styles'

const FIFTEEN_MINUTES_IN_SECONDS = 900

export const Timer: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...props
}) => {
  const timerElementRef = useRef<HTMLSpanElement>(null)

  useTimerForTarget(timerElementRef, FIFTEEN_MINUTES_IN_SECONDS)

  return (
    <S.TimerContainer {...props}>
      <S.TimerContent>
        <span>Reserved price:</span>
        <strong ref={timerElementRef} />
      </S.TimerContent>
    </S.TimerContainer>
  )
}
