import React, { useContext, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { FlowContext } from 'components/FlowContext'
import { useCounter } from 'hooks/useCounter'
import { TDynamicPageComponent } from 'models/common.model'
import { PageId } from 'root-constants/pages'
import { setAnswersAction } from 'root-redux/actions/common'
import { StyledPreparingFlow as S } from './PreparingFlow.styles'

const PAGE_NAME = 'Preparing Personal Flow'

const Component: TDynamicPageComponent = () => {
  const { onNextStep, currentPageId } = useContext(FlowContext)
  const dispatch = useDispatch()

  const {
    counter: movementCounterPercent,
    isFinished: isMovementCounterFinished,
  } = useCounter(true, {
    duration: 4000,
  })

  const { counter: sosCounterPercent, isFinished: isSosCounterFinished } =
    useCounter(isMovementCounterFinished, {
      duration: 4000,
    })

  const { counter: alertsCounterPercent, isFinished: isAlertsCounterFinished } =
    useCounter(isSosCounterFinished, {
      duration: 4000,
    })

  const barsList = useMemo(
    () => [
      {
        title: 'Adding movement history feature',
        color: '#199CF3',
        counter: movementCounterPercent,
      },
      {
        title: 'Setting up SOS ',
        color: '#7879F1',
        counter: sosCounterPercent,
      },
      {
        title: 'Adding Place Alerts',
        color: '#F24281',
        counter: alertsCounterPercent,
      },
    ],
    [alertsCounterPercent, movementCounterPercent, sosCounterPercent],
  )

  useEffect(() => {
    if (!isAlertsCounterFinished) return

    dispatch(
      setAnswersAction({
        question: '',
        answers: '',
        pageId: currentPageId,
        pageName: PAGE_NAME,
      }),
    )
    onNextStep()
    // Don't add currentPageId to dependencie - invokes rerenders and wrong next step calculations
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAlertsCounterFinished, dispatch, onNextStep])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>Creating your profile</S.Title>
        <S.ProgressBarsList>
          {barsList.map(({ color, counter, title }) => (
            <S.ProgressContainer key={title}>
              <S.ProgressHeader>
                <S.ProgressTitle>{title}</S.ProgressTitle>
                <S.ProgressPercent>{counter}</S.ProgressPercent>
              </S.ProgressHeader>
              <S.ProgressBar max={100} value={counter} color={color} />
            </S.ProgressContainer>
          ))}
        </S.ProgressBarsList>
      </S.Column>
    </S.Wrapper>
  )
}

Component.hasHeader = true
Component.id = PageId.PREPARING_FLOW_1

export const PreparingFlowVariant1 = Component
