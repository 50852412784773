import { browserHistory } from 'browser-history'
import { setViewportFullHeight } from 'helpers/setViewportFullHeight'
import { useInitAppConfig } from 'hooks/useInitAppConfig'
import { useInitGoogleAnalyticsTracker } from 'hooks/useInitGoogleAnalyticsTracker'
import { useScrollFix } from 'hooks/useScrollFix'
import { useSetDefaultsubscription } from 'hooks/useSetDefaultSubscription'
import { useSetOptimizeVariantId } from 'hooks/useSetOptimizeVariantId'
import { useSetUserStatus } from 'hooks/useSetUserStatus'
import { useSetUuid } from 'hooks/useSetUuid'
import { useSetVariant } from 'hooks/useSetVariant'
import { useTiktokPixelInitialization } from 'hooks/useTiktokPixelInitialization'
import React, { Suspense } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { selectConfig, selectCurrentVariant } from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'
import { polyfill as smoothScrollPolyfill } from 'smoothscroll-polyfill'
import { CustomRouter } from './CustomRouter'
import { FlowRouter } from './FlowRouter'
import { Spinner } from './Spinner'

setViewportFullHeight()
smoothScrollPolyfill()

export const App: React.FC = () => {
  const userStatus = useSelector(selectUserStatus, shallowEqual)
  const variant = useSelector(selectCurrentVariant, shallowEqual)
  const config = useSelector(selectConfig, shallowEqual)

  useInitAppConfig()
  useInitGoogleAnalyticsTracker()
  useTiktokPixelInitialization()
  useSetVariant()
  useSetUuid()
  useSetUserStatus()
  useScrollFix()
  useSetOptimizeVariantId()
  useSetDefaultsubscription()

  return (
    <CustomRouter history={browserHistory}>
      <Suspense fallback={<Spinner />}>
        {userStatus && variant && config ? <FlowRouter /> : <Spinner />}
      </Suspense>
    </CustomRouter>
  )
}
