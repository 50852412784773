import { Button } from 'components/Button'
import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'root-constants/pages'
import { setAnswersAction } from 'root-redux/actions/common'
import { StyledSocialProof as S } from './SocialProof.styles'

const PAGE_NAME = 'Social proof 2'

const Component: TDynamicPageComponent = () => {
  const { onNextStep, currentPageId } = useContext(FlowContext)
  const dispatch = useDispatch()

  const handleContinue = useCallback(() => {
    dispatch(
      setAnswersAction({
        question: '',
        answers: '',
        pageId: currentPageId,
        pageName: PAGE_NAME,
      }),
    )
    onNextStep()
  }, [currentPageId, dispatch, onNextStep])

  return (
    <S.Wrapper2>
      <S.Column>
        <S.Description>
          <strong>70%</strong> of our users started to feel more secure in their
          relationships
        </S.Description>
        <Button onClick={handleContinue}>Continue</Button>
      </S.Column>
    </S.Wrapper2>
  )
}

Component.id = PageId.SOCIAL_PROOF_2
Component.hasHeader = true

export const SocialProofVariant2 = Component
