import { Option, OptionType } from 'components/Option'
import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'root-constants/pages'
import { setAnswersAction } from 'root-redux/actions/common'
import { QuestionButton } from 'components/QuestionButton'
import { StyledOption } from 'components/Option.styles'
import { useURLSearchData } from 'hooks/useURLSearchData'
import { GOALS_PARAM_KEY, UrlDataGoals } from 'root-constants/urlData'
import { FixedContainer } from 'components/FixedContainer'
import { Button } from 'components/Button'
import { StyledReasons as S } from './Reasons.styles'

const OPTIONS_VALUES = {
  peace: 'To have peace of mind',
  safe: 'To ensure they are safe',
  lost: 'To find them if they are lost or missing',
  emergency: 'To find them in case of an emergency',
  location: 'To keep track of their whereabouts',
  notification: 'To be notified if they leave or enter a certain area',
  honest: 'To make sure they are honest with me',
  all: 'All of the above',
}

const Component: TDynamicPageComponent = () => {
  const { onNextStep, currentPageId, onSkipSteps } = useContext(FlowContext)
  const dispatch = useDispatch()
  const { getDataFromURL } = useURLSearchData()

  const goals = useMemo(() => getDataFromURL(GOALS_PARAM_KEY), [getDataFromURL])

  const [answers, setAnswers] = useState<string[]>([])

  const isOneGoal = useMemo(() => typeof goals === 'string', [goals])

  const question = useMemo(
    () =>
      isOneGoal
        ? 'Why do you need to keep an eye on them?'
        : 'Why do you need to keep an eye on these people?',
    [isOneGoal],
  )

  const pageName = useMemo(() => {
    if (goals === UrlDataGoals.KID) {
      return 'Goal kid'
    }
    if (goals === UrlDataGoals.PARTNER) {
      return 'Goal partner'
    }
    if (goals === UrlDataGoals.FRIEND) {
      return 'Goal friend'
    }
    return 'Goal multiple'
  }, [goals])

  const handleContinue = useCallback(() => {
    dispatch(
      setAnswersAction({
        question,
        answers,
        pageId: currentPageId,
        pageName,
      }),
    )
    goals === UrlDataGoals.FRIEND ? onSkipSteps() : onNextStep()
  }, [
    answers,
    currentPageId,
    dispatch,
    goals,
    onNextStep,
    onSkipSteps,
    pageName,
    question,
  ])

  const optionProps = useMemo(
    () => ({
      name: currentPageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked && value === OPTIONS_VALUES.all) {
          setAnswers([OPTIONS_VALUES.all])
          return
        }

        if (isChecked) {
          const newValues = [...answers, value].filter(
            (item) => item !== OPTIONS_VALUES.all,
          )
          setAnswers(newValues)
          return
        }

        const newValues = answers.filter((item) => item !== value)
        setAnswers(newValues)
      },
    }),
    [answers, currentPageId],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{question}</S.Title>
        <StyledOption.OptionsContainer style={{ paddingBottom: 4 }}>
          <Option
            {...optionProps}
            value={OPTIONS_VALUES.peace}
            checked={answers.includes(OPTIONS_VALUES.peace)}
          >
            <QuestionButton hasCheckboxIcon>
              {OPTIONS_VALUES.peace}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTIONS_VALUES.safe}
            checked={answers.includes(OPTIONS_VALUES.safe)}
          >
            <QuestionButton hasCheckboxIcon>
              {OPTIONS_VALUES.safe}
            </QuestionButton>
          </Option>
          {goals === UrlDataGoals.PARTNER ? (
            <Option
              {...optionProps}
              value={OPTIONS_VALUES.honest}
              checked={answers.includes(OPTIONS_VALUES.honest)}
            >
              <QuestionButton hasCheckboxIcon>
                {OPTIONS_VALUES.honest}
              </QuestionButton>
            </Option>
          ) : (
            <Option
              {...optionProps}
              value={OPTIONS_VALUES.lost}
              checked={answers.includes(OPTIONS_VALUES.lost)}
            >
              <QuestionButton hasCheckboxIcon>
                {OPTIONS_VALUES.lost}
              </QuestionButton>
            </Option>
          )}
          {goals === UrlDataGoals.KID ? (
            <Option
              {...optionProps}
              value={OPTIONS_VALUES.notification}
              checked={answers.includes(OPTIONS_VALUES.notification)}
            >
              <QuestionButton hasCheckboxIcon>
                {OPTIONS_VALUES.notification}
              </QuestionButton>
            </Option>
          ) : (
            <Option
              {...optionProps}
              value={OPTIONS_VALUES.emergency}
              checked={answers.includes(OPTIONS_VALUES.emergency)}
            >
              <QuestionButton hasCheckboxIcon>
                {OPTIONS_VALUES.emergency}
              </QuestionButton>
            </Option>
          )}
          {!isOneGoal && (
            <Option
              {...optionProps}
              value={OPTIONS_VALUES.location}
              checked={answers.includes(OPTIONS_VALUES.location)}
            >
              <QuestionButton hasCheckboxIcon>
                {OPTIONS_VALUES.location}
              </QuestionButton>
            </Option>
          )}
          <Option
            {...optionProps}
            value={OPTIONS_VALUES.all}
            checked={answers.includes(OPTIONS_VALUES.all)}
          >
            <QuestionButton hasCheckboxIcon>
              {OPTIONS_VALUES.all}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <FixedContainer>
          <Button onClick={handleContinue} disabled={!answers.length}>
            Next
          </Button>
        </FixedContainer>
      </S.Column>
    </S.Wrapper>
  )
}

Component.id = PageId.REASONS_1
Component.hasHeader = true

export const ReasonsVariant1 = Component
