import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  selectOptimizeExperimentIdByVariantIdMap,
  selectOptimizeExperimentMap,
  selectOptimizeMap,
} from 'root-redux/selects/common'

export const useAlternativeOptimizeVariant = (
  experimentsMapIndex = 0,
): boolean => {
  const experimentsMap = useSelector(selectOptimizeExperimentMap)
  const optimizeMap = useSelector(selectOptimizeMap)
  const experimentIdByVariantIdMap = useSelector(
    selectOptimizeExperimentIdByVariantIdMap,
  )

  return useMemo(() => {
    if (!experimentsMap || !optimizeMap || !experimentIdByVariantIdMap) {
      return false
    }

    const experimentId = experimentsMap[experimentsMapIndex]
    const variantId = +experimentIdByVariantIdMap[experimentId]

    return !!variantId
  }, [
    experimentIdByVariantIdMap,
    experimentsMap,
    experimentsMapIndex,
    optimizeMap,
  ])
}
