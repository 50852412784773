import React from 'react'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import { App } from 'components/App'
import { store } from 'root-redux/store/store'
import './styles.css'

import '@fontsource/nunito/400.css'
import '@fontsource/nunito/500.css'
import '@fontsource/nunito/600.css'
import '@fontsource/nunito/700.css'
import '@fontsource/nunito/800.css'

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    // Facebook Pixel
    fbq: any
    // AppsFlyer WebSDK
    AF: any
    // ZenDesk plugin
    zE: any
    zESettings: any
    // Google analytics
    gtag: any
    MSStream: any
    // TikTok Pixel
    ttq: any
  }
}

createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <App />
  </Provider>,
)
