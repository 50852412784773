import { TPriceId, TTrialPriceId } from 'models/variant.model'

export const getProductId = ({
  priceId,
  trialPriceId,
  trialPeriodDays,
}: {
  priceId: TPriceId | null | undefined
  trialPriceId: TTrialPriceId | null | undefined
  trialPeriodDays: number | undefined
}): string => {
  if (trialPeriodDays) {
    return `${priceId};${trialPriceId || '-'};${trialPeriodDays}`
  }

  return `${priceId}${trialPriceId ? `;${trialPriceId}` : ''}`
}
