import { IUserStatus, IUserStatusRaw } from 'models/user.model'
import { LOGIN_METHOD_RAW_MAP } from 'root-constants/common'

export const getUserStatusFromRawUserStatus = (
  userStatusRaw: IUserStatusRaw,
): IUserStatus => ({
  hasSubscription: userStatusRaw.has_subscription,
  account: {
    hasAccount: userStatusRaw.account?.has_account,
    loginMethod:
      LOGIN_METHOD_RAW_MAP[userStatusRaw.account?.login_method] || null,
  },
  email: {
    hasEmail: userStatusRaw.email.has_email,
    onboardingEmail: userStatusRaw.email.onboarding_email,
  },
})
