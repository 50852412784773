import styled from 'styled-components'
import icon from 'assets/images/check-icon-white.svg'
import { Color } from 'root-constants/common'

export const StyledQuestionButton = {
  Wrapper: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 24px;
    height: 70px;
    border-radius: 16px;
    cursor: pointer;
    background-color: ${Color.GREY};
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    transition: all 0.2s ease-out;

    &[data-has-check-icon='true'] {
      height: auto;
      border-radius: 30px;
      padding: 16px 58px 16px 24px;
    }

    &[data-has-check-icon='true']::before {
      content: '';
      position: absolute;
      right: 21px;
      top: 50%;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      border: 2px solid #bcc9d0;
      border-radius: 4px;
    }

    input:checked + & {
      background-color: ${Color.ACCENT_VIOLET};
      color: #fff;

      &[data-has-check-icon='true'] {
        background-color: ${Color.GREY};
        color: ${Color.DEFAULT_TEXT};
      }

      &[data-has-check-icon='true']::before {
        background-color: ${Color.ACCENT_VIOLET};
        background-image: url(${icon});
        background-position: center;
        background-repeat: no-repeat;
        border: none;
      }
    }
  `,
}
