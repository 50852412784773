import styled from 'styled-components'

export const StyledExtendedTotalAmount = {
  Wrapper: styled.div`
    width: 100%;
    margin: 16px 0 24px;
    display: flex;
    flex-direction: column;
  `,
  Title: styled.h4`
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 900;
    line-height: 22px;
  `,
  Period: styled.span`
    text-transform: capitalize;
  `,
  PriceContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;

    em {
      font-style: normal;
      color: #fd417f;
    }

    strong {
      font-weight: 900;
      line-height: 16px;
    }

    :not(:last-child) {
      margin-bottom: 12px;
    }
  `,
}
