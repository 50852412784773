import React, { useContext } from 'react'
import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import { PageId } from 'root-constants/pages'
import familySafety from 'assets/images/family-safety.webp'
import { Button } from 'components/Button'
import { StyledFamilySafety as S } from './FamilySafety.styles'

const Component: TDynamicPageComponent = () => {
  const { onNextStep } = useContext(FlowContext)

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <img src={familySafety} alt="family-safety" />
      </S.ImageContainer>
      <S.Column>
        <S.Text>Be sure your family is in safety</S.Text>
        <Button onClick={onNextStep}>Continue</Button>
      </S.Column>
    </S.Wrapper>
  )
}

Component.id = PageId.FAMILY_SAFETY_1
Component.hasHeader = true

export const FamilySafetyVariant1 = Component
