import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetAnswersAction } from 'root-redux/actions/common'
import { selectUUID } from 'root-redux/selects/user'
import { eventLogger } from 'services/eventLogger.service'

export const useStartSession = (): void => {
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  useEffect(() => {
    dispatch(resetAnswersAction())
    eventLogger.logSessionStarted()
  }, [dispatch])

  useEffect(() => {
    if (uuid) {
      window.fbq('track', 'ViewContent', {}, { eventID: uuid })
    }
  }, [uuid])
}
