import { useSearchParams } from 'react-router-dom'

export const useURLSearchData = (): {
  setDataToURL: (key: string, value: string | string[]) => void
  getDataFromURL: (key: string) => string | string[]
  removeDataFromUrl: (keys: string[]) => void
} => {
  const [searchParams, setSearchParams] = useSearchParams()

  return {
    setDataToURL: (key: string, value: string | string[]) => {
      searchParams.set(key, Array.isArray(value) ? value.join(',') : value)
      setSearchParams(searchParams)
    },
    getDataFromURL: (key: string): string | string[] => {
      const answersArr = searchParams.get(key)?.split(',') || []

      if (!answersArr?.length) return ''

      return answersArr.length === 1 ? answersArr[0] : answersArr
    },
    removeDataFromUrl: (keys: string[]): void => {
      searchParams.forEach((...[, key]) => {
        if (keys.includes(key)) {
          searchParams.delete(key)
          setSearchParams(searchParams)
        }
      })
    },
  }
}
