import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import cardIcon from 'assets/images/sprite/card.svg'
import { FlowContext } from 'components/FlowContext'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { StripeFieldName } from 'root-constants/common'
import { resetErrorAction } from 'root-redux/actions/common'
import { EMPTY_FIELD_ERROR, THREEDS_REDIRECT_SEARCH_PARAM } from '../constants'
import {
  getDefaultPaymentErrorsState,
  getPaymentErrorStateBySubmitWithUntouchedFields,
} from '../helpers'
import { check3DSecure, purchaseAction } from '../redux/actions'
import { select3DSecureIframeUrl } from '../redux/selects'
import { TPaymentErrorState } from '../types'
import {
  stripeElementStyle,
  StyledStripePaymentForm as S,
} from './StripePaymentForm.styles'

export const StripePaymentForm: React.FC = () => {
  const { currentPageId } = useContext(FlowContext)
  const { search } = useLocation()
  const stripe = useStripe()
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const elements = useElements()
  const dispatch = useDispatch()
  const [errors, setErrors] = useState<TPaymentErrorState>(() =>
    getDefaultPaymentErrorsState(),
  )
  const [name, setName] = useState('')

  const hasErrors = useMemo(
    () => Object.values(errors).some((error) => error.isShown && error.error),
    [errors],
  )
  const hasUntouchedFields = useMemo(
    () =>
      Object.values(errors).some((error) => error.isShown && !error.isTouched),
    [errors],
  )
  const hasUncompletedFields = useMemo(
    () => Object.values(errors).some((field) => !field.isComplete),
    [errors],
  )
  const isFormValid = useMemo(
    () => !hasErrors && !hasUntouchedFields && !hasUncompletedFields,
    [hasErrors, hasUncompletedFields, hasUntouchedFields],
  )

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const isSuccess = URLParams.has(THREEDS_REDIRECT_SEARCH_PARAM)

    if (!isSuccess || !threeDSecureIframeUrl || !stripe) return

    dispatch(check3DSecure(stripe))
  }, [dispatch, search, stripe, threeDSecureIframeUrl])

  const handleChange = useCallback(
    ({
      fieldName,
      isEmpty,
      hasError,
      isComplete,
    }: {
      fieldName: StripeFieldName
      isEmpty: boolean
      hasError: boolean
      isComplete: boolean
    }) => {
      dispatch(resetErrorAction())

      let error = ''

      if (hasError) {
        error = 'is invalid'
      }

      if (isEmpty) {
        error = EMPTY_FIELD_ERROR
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: { isTouched: true, error, isComplete },
      }))
    },
    [dispatch],
  )

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      dispatch(resetErrorAction())

      if (hasUntouchedFields) {
        setErrors(getPaymentErrorStateBySubmitWithUntouchedFields(errors))
        return
      }

      if (hasErrors) return

      const card = elements?.getElement(CardNumberElement)

      if (!stripe || !card) return

      dispatch(
        purchaseAction({
          stripe,
          card,
          name,
          paymentPageId: currentPageId,
        }),
      )
    },
    [
      dispatch,
      hasUntouchedFields,
      hasErrors,
      elements,
      stripe,
      name,
      currentPageId,
      errors,
    ],
  )

  return (
    <S.Form onSubmit={handleSubmit}>
      <S.InputContainer>
        <div>
          <S.Label>Card number</S.Label>
          <S.CardNumberContainer
            data-invalid={!!errors[StripeFieldName.NUMBER].error}
          >
            <S.CardNumberElement
              options={{
                showIcon: false,
                placeholder: '1234 1234 1234 1234',
                style: stripeElementStyle,
              }}
              onChange={({ empty, error, complete }) => {
                handleChange({
                  fieldName: StripeFieldName.NUMBER,
                  isEmpty: empty,
                  hasError: !!error,
                  isComplete: complete,
                })
              }}
            />
          </S.CardNumberContainer>
        </div>
        <S.CvvExpiryInputContainer>
          <div>
            <S.Label>Expiry date</S.Label>
            <S.CardExpiryContainer
              data-invalid={!!errors[StripeFieldName.EXPIRY].error}
            >
              <S.CardExpiryElement
                options={{
                  placeholder: '01/22',
                  style: stripeElementStyle,
                }}
                onChange={({ empty, error, complete }) => {
                  handleChange({
                    fieldName: StripeFieldName.EXPIRY,
                    isEmpty: empty,
                    hasError: !!error,
                    isComplete: complete,
                  })
                }}
              />
            </S.CardExpiryContainer>
          </div>
          <div>
            <S.Label>CVC</S.Label>
            <S.CardCvvContainer
              data-invalid={!!errors[StripeFieldName.CVC].error}
            >
              <S.CardSvg svg={cardIcon} />
              <S.CardCvcElement
                options={{
                  placeholder: '111',
                  style: stripeElementStyle,
                }}
                onChange={({ empty, error, complete }) => {
                  handleChange({
                    fieldName: StripeFieldName.CVC,
                    isEmpty: empty,
                    hasError: !!error,
                    isComplete: complete,
                  })
                }}
              />
            </S.CardCvvContainer>
          </div>
        </S.CvvExpiryInputContainer>
        <div>
          <S.Label>Cardholder name</S.Label>
          <S.CardholderInput
            type="text"
            placeholder="JOHN SMITH"
            data-invalid={!!errors[StripeFieldName.NAME].error}
            onChange={(e) => {
              const value = e.target.value.trim()

              setName(value)

              handleChange({
                fieldName: StripeFieldName.NAME,
                isEmpty: false,
                hasError: false,
                isComplete: true,
              })
            }}
          />
        </div>
      </S.InputContainer>
      <S.SubmitButton type="submit" disabled={!stripe || !isFormValid}>
        Continue
      </S.SubmitButton>
    </S.Form>
  )
}
