import { IRawVariant, IVariant } from 'models/variant.model'
import { CENTS_IN_DOLLAR } from 'root-constants/common'
import { INITIAL_PRICE } from 'modules/subscriptions/constants'

export const getVariantFromRawVariant = (
  rawVariant: IRawVariant,
): IVariant => ({
  cohort: rawVariant.cohort_id,
  facebookPixelId: rawVariant.facebook_pixel_id,
  tiktokPixelId: rawVariant.tiktok_pixel_id,
  optimizeMap: rawVariant.optimize_map,
  optimizeExperimentMap: rawVariant.optimize_experiments,
  abSegmentName: rawVariant.ab_segment_name,
  steps: rawVariant.steps.map((step) => ({
    id: step.page_id,
    isPayment: step.is_payment,
    isQuestion: step.is_question,
    isSkippable: step.is_skippable,
    isSubscriptions: step.is_subscriptions,
    optimize: step.optimize
      ? {
          id: step.optimize.id,
          isDefault: step.optimize.is_default,
        }
      : null,
  })),
  subscriptions: rawVariant.prices.map((rawSubscription) => ({
    priceId: rawSubscription.price_id,
    payPalPlanId: rawSubscription.paypal_plan_id,
    periodName: rawSubscription.interval,
    periodQuantity: rawSubscription.interval_count,
    currentPrice: rawSubscription.current_price / CENTS_IN_DOLLAR,
    oldPrice: rawSubscription.old_price / CENTS_IN_DOLLAR,
    isDefault: rawSubscription.is_default,
    customPrice: rawSubscription.custom_price
      ? rawSubscription.custom_price / CENTS_IN_DOLLAR
      : INITIAL_PRICE,
    customPriceInterval: rawSubscription.custom_price_interval,
    trialPriceId: rawSubscription.trial_price_id,
    trialCurrentPrice: rawSubscription.trial_current_price
      ? rawSubscription.trial_current_price / CENTS_IN_DOLLAR
      : INITIAL_PRICE,
    trialPeriodDays: rawSubscription.trial_period_days,
    showOrder: rawSubscription.show_order,
    currency: rawSubscription.currency,
  })),
})
