import appleIcon from 'assets/images/continue-with-apple.svg'
import emailIcon from 'assets/images/continue-with-email.svg'
import googleIcon from 'assets/images/continue-with-google.svg'
import fbIcon from 'assets/images/continue-with-facebook.svg'
import { FlowContext } from 'components/FlowContext'
import { Modal } from 'components/Modal'
import { Spinner } from 'components/Spinner'
import firebase from 'firebase/app'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'
import { useAuthObserver } from 'hooks/useAuthObserver'
import { useGetRedirectResult } from 'hooks/useGetRedirectResult'
import { useInitFirebase } from 'hooks/useInitFirebase'
import { TDynamicPageComponent } from 'models/common.model'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { LoginMethod, PlatformOS, STRIPE_COHORT } from 'root-constants/common'
import { PageId } from 'root-constants/pages'
import { resetErrorAction } from 'root-redux/actions/common'
import { bindUserAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectError,
  selectIsFetching,
} from 'root-redux/selects/common'
import { eventLogger } from 'services/eventLogger.service'
import { StyledAccount as S } from './Account.styles'
import { StyledAccountButton } from './AccountButton.styles'
import { EmailAccount } from './components/EmailAccount'

const Component: TDynamicPageComponent = () => {
  const signInFirebase = (provider) =>
    firebase.auth().signInWithRedirect(provider)
  const location = useLocation()
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const error = useSelector(selectError)
  const isFetching = useSelector(selectIsFetching)
  const { onNextStep } = useContext(FlowContext)
  const [isModalShown, setIsModalShown] = useState(false)
  const [isEmailLoginShown, setIsEmailLoginShown] = useState<boolean>(false)
  const [isFirebaseDataLoading, setIsFirebaseDataLoading] = useState(false)

  const isAndroid = useMemo(
    () => getMobileOperatingSystem() === PlatformOS.ANDROID,
    [],
  )

  useEffect(() => {
    eventLogger.logCreateAccountShown()
  }, [])

  useEffect(() => {
    error && setIsModalShown(true)
  }, [error])

  useEffect(() => {
    if (location.pathname === '/account') {
      setIsEmailLoginShown(false)
    }
  }, [location.pathname])

  const handleContinueWithGoogle = useCallback(async () => {
    eventLogger.logLoginMethodSelected({ method: LoginMethod.GOOGLE })

    const provider = new firebase.auth.GoogleAuthProvider()
    provider.setCustomParameters({ prompt: 'select_account' })
    await signInFirebase(provider)
  }, [])

  const handleContinueWithFb = useCallback(async () => {
    eventLogger.logLoginMethodSelected({ method: LoginMethod.FACEBOOK })

    await signInFirebase(new firebase.auth.FacebookAuthProvider())
  }, [])

  const handleContinueWithApple = useCallback(async () => {
    eventLogger.logLoginMethodSelected({ method: LoginMethod.APPLE })

    await signInFirebase(new firebase.auth.OAuthProvider('apple.com'))
  }, [])

  const handleContinueWithEmail = useCallback(() => {
    setIsEmailLoginShown(true)

    eventLogger.logLoginMethodSelected({ method: LoginMethod.EMAIL })
  }, [])

  const handleButtonsClick = useCallback(
    (event) => {
      if (error) {
        event.stopPropagation()
        setIsModalShown(true)
      }
    },
    [error],
  )

  const authStateChangeHandler = useCallback(
    (token: string) => {
      dispatch(bindUserAction(token, onNextStep))
    },
    [dispatch, onNextStep],
  )

  useInitFirebase()
  useGetRedirectResult(authStateChangeHandler, setIsFirebaseDataLoading)
  useAuthObserver(authStateChangeHandler)

  return (
    <S.Wrapper>
      {(isFetching || isFirebaseDataLoading) && <Spinner />}
      {isEmailLoginShown ? (
        <EmailAccount
          onBack={() => {
            setIsEmailLoginShown(false)
          }}
        />
      ) : (
        <S.Column>
          <S.Title>Welcome to Famio!</S.Title>
          <S.Text>
            Sign up and create an account
            <br />
            {cohort === STRIPE_COHORT
              ? 'to stay connected with your close one'
              : `to track your loved one's location`}
          </S.Text>
          <S.Buttons onClickCapture={handleButtonsClick}>
            <StyledAccountButton.Email onClick={handleContinueWithEmail}>
              <img src={emailIcon} width={20} alt="email" />
              <span>Continue with Email</span>
            </StyledAccountButton.Email>
            <StyledAccountButton.Google onClick={handleContinueWithGoogle}>
              <img src={googleIcon} width={24} alt="google" />
              <span>Continue with Google</span>
            </StyledAccountButton.Google>
            {!isAndroid && (
              <StyledAccountButton.Apple onClick={handleContinueWithApple}>
                <img src={appleIcon} width={18} alt="apple" />
                <span>Continue with Apple</span>
              </StyledAccountButton.Apple>
            )}
            <StyledAccountButton.Facebook onClick={handleContinueWithFb}>
              <img src={fbIcon} width={24} alt="facebook" />
              <span>Continue with Facebook</span>
            </StyledAccountButton.Facebook>
          </S.Buttons>
        </S.Column>
      )}

      <Modal
        onClose={() => {
          setIsModalShown(false)
          dispatch(resetErrorAction())
        }}
        isShown={isModalShown}
      >
        {error}
      </Modal>
    </S.Wrapper>
  )
}

Component.hasHeader = true
Component.id = PageId.ACCOUNT

export const Account = Component
