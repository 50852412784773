import React from 'react'
import { PageId } from 'root-constants/pages'

interface IFlowContext {
  onNextStep: () => void
  onPreviousStep: () => void
  onSkipSteps: () => void
  onCancelOfferSubscriptions: () => void
  onDataQuestionPage: () => void
  currentPageId: PageId
}

const defaultState = {
  onNextStep: () => undefined,
  onPreviousStep: () => undefined,
  onSkipSteps: () => undefined,
  onCancelOfferSubscriptions: () => undefined,
  onDataQuestionPage: () => undefined,
  currentPageId: PageId.DOWNLOAD,
}

export const FlowContext = React.createContext<IFlowContext>(defaultState)
