import { Option, OptionType } from 'components/Option'
import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'root-constants/pages'
import { setAnswersAction } from 'root-redux/actions/common'
import { QuestionButton } from 'components/QuestionButton'
import { StyledOption } from 'components/Option.styles'
import { useURLSearchData } from 'hooks/useURLSearchData'
import { GOALS_PARAM_KEY, UrlDataGoals } from 'root-constants/urlData'
import { FREQUENCY_OPTIONS_VALUES } from 'root-constants/common'
import { StyledConcerns as S } from './Conserns.styles'

const PAGE_NAME = 'Concerns about safety'
const QUESTION =
  'How often do you have any concerns about your loved one’s safety?'

const Component: TDynamicPageComponent = () => {
  const { onNextStep, onSkipSteps, currentPageId } = useContext(FlowContext)
  const dispatch = useDispatch()
  const { getDataFromURL } = useURLSearchData()

  const goals = useMemo(() => getDataFromURL(GOALS_PARAM_KEY), [getDataFromURL])

  const isFriendGoal = useMemo(() => goals === UrlDataGoals.FRIEND, [goals])

  const isPartnerGoal = useMemo(() => goals === UrlDataGoals.PARTNER, [goals])

  useEffect(() => {
    isFriendGoal && onNextStep()
  }, [isFriendGoal, onNextStep])

  const handleContinue = useCallback(
    (answer) => {
      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: answer,
          pageId: currentPageId,
          pageName: PAGE_NAME,
        }),
      )
      if (
        isPartnerGoal &&
        (answer === FREQUENCY_OPTIONS_VALUES.never ||
          answer === FREQUENCY_OPTIONS_VALUES.rarely)
      ) {
        onSkipSteps()
        return
      }
      onNextStep()
    },
    [currentPageId, dispatch, isPartnerGoal, onNextStep, onSkipSteps],
  )

  const optionProps = useMemo(
    () => ({
      name: currentPageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  return isFriendGoal ? null : (
    <S.Wrapper>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option {...optionProps} value={FREQUENCY_OPTIONS_VALUES.quiteOften}>
            <QuestionButton>
              {FREQUENCY_OPTIONS_VALUES.quiteOften}
            </QuestionButton>
          </Option>
          <Option {...optionProps} value={FREQUENCY_OPTIONS_VALUES.sometimes}>
            <QuestionButton>
              {FREQUENCY_OPTIONS_VALUES.sometimes}
            </QuestionButton>
          </Option>
          <Option {...optionProps} value={FREQUENCY_OPTIONS_VALUES.rarely}>
            <QuestionButton>{FREQUENCY_OPTIONS_VALUES.rarely}</QuestionButton>
          </Option>
          <Option {...optionProps} value={FREQUENCY_OPTIONS_VALUES.never}>
            <QuestionButton>{FREQUENCY_OPTIONS_VALUES.never}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}

Component.id = PageId.CONCERNS_1
Component.hasHeader = true

export const ConcernsVariant1 = Component
