import { ApiService } from 'services/api.service'
import { CurrentEnvironment, RequestMethod } from 'root-constants/common'
import { Events } from 'services/eventLogger.service'
import { IResponseResult } from 'models/api.model'
import { getCurrentEnv } from 'helpers/getCurrentEnv'

const btoaAuthCredentialsProd =
  'cHJvZC1mYWNlLXlvZ2Etd3Nob29rc3VzZXI6WXFCNH1tYVJvMQ=='
const btoaAuthCredentialsDev =
  'ZGV2LWZhY2UteW9nYS13c2hvb2tzdXNlcjptWUpTbWdtM3Vn'

export class EventsApi {
  private readonly api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  sendEvent(
    event: Events,
    eventProperty: Record<string, any>,
  ): Promise<IResponseResult<never>> {
    const options = {
      method: RequestMethod.POST,
      headers: new Headers({
        Authorization: `Basic ${this.getBtoaAuthCredentials()}`,
      }),
      body: { event, ...eventProperty },
    }
    return this.api.makeRequest<never>('v1/events/landing', options)
  }

  private getBtoaAuthCredentials = (): string =>
    getCurrentEnv() === CurrentEnvironment.PROD
      ? btoaAuthCredentialsProd
      : btoaAuthCredentialsDev
}
