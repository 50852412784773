import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'components/styles'
import { HEADER_HEIGHT } from 'root-constants/common'
import styled from 'styled-components'

export const StyledTakeCalls = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 54px;
  `,
}
