import { IEventLogger } from 'models/events.model'
import { EventLoggerInstanceName, PlatformOS } from 'root-constants/common'
import { eventsApi } from 'api'
import { Events } from 'services/eventLogger.service'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

export class UserFlowTracker implements IEventLogger {
  public readonly name: EventLoggerInstanceName
  private readonly userId: string
  private cohort = ''
  private deviceType: PlatformOS = PlatformOS.UNKNOWN

  constructor({
    userId,
    instanceName,
  }: {
    userId: string
    instanceName: EventLoggerInstanceName
  }) {
    this.userId = userId
    this.name = instanceName
  }

  configure({ cohort }: { cohort: string }): void {
    this.cohort = cohort
    this.deviceType = getMobileOperatingSystem()
  }

  log(event: Events, eventProperty?: Record<string, unknown>): void {
    const timestampInSec = Math.floor(Date.now() / 1000)

    const mergedEventProperty = {
      cohort: this.cohort,
      user_id: this.userId,
      device_type: this.deviceType,
      timestamp: timestampInSec,
      ...eventProperty,
    }

    eventsApi.sendEvent(event, mergedEventProperty)
  }
}
