import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import textAnimation from 'assets/animations/textAnimation.json'
import lottie from 'lottie-web/build/player/lottie_light'
import logo from 'assets/images/logo.webp'
import { FlowContext } from 'components/FlowContext'
import { setAnswersAction } from 'root-redux/actions/common'
import { useDispatch } from 'react-redux'
import {
  GlobalStyleForProcessing,
  StyledPreloader as S,
} from './Preloader.styles'

const PAGE_NAME = 'Preloader'

export const PreloaderVariant1: React.FC = () => {
  const dispatch = useDispatch()
  const { onNextStep, currentPageId } = useContext(FlowContext)
  const [isButtonShown, setIsButtonShown] = useState(false)

  const animationRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (animationRef.current) {
      const animation = lottie.loadAnimation({
        container: animationRef.current,
        animationData: textAnimation,
        loop: false,
      })
      animation.addEventListener('complete', () => setIsButtonShown(true))
    }
  }, [])

  const handleContinue = useCallback(() => {
    dispatch(
      setAnswersAction({
        question: '',
        answers: '',
        pageId: currentPageId,
        pageName: PAGE_NAME,
      }),
    )
    onNextStep()
  }, [currentPageId, dispatch, onNextStep])

  return (
    <>
      <GlobalStyleForProcessing />
      <S.Wrapper>
        <S.Header>
          <img height="38" src={logo} alt="logo" />
        </S.Header>
        <div
          ref={animationRef}
          onMouseEnter={() => {
            setIsButtonShown(true)
          }}
        />
        <S.Button data-is-shown={isButtonShown} onClick={handleContinue}>
          Activate my offer
        </S.Button>
      </S.Wrapper>
    </>
  )
}
