import styled from 'styled-components'

export const StyledWrapper = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  padding: 77px 0 32px;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 41%
  );
  background-size: cover;
  background-repeat: no-repeat;
  pointer-events: none;

  button {
    pointer-events: auto;
  }
`
