import { Color } from 'root-constants/common'
import styled from 'styled-components'

export const StyledTrialPlanBlock = {
  Wrapper: styled.div`
    width: 100%;
    font-size: 16px;
    line-height: 22px;
  `,
  PriceTitle: styled.strong`
    display: block;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 16px;
  `,
  TrialPriceWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #b2b8cd;
    margin-bottom: 32px;
    padding: 15px 0;
    border-top: 1px solid ${Color.GREY};
    border-bottom: 1px solid ${Color.GREY};
  `,
  Period: styled.span`
    font-weight: 800;
    display: block;
  `,
  Price: styled.span`
    font-weight: 700;
  `,
  PriceAfterTrialWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-top: 1px solid ${Color.GREY};
    border-bottom: 1px solid ${Color.GREY};
    margin-bottom: 24px;
  `,
  Discount: styled.span`
    display: inline-block;
    font-weight: 800;
    font-size: 13px;
    line-height: 22px;
    margin-top: 4px;
    padding: 4px 8px;
    color: #fff;
    background-color: ${Color.ACCENT_VIOLET};
    border-radius: 10px;
  `,
}
