import React, { useContext, useEffect, useMemo } from 'react'
import { FlowContext } from 'components/FlowContext'
import { useCounter } from 'hooks/useCounter'
import { TDynamicPageComponent } from 'models/common.model'
import { PageId } from 'root-constants/pages'
import { StyledPreparingFlow as S } from './PreparingFlow.styles'

const Component: TDynamicPageComponent = () => {
  const { onNextStep } = useContext(FlowContext)

  const { counter: sosCounterPercent, isFinished: isSosCounterFinished } =
    useCounter(true, {
      duration: 4000,
    })

  const {
    counter: placeAlertsCounterPercent,
    isFinished: isPlaceAlertsCounterFinished,
  } = useCounter(isSosCounterFinished, {
    duration: 4000,
  })

  const {
    counter: batteryAlertsCounterPercent,
    isFinished: isBatteryAlertsCounterFinished,
  } = useCounter(isPlaceAlertsCounterFinished, {
    duration: 4000,
  })

  const barsList = useMemo(
    () => [
      {
        title: 'Setting up SOS',
        color: '#199cf3',
        counter: sosCounterPercent,
      },
      {
        title: 'Adding Place Alerts',
        color: '#7879f1',
        counter: placeAlertsCounterPercent,
      },
      {
        title: 'Adding Battery Alerts',
        color: '#f24281',
        counter: batteryAlertsCounterPercent,
      },
    ],
    [batteryAlertsCounterPercent, placeAlertsCounterPercent, sosCounterPercent],
  )

  useEffect(() => {
    if (!isBatteryAlertsCounterFinished) return
    onNextStep()
  }, [isBatteryAlertsCounterFinished, onNextStep])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>Creating your profile</S.Title>
        <S.ProgressBarsList>
          {barsList.map(({ title, color, counter }) => (
            <S.ProgressContainer key={title}>
              <S.ProgressHeader>
                <S.ProgressTitle>{title}</S.ProgressTitle>
                <S.ProgressPercent>{counter}</S.ProgressPercent>
              </S.ProgressHeader>
              <S.ProgressBar max={100} value={counter} color={color} />
            </S.ProgressContainer>
          ))}
        </S.ProgressBarsList>
      </S.Column>
    </S.Wrapper>
  )
}

Component.hasHeader = true
Component.id = PageId.PREPARING_FLOW_2

export const PreparingFlowVariant2 = Component
