import React, { ButtonHTMLAttributes } from 'react'
import { StyledComponentProps } from 'styled-components'
import { StyledStickyButton } from './StickyButton.styles'

export const StickyButton: React.FC<
  ButtonHTMLAttributes<HTMLButtonElement> &
    StyledComponentProps<any, any, any, any>
> = ({ children, ...props }) => (
  <StyledStickyButton {...props}>{children}</StyledStickyButton>
)
