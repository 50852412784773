import { getProductId } from 'helpers/getProductId'
import { ISubscription } from 'models/variant.model'

export const enum GoogleAnalyticsAction {
  EVENT = 'event',
}

export const enum GoogleAnalyticsEvent {
  PAGE_VIEW = 'page_view',
  BEGIN_CHECKOUT = 'begin_checkout',
  ADD_TO_CART = 'add_to_cart',
  PURCHASE = 'purchase',
  FIRST_PAGE_COMPLETED = 'first_page_completed',
}

export const enum GoogleAnalyticsEventCategory {
  TRANSITION = 'transition',
}

class GoogleAnalyticsService {
  logPageView(location: string): void {
    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.PAGE_VIEW, {
      page_path: `/${location}`,
    })
  }

  logCheckoutStarted(subscriptions: ISubscription[]): void {
    window.gtag(
      GoogleAnalyticsAction.EVENT,
      GoogleAnalyticsEvent.BEGIN_CHECKOUT,
      {
        items: subscriptions.map(
          ({ priceId, trialPriceId, trialPeriodDays, currentPrice }) => ({
            id: getProductId({
              priceId,
              trialPriceId,
              trialPeriodDays,
            }),
            price: currentPrice,
          }),
        ),
      },
    )
  }

  logAddingToCart({
    priceId,
    trialPriceId,
    trialPeriodDays,
    currentPrice,
  }: ISubscription): void {
    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.ADD_TO_CART, {
      items: [
        {
          id: getProductId({
            priceId,
            trialPriceId,
            trialPeriodDays,
          }),
          price: currentPrice,
        },
      ],
    })
  }

  logPurchaseCompleted({
    subscriptionId,
    price,
    productId,
    currency = 'USD',
  }: {
    subscriptionId: string
    price: number
    productId: string
    currency?: string
  }): void {
    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.PURCHASE, {
      transaction_id: subscriptionId,
      value: price,
      currency,
      items: [
        {
          id: productId,
          price,
        },
      ],
    })
  }

  logFirstPageCompleted(): void {
    window.gtag(
      GoogleAnalyticsAction.EVENT,
      GoogleAnalyticsEvent.FIRST_PAGE_COMPLETED,
      {
        event_category: GoogleAnalyticsEventCategory.TRANSITION,
      },
    )
  }
}

export const googleAnalyticsLogger = new GoogleAnalyticsService()
