import React from 'react'
import { TERMS_OF_USE_LINK } from 'root-constants/common'
import { eventLogger } from 'services/eventLogger.service'
import { OuterLink } from './OuterLink'

type TProps = {
  title?: string
}

export const TermsOfUseLink: React.FC<TProps> = ({ title }) => (
  <OuterLink
    href={TERMS_OF_USE_LINK}
    onClick={() => eventLogger.logTermsOfUseClicked()}
  >
    {title || 'Terms of Use'}
  </OuterLink>
)
