import { ISelectPlanItemProps } from 'modules/subscriptions/types'
import React, { useMemo } from 'react'
import { DISCOUNT_PERCENT } from 'root-constants/common'
import { StyledPlanItem as S } from './PlanItemVariant1.styles'

export const PlanItemVariant1: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    periodQuantity,
    periodName,
    currentPrice,
    customPrice,
    customPriceInterval,
    oldPrice,
    isDefault,
  },
  isSelected,
}) => {
  const integerCustomPrice = useMemo(
    () => (customPrice ? Math.trunc(customPrice) : 0),
    [customPrice],
  )

  const fractionalCustomPrice = useMemo(
    () =>
      customPrice
        ? Number((customPrice - integerCustomPrice).toFixed(2)) * 100
        : '00',
    [customPrice, integerCustomPrice],
  )

  const discountText = useMemo(() => {
    const discountAmount = Math.ceil(
      (currentPrice / (100 - DISCOUNT_PERCENT)) * 100 - currentPrice,
    )

    return periodQuantity === 1
      ? `Save $${discountAmount} every ${periodName}!`
      : `Save $${discountAmount} every ${periodQuantity} ${periodName}s!`
  }, [currentPrice, periodName, periodQuantity])

  return (
    <S.Wrapper data-is-default={isDefault} data-is-selected={isSelected}>
      <S.PlanItem data-is-selected={isSelected}>
        <S.PlanTitle>
          {periodQuantity}-{periodName}
          <br />
          plan
        </S.PlanTitle>
        {oldPrice && <S.OldPlanPrice>${oldPrice.toFixed(2)}</S.OldPlanPrice>}
        {customPrice && (
          <S.PlanPrice>
            <strong data-fractional={fractionalCustomPrice}>
              {integerCustomPrice}
            </strong>
            <sub>per {customPriceInterval}</sub>
          </S.PlanPrice>
        )}
      </S.PlanItem>
      <S.Discount>{discountText}</S.Discount>
    </S.Wrapper>
  )
}
