import React, { HTMLAttributes } from 'react'
import secureImg from 'assets/images/secure.jpg'
import { StyledSecurity as S } from './Security.styles'

export const Security: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...props
}) => (
  <S.SecureCard {...props}>
    <S.SecureImage src={secureImg} height="60" alt="secure" />
    <ul>
      <li>
        <S.SecureItemTitle>Your information is safe</S.SecureItemTitle>
        <S.SecureItemText>
          We won&#39;t sell or rent your personal contact information for any
          marketing purposes whatsoever.
        </S.SecureItemText>
      </li>
      <li>
        <S.SecureItemTitle>Secure checkout</S.SecureItemTitle>
        <S.SecureItemText>
          All information is encrypted and transmitted without risk using a
          Secure Socket Layer protocol.
        </S.SecureItemText>
      </li>
      <li>
        <S.SecureItemTitle>Need help</S.SecureItemTitle>
        <S.SecureItemText style={{ whiteSpace: 'nowrap' }}>
          Send us an email:{' '}
          <a href="mailto:famio@harmonybit.com">famio@harmonybit.com</a>
        </S.SecureItemText>
      </li>
    </ul>
  </S.SecureCard>
)
