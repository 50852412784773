import { BASE_COLUMN_WIDTH } from 'root-constants/common'
import styled from 'styled-components'

export const StyledHeader = {
  HeaderWrapper: styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-bottom: 1px solid #ccdce5;
  `,
  Header: styled.header`
    width: ${BASE_COLUMN_WIDTH}px;
    padding: 11px 0 6px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
}
