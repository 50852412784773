import { IStep } from 'models/variant.model'
import { PageId } from 'root-constants/pages'
import { IUserFlowContext } from 'services/userFlowState.service'

export const getUserFlowBaseConfig = (pages: IStep[]): any => ({
  initial: {
    always: [
      {
        cond: (ctx: IUserFlowContext): boolean => {
          const {
            hasSubscription,
            account: { hasAccount },
            email: { hasEmail },
          } = ctx.userStatus
          return !hasEmail && !hasSubscription && !hasAccount
        },
        target: pages[0].id,
      },
      {
        cond: (ctx: IUserFlowContext): boolean =>
          !!ctx.userStatus?.account.hasAccount,
        target: PageId.DOWNLOAD,
      },
      {
        cond: (ctx: IUserFlowContext): boolean =>
          !!ctx.userStatus?.hasSubscription,
        target: PageId.ACCOUNT,
      },
      {
        cond: (ctx: IUserFlowContext): boolean =>
          !!ctx.userStatus?.email?.hasEmail,
        target: pages.find((page) => page.isSubscriptions)?.id || pages[0].id,
      },
    ],
  },
  ...pages.reduce((acc, page, index) => {
    const subscriptionsPage =
      pages.find((dynamicPage) => dynamicPage.isSubscriptions)?.id ||
      pages[0].id
    const getNextFlowStepTarget = () => {
      const isLastPage = pages.length === index + 1
      return isLastPage ? PageId.ACCOUNT : pages[index + 1].id
    }
    const dataQuestionPage = pages.find(
      (dynamicPage) => dynamicPage.id === PageId.DATA_1,
    )?.id

    return {
      ...acc,
      [page.id]: {
        on: {
          NEXT_FLOW_STEP: {
            target: getNextFlowStepTarget(),
          },
          SKIP_FLOW_STEPS: {
            target: (
              pages.find(
                (dynamicPage, dynamicPageIndex) =>
                  !dynamicPage.isSkippable && dynamicPageIndex > index,
              ) || page
            ).id,
          },
          DATA_QUESTION_PAGE: {
            target: dataQuestionPage,
          },
          ...(page.isPayment && {
            PREVIOUS_FLOW_STEP: {
              target: subscriptionsPage,
            },
          }),
        },
      },
    }
  }, {}),
  account: {
    on: {
      NEXT_FLOW_STEP: PageId.DOWNLOAD,
    },
  },
  download: {
    type: 'final',
  },
})
