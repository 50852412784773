import { selectSubscription } from 'modules/payment/redux/selects'
import React, { forwardRef, HTMLAttributes } from 'react'
import { useSelector } from 'react-redux'
import { StyledTrialPlanBlock as S } from './TrialPlanBlock.styles'

export const TrialPlanBlock = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ ...props }, ref) => {
  const selectedSubscription = useSelector(selectSubscription)

  return (
    <S.Wrapper ref={ref} {...props}>
      <S.PriceTitle>Price Today</S.PriceTitle>
      <S.TrialPriceWrapper>
        <S.Period>{selectedSubscription?.trialPeriodDays}-Day Trial</S.Period>
        <S.Price>${selectedSubscription?.trialCurrentPrice}</S.Price>
      </S.TrialPriceWrapper>
      <S.PriceTitle>Price After Trial</S.PriceTitle>
      <S.PriceAfterTrialWrapper>
        <div>
          <S.Period>{selectedSubscription?.periodQuantity}-Month Plan</S.Period>
          <S.Discount>37% OFF</S.Discount>
        </div>
        <S.Price>${selectedSubscription?.currentPrice}</S.Price>
      </S.PriceAfterTrialWrapper>
    </S.Wrapper>
  )
})
