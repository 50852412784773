/* eslint-disable max-lines */
import {
  PaymentMethodResult,
  PaymentRequestPaymentMethodEvent,
  Stripe,
  StripeCardNumberElement,
  StripeError,
} from '@stripe/stripe-js'
import { paymentApi } from 'api'
import { getProductId } from 'helpers/getProductId'
import { IAction, IAppState, TAppDispatchThunk } from 'models/store.model'
import { ISubscription } from 'models/variant.model'
import { DEFAULT_CARDHOLDER_NAME } from 'modules/payment/constants'
import { getRedirectUrl } from 'modules/payment/helpers'
import { CENTS_IN_DOLLAR } from 'root-constants/common'
import { PageId } from 'root-constants/pages'
import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { getUserStatusAction } from 'root-redux/actions/user'
import { selectEmail, selectUUID } from 'root-redux/selects/user'
import { eventLogger } from 'services/eventLogger.service'
import { checkIsRetryAllowed } from '../helpers/checkIsRetryAllowed'
import { logFailedPayment } from '../helpers/logFailedPayment'
import { logSuccessfulPayment } from '../helpers/logSuccessfulPayment'
import {
  selectCurrency,
  selectPaymentClientSecret,
  selectSubscriptionCurrentPrice,
  selectSubscriptionId,
  selectSubscriptionPriceId,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
  selectSubscriptionTrialPriceId,
} from './selects'

const MODULE_NAME = 'PAYMENT'

export const SET_SUBSCRIPTION = `${MODULE_NAME}/SET_SUBSCRIPTION`
export const PURCHASE = `${MODULE_NAME}/PURCHASE`
export const CHECK_3D_SECURE = `${MODULE_NAME}/CHECK_3D_SECURE`
export const SET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/SET_3D_SECURE_IFRAME_URL`
export const RESET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/RESET_3D_SECURE_IFRAME_URL`
export const SET_PAYMENT_CLIENT_SECRET = `${MODULE_NAME}/SET_PAYMENT_CLIENT_SECRET`
export const SET_TRIAL_PERIOD_DAYS = `${MODULE_NAME}/SET_TRIAL_PERIOD_DAYS`
export const SET_SUBSCRIPTION_ID = `${MODULE_NAME}/SET_SUBSCRIPTION_ID`
export const SET_IS_PAYMENT_FLOWS_SHOWN = `${MODULE_NAME}/SET_IS_PAYMENT_FLOWS_SHOWN`

const getErrorActionPayload = ({ type, message }: StripeError): string =>
  message || type

const set3DSecureIframeUrlAction = (payload: string): IAction<string> => ({
  type: SET_3D_SECURE_IFRAME_URL,
  payload,
})

const reset3DSecureIframeUrlAction = (): IAction<never> => ({
  type: RESET_3D_SECURE_IFRAME_URL,
})

const setPaymentClientSecretAction = (payload: string): IAction<string> => ({
  type: SET_PAYMENT_CLIENT_SECRET,
  payload,
})

const setSubscriptionIdAction = (payload: string): IAction<string> => ({
  type: SET_SUBSCRIPTION_ID,
  payload,
})

export const setIsPaymentFlowsShownAction = (
  payload: boolean,
): IAction<boolean> => ({
  type: SET_IS_PAYMENT_FLOWS_SHOWN,
  payload,
})

export const setSelectedSubscriptionAction = (
  payload: ISubscription,
): IAction<ISubscription> => ({
  type: SET_SUBSCRIPTION,
  payload,
})

export const purchaseAction =
  ({
    stripe,
    paymentPageId,
    card,
    name,
    createPaymentResFromDigitalWallet,
  }: {
    stripe: Stripe
    paymentPageId: PageId
    card?: StripeCardNumberElement
    name?: string
    createPaymentResFromDigitalWallet?: PaymentRequestPaymentMethodEvent
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const priceId = selectSubscriptionPriceId(state)
    const trialPriceId = selectSubscriptionTrialPriceId(state)
    const uuid = selectUUID(state)
    const currentPrice = selectSubscriptionCurrentPrice(state)
    const trialPeriodPrice = selectSubscriptionTrialPeriodPrice(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const email = selectEmail(state)
    const currency = selectCurrency(state)

    if (!priceId || !currentPrice) {
      console.error('Error: no subscriptions plan selected')
      return
    }

    dispatch(startFetching(PURCHASE))

    const productId = getProductId({
      priceId,
      trialPriceId,
      trialPeriodDays,
    })

    const paymentMethod =
      createPaymentResFromDigitalWallet?.paymentMethod?.card?.wallet?.type

    eventLogger.logPurchaseStarted({
      email,
      productId,
      priceDetails: {
        price: currentPrice,
        trial: !!trialPeriodPrice,
      },
      paymentMethod,
      // isCancelOfferApplied,
    })

    try {
      const createPaymentResponse =
        card && !createPaymentResFromDigitalWallet
          ? await stripe.createPaymentMethod({
              type: 'card',
              card,
              billing_details: { name: name || DEFAULT_CARDHOLDER_NAME },
            })
          : (createPaymentResFromDigitalWallet as PaymentMethodResult)

      if (
        !createPaymentResponse?.paymentMethod &&
        createPaymentResponse?.error
      ) {
        const {
          error: { type, code, message },
        } = createPaymentResponse

        eventLogger.logPurchaseFailed({
          email,
          productId,
          priceDetails: {
            price: currentPrice,
            trial: !!trialPeriodPrice,
          },
          error: {
            type,
            code,
            description: message,
          },
          paymentMethod,
          // isCancelOfferApplied,
        })
        dispatch(setErrorAction(message || type))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const isFreeTrial = !!trialPeriodDays && !trialPriceId
      const createSubscriptionResponse = await paymentApi.createSubscription({
        uuid,
        priceId,
        trialPriceId,
        trialPeriodDays,
        // Because retrying payments (discounting) don't work with free trials
        paymentMethodId: isFreeTrial
          ? createPaymentResponse.paymentMethod.id
          : '',
      })

      if (
        !createSubscriptionResponse.success ||
        !createSubscriptionResponse.data
      ) {
        // It's a hack for fixing behavior after changing response
        // from WS BE after getting payment_method
        if (
          createSubscriptionResponse.data?.error?.startsWith(
            'creating new customer',
          )
        ) {
          let error = { type: createSubscriptionResponse.data?.error }

          try {
            error = JSON.parse(
              createSubscriptionResponse.data.error.split(
                'creating new customer: ',
              )[1],
            )
          } finally {
            logFailedPayment({
              email,
              productId,
              price: currentPrice,
              isTrialActive: !!trialPeriodPrice,
              paymentResponse: error,
              paymentMethod,
              // isCancelOfferApplied,
            })
            dispatch(
              setErrorAction({ type: createSubscriptionResponse.data?.error }),
            )
            dispatch(stopFetching(PURCHASE))
            createPaymentResFromDigitalWallet?.complete('fail')
          }
          return
        }

        if (isFreeTrial) {
          logFailedPayment({
            email,
            productId,
            price: currentPrice,
            isTrialActive: !!trialPeriodPrice,
            paymentResponse: { type: createSubscriptionResponse.data?.error },
            paymentMethod,
            // isCancelOfferApplied,
          })
          dispatch(
            setErrorAction({ type: createSubscriptionResponse.data?.error }),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(setErrorAction('Error: Something went wrong'))
        logFailedPayment({
          email,
          productId,
          price: currentPrice,
          isTrialActive: !!trialPeriodPrice,
          paymentResponse: { type: createSubscriptionResponse.data?.error },
          paymentMethod,
          // isCancelOfferApplied,
        })
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      if (isFreeTrial) {
        logSuccessfulPayment({
          email,
          productId,
          price: currentPrice,
          trialPrice: trialPeriodPrice,
          trialPeriodDays:
            createSubscriptionResponse.data.subscription.trial_period_days,
          subscriptionId:
            createSubscriptionResponse.data.subscription.subscription_id,
          uuid,
          paymentMethod,
          currency,
          // isCancelOfferApplied,
        })
        dispatch(getUserStatusAction(uuid))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('success')
        return
      }

      dispatch(
        setPaymentClientSecretAction(
          createSubscriptionResponse.data.subscription.client_secret,
        ),
      )
      dispatch(
        setSubscriptionIdAction(
          createSubscriptionResponse.data.subscription.subscription_id,
        ),
      )

      const cardPaymentResponseFirst = await stripe.confirmCardPayment(
        createSubscriptionResponse.data.subscription.client_secret,
        {
          payment_method: createPaymentResponse.paymentMethod.id,
          save_payment_method: true,
          return_url: getRedirectUrl(paymentPageId),
        },
      )

      if (
        !cardPaymentResponseFirst?.paymentIntent &&
        cardPaymentResponseFirst?.error
      ) {
        logFailedPayment({
          email,
          productId,
          price: currentPrice,
          isTrialActive: !!trialPeriodPrice,
          paymentResponse: cardPaymentResponseFirst.error,
          paymentMethod,
          currency,
          // isCancelOfferApplied,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseFirst)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseFirst.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseFirst = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseFirst.error as StripeError,
        })

        if (!retryResponseFirst.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseFirst.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            createSubscriptionResponse.data.subscription.client_secret,
          ),
        )
        dispatch(
          setSubscriptionIdAction(
            createSubscriptionResponse.data.subscription.subscription_id,
          ),
        )

        const cardPaymentResponseSecond = await stripe.confirmCardPayment(
          retryResponseFirst.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseSecond?.paymentIntent &&
          !cardPaymentResponseSecond?.error
        ) {
          logSuccessfulPayment({
            email,
            productId,
            uuid,
            price:
              cardPaymentResponseSecond.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPrice:
              cardPaymentResponseSecond.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPeriodDays:
              retryResponseFirst.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseFirst.data.subscription.subscription_id,
            discountApplied:
              retryResponseFirst.data.subscription.discount_applied,
            paymentMethod,
            currency,
            // isCancelOfferApplied,
          })
          dispatch(getUserStatusAction(uuid))
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          email,
          productId,
          price: currentPrice,
          isTrialActive: !!trialPeriodPrice,
          paymentResponse: cardPaymentResponseSecond.error,
          paymentMethod,
          // isCancelOfferApplied,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseSecond)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseSecond.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseSecond = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseSecond.error as StripeError,
        })

        if (!retryResponseSecond.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseSecond.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            createSubscriptionResponse.data.subscription.client_secret,
          ),
        )
        dispatch(
          setSubscriptionIdAction(
            createSubscriptionResponse.data.subscription.subscription_id,
          ),
        )

        const cardPaymentResponseThird = await stripe.confirmCardPayment(
          retryResponseSecond.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseThird?.paymentIntent &&
          !cardPaymentResponseThird?.error
        ) {
          logSuccessfulPayment({
            email,
            productId,
            uuid,
            price:
              cardPaymentResponseThird.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPrice:
              cardPaymentResponseThird.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPeriodDays:
              retryResponseSecond.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseSecond.data.subscription.subscription_id,
            discountApplied:
              retryResponseSecond.data.subscription.discount_applied,
            paymentMethod,
            currency,
            // isCancelOfferApplied,
          })
          dispatch(getUserStatusAction(uuid))
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          email,
          paymentResponse: cardPaymentResponseThird.error,
          paymentMethod,
          productId,
          price: currentPrice,
          isTrialActive: !!trialPeriodPrice,
          // isCancelOfferApplied,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseThird)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseThird.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseThird = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseThird.error as StripeError,
        })

        if (!retryResponseThird.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseThird.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            createSubscriptionResponse.data.subscription.client_secret,
          ),
        )
        dispatch(
          setSubscriptionIdAction(
            createSubscriptionResponse.data.subscription.subscription_id,
          ),
        )

        const cardPaymentResponseFourth = await stripe.confirmCardPayment(
          retryResponseThird.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseFourth?.paymentIntent &&
          !cardPaymentResponseFourth?.error
        ) {
          logSuccessfulPayment({
            email,
            productId,
            uuid,
            price:
              cardPaymentResponseFourth.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPrice:
              cardPaymentResponseFourth.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPeriodDays:
              retryResponseThird.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseThird.data.subscription.subscription_id,
            discountApplied:
              retryResponseThird.data.subscription.discount_applied,
            paymentMethod,
            currency,
            // isCancelOfferApplied,
          })
          dispatch(getUserStatusAction(uuid))
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          email,
          productId,
          price: currentPrice,
          isTrialActive: !!trialPeriodPrice,
          paymentResponse: cardPaymentResponseFourth.error,
          paymentMethod,
          // isCancelOfferApplied,
        })

        dispatch(
          setErrorAction(
            getErrorActionPayload(cardPaymentResponseFourth.error),
          ),
        )

        // Needed for reset invoice on BE
        await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseFourth.error as StripeError,
        })

        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const { paymentIntent } = cardPaymentResponseFirst

      if (paymentIntent.status === 'requires_payment_method') {
        eventLogger.logPurchaseFailed({
          email,
          productId,
          priceDetails: {
            price: currentPrice,
            trial: !!trialPeriodPrice,
          },
          error: {
            type: 'requires_payment_method',
          },
          paymentMethod,
          // isCancelOfferApplied,
        })
        dispatch(setErrorAction('Payment failed'))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const threeDSesureURL = paymentIntent.next_action?.redirect_to_url?.url

      if (paymentIntent.status === 'requires_action' && threeDSesureURL) {
        dispatch(set3DSecureIframeUrlAction(threeDSesureURL))
        dispatch(stopFetching(PURCHASE))
        return
      }

      logSuccessfulPayment({
        email,
        productId,
        price: currentPrice,
        trialPrice: trialPeriodPrice,
        trialPeriodDays:
          createSubscriptionResponse.data.subscription.trial_period_days,
        subscriptionId:
          createSubscriptionResponse.data.subscription.subscription_id,
        uuid,
        paymentMethod,
        currency,
        // isCancelOfferApplied,
      })
      dispatch(getUserStatusAction(uuid))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('success')
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
    }
  }

export const check3DSecure =
  (stripe: Stripe): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const priceId = selectSubscriptionPriceId(state)
    const trialPriceId = selectSubscriptionTrialPriceId(state)
    const currentPrice = selectSubscriptionCurrentPrice(state)
    const trialPeriodPrice = selectSubscriptionTrialPeriodPrice(state)
    const clientSecret = selectPaymentClientSecret(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const subscriptionId = selectSubscriptionId(state)
    const uuid = selectUUID(state)
    const email = selectEmail(state)
    const currency = selectCurrency(state)

    if (!priceId || !currentPrice) {
      console.error('Error: no subscriptions plan selected')
      return
    }

    if (!clientSecret) {
      console.error('Error: client secret is needed')
      return
    }

    dispatch(startFetching(CHECK_3D_SECURE))

    const productId = getProductId({
      priceId,
      trialPriceId,
      trialPeriodDays,
    })

    const response = await stripe.retrievePaymentIntent(clientSecret)

    if (response.paymentIntent?.status === 'succeeded') {
      logSuccessfulPayment({
        email,
        price: currentPrice,
        productId,
        trialPrice: trialPeriodPrice,
        trialPeriodDays,
        subscriptionId,
        uuid,
        currency,
        // isCancelOfferApplied,
      })
      dispatch(getUserStatusAction(uuid))
      dispatch(reset3DSecureIframeUrlAction())
      return
    }

    if (response.paymentIntent?.status === 'requires_payment_method') {
      eventLogger.logPurchaseFailed({
        email,
        productId,
        priceDetails: {
          price: currentPrice,
          trial: !!trialPeriodPrice,
        },
        error: {
          type: 'requires_payment_method',
        },
        // isCancelOfferApplied,
      })
      dispatch(reset3DSecureIframeUrlAction())
      dispatch(setErrorAction('Payment failed'))
      dispatch(stopFetching(CHECK_3D_SECURE))
      return
    }

    if (response.error) {
      const {
        error: { type, code, message },
      } = response

      eventLogger.logPurchaseFailed({
        email,
        productId,
        priceDetails: {
          price: currentPrice,
          trial: !!trialPeriodPrice,
        },
        error: {
          type,
          code,
          description: message,
        },
        // isCancelOfferApplied,
      })
      dispatch(reset3DSecureIframeUrlAction())
      dispatch(setErrorAction(message || type))
      dispatch(stopFetching(CHECK_3D_SECURE))
      return
    }

    dispatch(reset3DSecureIframeUrlAction())
    dispatch(setErrorAction('Error: unhandled checking 3D Secure error'))
    dispatch(stopFetching(CHECK_3D_SECURE))
  }
