import React, { useMemo } from 'react'
import {
  selectSubscriptionCurrentPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
  selectSubscriptionTrialPriceId,
} from 'modules/payment/redux/selects'
import { useSelector } from 'react-redux'
import { StyledTotalAmount as S } from './TotalAmount.styles'

export const TotalAmount: React.FC = () => {
  const trialPriceId = useSelector(selectSubscriptionTrialPriceId)
  const trialCurrentPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const selectedPrice = useSelector(selectSubscriptionCurrentPrice)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)

  const subscriptionDescription = useMemo(
    () =>
      trialPriceId
        ? `per ${trialPeriodDays}-day trial`
        : `per ${periodQuantity}-${periodName} plan`,

    [trialPriceId, trialPeriodDays, periodQuantity, periodName],
  )

  return (
    <S.Container>
      <S.TitleContainer>Total:</S.TitleContainer>
      <div>
        <S.PriceContainer>
          ${trialCurrentPrice || selectedPrice}{' '}
        </S.PriceContainer>
        <S.SubscriptionDescription>
          {subscriptionDescription}
        </S.SubscriptionDescription>
      </div>
    </S.Container>
  )
}
