import emailImg from 'assets/images/email.webp'
import lockImg from 'assets/images/sprite/lock.svg'
import { Input } from 'components/Input'
import { PoliciesGroup } from 'components/PoliciesGroup'
import { TDynamicPageComponent } from 'models/common.model'
import { IEmailPageProps } from 'modules/email/types'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { PageId } from 'root-constants/pages'
import { selectIsFetching } from 'root-redux/selects/common'
import { EmailWrapper } from '../EmailWrapper'
import { getFilteredEmailDomens } from '../helpers'
import { StyledEmailVariant1 as S } from './EmailVariant1.styles'

const EmailVariant1Component: React.FC<IEmailPageProps> = ({
  email,
  onChange,
  onSubmit,
}) => {
  const isFetching = useSelector(selectIsFetching)
  const [emailHasFocus, setEmailHasFocus] = useState(false)
  const [isAutoCompleteShown, setIsAutoCompleteShown] = useState(false)
  const [isVirtualKeypadActive, setIsVirtualKeypadActive] = useState(false)

  const isButtonDisabled = useMemo(
    () => !email.value || !email.isValid || isFetching,
    [email.isValid, email.value, isFetching],
  )
  const filteredEmailDomens = useMemo(
    () => getFilteredEmailDomens(email.value),
    [email.value],
  )

  useEffect(() => {
    filteredEmailDomens.length && emailHasFocus
      ? setIsAutoCompleteShown(true)
      : setIsAutoCompleteShown(false)
  }, [email.isValid, email.value, emailHasFocus, filteredEmailDomens.length])

  return (
    <S.Wrapper>
      <S.Content>
        {!isVirtualKeypadActive && (
          <>
            <S.TitleImg src={emailImg} height="196" alt="letter" />
            <S.Title>What is your email?</S.Title>
          </>
        )}
        <S.InputWrapper>
          <Input
            value={email.value}
            isValid={email.isValid}
            validationText={email.validationText}
            placeholder="Your e-mail"
            name="email"
            type="email"
            autoComplete="off"
            onChange={(e) => {
              onChange(e.target.value)
            }}
            onFocus={() => {
              setIsVirtualKeypadActive(true)
              setEmailHasFocus(true)
              setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }, 100)
            }}
            onBlur={() => {
              setTimeout(() => setIsVirtualKeypadActive(false))
            }}
          />
          {isAutoCompleteShown && (
            <S.AutoCompleteWrapper>
              {filteredEmailDomens.map((value) => (
                <S.AutoCompleteButton
                  type="button"
                  key={value}
                  value={value}
                  onClick={(e) => {
                    onChange((e.currentTarget as HTMLButtonElement).value)
                    setEmailHasFocus(false)
                  }}
                >
                  <S.AutoCompleteButtonTextWrapper>
                    {value}
                  </S.AutoCompleteButtonTextWrapper>
                </S.AutoCompleteButton>
              ))}
            </S.AutoCompleteWrapper>
          )}
        </S.InputWrapper>
        <S.Note>
          <S.LockImg svg={lockImg} style={{ fill: '#7B8898' }} />
          <S.Description>
            We respect your privacy and will never sell or share your email
            address.
          </S.Description>
        </S.Note>
        <S.Footer
          data-is-virtual-keypad-active={isVirtualKeypadActive}
          data-is-auto-complete-shown={isAutoCompleteShown}
        >
          <S.Button onClick={onSubmit} disabled={isButtonDisabled}>
            Continue
          </S.Button>
          <PoliciesGroup />
        </S.Footer>
      </S.Content>
    </S.Wrapper>
  )
}

const Component: TDynamicPageComponent = () => (
  <EmailWrapper>
    {(props) => <EmailVariant1Component {...props} />}
  </EmailWrapper>
)

Component.hasHeader = true
Component.id = PageId.EMAIL_1

export const EmailVariant1 = Component
