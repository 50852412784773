import { setSelectedSubscriptionAction } from 'modules/payment/redux/actions'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { selectCurrentVariantSubscriptions } from 'root-redux/selects/common'

export const useSetDefaultsubscription = (): void => {
  const dispatch = useDispatch()
  const subscriptions = useSelector(
    selectCurrentVariantSubscriptions,
    shallowEqual,
  )

  useEffect(() => {
    const subscription =
      subscriptions.find(({ isDefault }) => !!isDefault) || subscriptions[0]
    dispatch(setSelectedSubscriptionAction(subscription))
  }, [dispatch, subscriptions])
}
