import React, { useRef } from 'react'
import { useTimerForTarget } from '../hooks/useTimerForTarget'
import { StyledTimer2 as S } from './Timer2.styles'

const TEN_MINUTES_IN_SECONDS = 600

export const Timer2: React.FC = () => {
  const timerElementRef = useRef<HTMLSpanElement>(null)

  useTimerForTarget(timerElementRef, TEN_MINUTES_IN_SECONDS)

  return (
    <S.TimerContainer>
      <span>Reserved for</span>
      <span ref={timerElementRef} />
    </S.TimerContainer>
  )
}
