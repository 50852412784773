import { FlowContext } from 'components/FlowContext'
import { Modal } from 'components/Modal'
import { useEmailInputField } from 'hooks/useEmailInputField'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetErrorAction } from 'root-redux/actions/common'
import { sendUserInfoAction } from 'root-redux/actions/user'
import { selectError } from 'root-redux/selects/common'
import { eventLogger } from 'services/eventLogger.service'
import { IEmailPageProps } from './types'

type TProps = {
  children: React.ReactNode | React.FC<IEmailPageProps>
}

export const EmailWrapper: React.FC<TProps> = ({ children }) => {
  const dispatch = useDispatch()
  const error = useSelector(selectError)
  const { onNextStep } = useContext(FlowContext)

  const [email, , validateEmail] = useEmailInputField()
  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  useEffect(() => {
    eventLogger.logEmailPageShown()
  }, [])

  const handleSubmit = useCallback(() => {
    if (!email.isValid) return

    dispatch(
      sendUserInfoAction({
        email: email.value.toLowerCase(),
        successCallback: onNextStep,
        unsuccessCallback: () => setIsErrorModalShown(true),
      }),
    )
  }, [dispatch, email.isValid, email.value, onNextStep])

  const childrenWithProps = useMemo(
    () =>
      typeof children === 'function'
        ? children({
            onSubmit: handleSubmit,
            onChange: (value) => validateEmail(value),
            email,
          })
        : children,
    [children, handleSubmit, email, validateEmail],
  )

  return (
    <>
      {childrenWithProps}
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
      >
        {error}
      </Modal>
    </>
  )
}
