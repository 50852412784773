import { Option, OptionType } from 'components/Option'
import { FlowContext } from 'components/FlowContext'
import { PoliciesGroup } from 'components/PoliciesGroup'
import { useURLSearchData } from 'hooks/useURLSearchData'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { GOALS_PARAM_KEY, UrlDataGoals } from 'root-constants/urlData'
import { setAnswersAction } from 'root-redux/actions/common'
import { Button } from 'components/Button'
import { StyledIntroVariant2 as S } from './IntroVariant2.styles'

const PAGE_NAME = 'First question'
const QUESTION = 'What is your relationship to the person you want to track?'

const OPTIONS_VALUES = {
  partner: 'partner',
  kid: 'kid',
  friend: 'friend',
  other: 'other',
}

const OPTION_VALUE_TO_GOAL_MAP = {
  [OPTIONS_VALUES.partner]: UrlDataGoals.PARTNER,
  [OPTIONS_VALUES.kid]: UrlDataGoals.KID,
  [OPTIONS_VALUES.friend]: UrlDataGoals.FRIEND,
  [OPTIONS_VALUES.other]: UrlDataGoals.FRIEND,
}

export const IntroVariant2 = () => {
  const { onNextStep, currentPageId } = useContext(FlowContext)
  const dispatch = useDispatch()
  const { setDataToURL, removeDataFromUrl } = useURLSearchData()

  const [answers, setAnswers] = useState<string[]>([])

  const urlDataGoals = useMemo(() => {
    const goals = answers.map((answer) => OPTION_VALUE_TO_GOAL_MAP[answer])
    const uniqGoals = new Set(goals)
    return [...uniqGoals]
  }, [answers])

  useEffect(() => {
    removeDataFromUrl([GOALS_PARAM_KEY])
  }, [removeDataFromUrl])

  const handleContinue = useCallback(() => {
    dispatch(
      setAnswersAction({
        question: QUESTION,
        answers,
        pageId: currentPageId,
        pageName: PAGE_NAME,
      }),
    )
    setDataToURL(GOALS_PARAM_KEY, urlDataGoals)
    onNextStep()
  }, [dispatch, answers, currentPageId, setDataToURL, urlDataGoals, onNextStep])

  const optionProps = useMemo(
    () => ({
      name: currentPageId,
      type: OptionType.CHECKBOX,
      onChange: (value, isChecked) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [answers, currentPageId],
  )
  return (
    <S.Wrapper>
      <S.Title>
        Keep your loved ones <br />
        close, even when apart
      </S.Title>
      <S.FixedContainer>
        <S.Column>
          <S.Question>{QUESTION}</S.Question>
          <S.Note>You can select more than one option</S.Note>
          <S.OptionsContainer>
            <Option {...optionProps} value={OPTIONS_VALUES.partner}>
              <S.QuestionButton>My partner</S.QuestionButton>
            </Option>
            <Option {...optionProps} value={OPTIONS_VALUES.kid}>
              <S.QuestionButton>My kid</S.QuestionButton>
            </Option>
            <Option {...optionProps} value={OPTIONS_VALUES.friend}>
              <S.QuestionButton>My friend</S.QuestionButton>
            </Option>
            <Option {...optionProps} value={OPTIONS_VALUES.other}>
              <S.QuestionButton>Other</S.QuestionButton>
            </Option>
          </S.OptionsContainer>
          <Button
            onClick={handleContinue}
            style={{ marginBottom: '17px' }}
            disabled={!answers.length}
          >
            Continue
          </Button>
          <PoliciesGroup />
        </S.Column>
      </S.FixedContainer>
    </S.Wrapper>
  )
}
