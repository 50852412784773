import { OptionType } from 'components/Option'
import { ISubscription } from 'models/variant.model'
import { selectSubscription } from 'modules/payment/redux/selects'
import { ISelectPlanItemProps } from 'modules/subscriptions/types'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentVariantSubscriptions } from 'root-redux/selects/common'
import { StyledSelectPlanVariant1 as S } from './SelectPlanBlockVariant1.styles'

type TProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onSelect'> & {
  onSelect: (subscription?: ISubscription) => void
  SelectPlanItem: React.FC<ISelectPlanItemProps>
}

export const SelectPlanBlockVariant1 = React.forwardRef<HTMLDivElement, TProps>(
  ({ onSelect, SelectPlanItem, ...props }, ref) => {
    const subscriptions = useSelector(selectCurrentVariantSubscriptions)
    const selectedSubscription = useSelector(selectSubscription)

    const isSubscriptionSelected = useCallback(
      (subscription) => selectedSubscription?.priceId === subscription.priceId,
      [selectedSubscription?.priceId],
    )

    const handleChange = useCallback(
      (value) => {
        const checkedSubscription = subscriptions.find(
          (subscription) => subscription.priceId === value,
        )
        onSelect(checkedSubscription)
      },
      [onSelect, subscriptions],
    )

    return (
      <S.Wrapper ref={ref} {...props}>
        {subscriptions.map((subscription) => (
          <S.Option
            type={OptionType.RADIO}
            name="subscription"
            value={subscription.priceId}
            key={subscription.priceId}
            onChange={handleChange}
          >
            <SelectPlanItem
              subscription={subscription}
              isSelected={isSubscriptionSelected(subscription)}
            />
          </S.Option>
        ))}
      </S.Wrapper>
    )
  },
)
