import { Option, OptionType } from 'components/Option'
import { FlowContext } from 'components/FlowContext'
import { PoliciesGroup } from 'components/PoliciesGroup'
import React, { useContext, useMemo, useState } from 'react'
import { Button } from 'components/Button'
import { TDynamicPageComponent } from 'models/common.model'
import { PageId } from 'root-constants/pages'
import { StyledIntroVariant3 as S } from './IntroVariant3.styles'

const QUESTION = 'Who do you care about?'

const OPTIONS_VALUES = {
  partner: 'partner',
  kid: 'kid',
  other: 'other',
}

export const Component: TDynamicPageComponent = () => {
  const { onNextStep, currentPageId } = useContext(FlowContext)

  const [answers, setAnswers] = useState<string[]>([])

  const optionProps = useMemo(
    () => ({
      name: currentPageId,
      type: OptionType.CHECKBOX,
      onChange: (value, isChecked) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [answers, currentPageId],
  )
  return (
    <S.Wrapper>
      <S.Title>
        Stay connected to your
        <br />
        closed ones, no matter
        <br />
        the distance
      </S.Title>
      <S.FixedContainer>
        <S.Column>
          <S.Question>{QUESTION}</S.Question>
          <S.Note>You can select more than one option</S.Note>
          <S.OptionsContainer>
            <Option {...optionProps} value={OPTIONS_VALUES.partner}>
              <S.QuestionButton>My wife/husband</S.QuestionButton>
            </Option>
            <Option {...optionProps} value={OPTIONS_VALUES.kid}>
              <S.QuestionButton>My kid</S.QuestionButton>
            </Option>
            <Option {...optionProps} value={OPTIONS_VALUES.other}>
              <S.QuestionButton>Other</S.QuestionButton>
            </Option>
          </S.OptionsContainer>
          <Button
            onClick={onNextStep}
            style={{ marginBottom: '17px' }}
            disabled={!answers.length}
          >
            Continue
          </Button>
          <PoliciesGroup />
        </S.Column>
      </S.FixedContainer>
    </S.Wrapper>
  )
}

Component.id = PageId.INTRO_3
Component.hasHeader = true

export const IntroVariant3 = Component
