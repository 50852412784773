import React from 'react'
import { StyledBenefits as S } from './Benefits.styles'

export const BenefitsVariant2: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Title>Famio benefits</S.Title>

      <ul>
        <S.ListItem>
          <b>Get battery alerts</b>
        </S.ListItem>
        <S.ListItem>
          <b>Get real-time notifications with SOS alerts</b>
        </S.ListItem>
        <S.ListItem>
          <b>Be sure your family is in safety</b>
        </S.ListItem>
        <S.ListItem>
          <b>A stress-free parenting</b>
        </S.ListItem>
      </ul>
    </S.Wrapper>
  )
}
