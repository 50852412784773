import errorIcon from 'assets/images/error.svg'
import { Spinner } from 'components/Spinner'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetErrorAction } from 'root-redux/actions/common'
import { selectActionList, selectError } from 'root-redux/selects/common'
import { DEFAULT_ERROR_DESCRIPTION } from '../constants'
import { CHECK_3D_SECURE, PURCHASE } from '../redux/actions'
import { StyledPaymentStatus as S } from '../styles'
import { PaymentSuccess } from './PaymentSuccess'

export const StripePaymentProcessing: React.FC = () => {
  const dispatch = useDispatch()
  const fetchingActionsList = useSelector(selectActionList)
  const requestError = useSelector(selectError)
  const [isShown, setIsShown] = useState(false)

  const isPurchaseInProcess = useMemo(
    () =>
      fetchingActionsList.includes(PURCHASE) ||
      fetchingActionsList.includes(CHECK_3D_SECURE),
    [fetchingActionsList],
  )

  useEffect(() => {
    if (isPurchaseInProcess) {
      setIsShown(true)
    }
  }, [isPurchaseInProcess])

  const handleResetError = useCallback(() => {
    setIsShown(false)
    dispatch(resetErrorAction())
  }, [dispatch])

  return (
    <>
      {isPurchaseInProcess && (
        <S.Wrapper>
          <S.Container>
            <Spinner isFullScreen={false} />
            <S.Title>Processing…</S.Title>
            <S.Subtitle>
              Please wait, we are processing your purchase
            </S.Subtitle>
          </S.Container>
        </S.Wrapper>
      )}
      {!isPurchaseInProcess && !requestError && isShown && <PaymentSuccess />}
      {!isPurchaseInProcess && requestError && isShown && (
        <S.Wrapper>
          <S.Container>
            <img src={errorIcon} width="108" alt="Error" />
            <S.Title>
              There was an error processing your payment information.
            </S.Title>
            <S.Subtitle>
              {requestError?.description ||
                requestError ||
                DEFAULT_ERROR_DESCRIPTION}
            </S.Subtitle>
            <S.Button onClick={handleResetError}>Try Again</S.Button>
          </S.Container>
        </S.Wrapper>
      )}
    </>
  )
}
