import { useMachine } from '@xstate/react'
import { navigate } from 'browser-history'
import { getPathFromPageId } from 'helpers/getPathFromPageId'
import { getUserFlowBaseConfig } from 'helpers/getXStateConfig'
import { useEventLoggerInitialization } from 'hooks/useEventLoggerInitialization'
import { useFacebookPixelInitialization } from 'hooks/useFacebookPixelInitialization'
import { useLogAbSegmentName } from 'hooks/useLogAbSegmentName'
import { useStartSession } from 'hooks/useStartSession'
import { IUserStatus } from 'models/user.model'
import { IVariant } from 'models/variant.model'
import { Account } from 'modules/account/Account'
import React, { useCallback, useEffect, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { PagePath } from 'root-constants/common'
import {
  selectCurrentVariant,
  selectCurrentVariantSteps,
} from 'root-redux/selects/common'
import { selectUserStatus, selectUUID } from 'root-redux/selects/user'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'
import { createUserFlowStateMachine } from 'services/userFlowState.service'
import { DownloadApp } from 'pages/download-app/DownloadApp'
import { ID_TO_PAGE_MAP, PageId } from '../constants/pages'
import { MainWrapper } from './MainWrapper'

export const FlowRouter: React.FC = () => {
  const { pathname, search } = useLocation()
  const uuid = useSelector(selectUUID)
  const steps = useSelector(selectCurrentVariantSteps, shallowEqual)
  const userStatus = useSelector(selectUserStatus, shallowEqual) as IUserStatus
  const variant = useSelector(selectCurrentVariant, shallowEqual) as IVariant

  const { cohort, facebookPixelId } = variant

  const firstPageId = useMemo(() => steps[0].id, [steps])

  useFacebookPixelInitialization(facebookPixelId)
  useEventLoggerInitialization({ cohort })
  useStartSession()
  useLogAbSegmentName()

  const [current, send] = useMachine(() =>
    createUserFlowStateMachine({ userStatus }, getUserFlowBaseConfig(steps)),
  )

  const currentPageId = useMemo(() => current.value as PageId, [current.value])
  const currentPageIndex = useMemo(
    () => steps.findIndex((page) => page.id === currentPageId),
    [steps, currentPageId],
  )

  const goToNextStep = useCallback(() => {
    send('NEXT_FLOW_STEP')
  }, [send])

  const goToPreviousStep = useCallback(() => {
    send('PREVIOUS_FLOW_STEP')
  }, [send])

  const goToCancelOfferSubscriptionsPage = useCallback(() => {
    send('CANCEL_OFFER_SUBSCRIPTIONS_PAGE')
  }, [send])

  const skipSteps = useCallback(() => {
    send('SKIP_FLOW_STEPS')
  }, [send])

  const goToDataQuestionPage = useCallback(() => {
    send('DATA_QUESTION_PAGE')
  }, [send])

  const flowContextValue = useMemo(
    () => ({
      onNextStep: goToNextStep,
      onPreviousStep: goToPreviousStep,
      onSkipSteps: skipSteps,
      onCancelOfferSubscriptions: goToCancelOfferSubscriptionsPage,
      onDataQuestionPage: goToDataQuestionPage,
      currentPageId,
    }),
    [
      currentPageId,
      goToCancelOfferSubscriptionsPage,
      goToDataQuestionPage,
      goToNextStep,
      goToPreviousStep,
      skipSteps,
    ],
  )

  useEffect(() => {
    const targetPath = getPathFromPageId({
      pageId: currentPageId,
      cohort,
      uuid,
      currentSearch: search,
    })

    const isPathNew = targetPath !== `${pathname}${search}`
    isPathNew && navigate(targetPath)
  }, [cohort, current.changed, currentPageId, pathname, search, uuid])

  useEffect(() => {
    if (currentPageIndex === 1) {
      window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
      googleAnalyticsLogger.logFirstPageCompleted()
    }
  }, [currentPageIndex, uuid])

  useEffect(() => {
    const currentOnboardingVariant = cohort ? `/${cohort}` : ``

    if (window.location.href.includes(PagePath.LOCALHOST)) return

    const currentLocation = `${pathname}${currentOnboardingVariant}`
    googleAnalyticsLogger.logPageView(currentLocation)
  }, [cohort, pathname])

  return (
    <Routes>
      <Route path="/" element={<Navigate to={`/${firstPageId}`} replace />} />
      <Route
        path="/*"
        element={
          <MainWrapper
            firstPageId={firstPageId}
            flowContextValue={flowContextValue}
          />
        }
      >
        {steps.map(({ id: pageId }) => {
          const CurrentPage =
            ID_TO_PAGE_MAP[pageId] || ID_TO_PAGE_MAP[firstPageId]

          return <Route path={pageId} key={pageId} element={<CurrentPage />} />
        })}
        <Route path="account" element={<Account />} />
        <Route path="download" element={<DownloadApp />} />
      </Route>
    </Routes>
  )
}
