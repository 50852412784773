import styled from 'styled-components'
import checkbox from 'assets/images/checkbox-blue.svg'

export const StyledReviewsSlider = {
  Wrapper: styled.div`
    padding-top: 33px;

    .swiper-wrapper {
      display: flex;
    }

    .swiper-slide {
      position: relative;
      width: 265px;
    }
  `,
  Card: styled.div`
    position: relative;
    padding: 40px 10px 24px;
    height: 228px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 9px;
    border: 4px solid #eed0e4;
  `,
  Avatar: styled.img`
    position: absolute;
    top: -33px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
  `,
  CardTitle: styled.h3`
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  `,
  Recommendation: styled.div`
    padding-left: 20px;
    position: relative;
    display: flex;

    ::before {
      content: url(${checkbox});
      width: 14px;
      height: 14px;
      display: block;
      position: absolute;
      left: 0;
      top: -2px;
    }

    ::after {
      content: 'Recommends Famio';
      font-size: 12px;
      line-height: 16px;
    }
  `,
  CardText: styled.p`
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
  `,
}
