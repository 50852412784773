import husbandCallImg from 'assets/images/subscription-husband-call.jpg'
import wifeCallImg from 'assets/images/subscription-wife-call.jpg'
import femaleImg from 'assets/images/subscriptions-gender-female.webp'
import maleImg from 'assets/images/subscriptions-gender-male.webp'
import { ReviewsSlider } from 'components/ReviewSlider'
import { getProductId } from 'helpers/getProductId'
import { useAlternativeOptimizeVariant } from 'hooks/useAlternativeOptimizeVariant'
import { useURLSearchData } from 'hooks/useURLSearchData'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { PageId } from 'root-constants/pages'
import { GENDER_PARAM_KEY, UrlDataGender } from 'root-constants/urlData'
import { selectCurrentVariantSubscriptions } from 'root-redux/selects/common'
import { eventLogger, ScreenName } from 'services/eventLogger.service'
import { Guarantee } from '../components/Guarantee'
import { Security } from '../components/Security'
import { SubheaderWithTimer } from '../components/SubheaderWithTimer'
import { SubscriptionDescription } from '../components/SubscriptionDescription'
import { Timer } from '../components/Timer'
import { PlanItemVariant1 } from '../components/variant1/PlanItemVariant1'
import { SelectPlanBlockVariant1 } from '../components/variant1/SelectPlanBlockVariant1'
import { SubscriptionsWrapper } from '../SubscriptionsWrapper'
import { ISubscriptionsPageProps } from '../types'
import { StyledSubscriptions as S } from './Subscriptions.styles'

const SubscriptionsPageVariant1Component: React.FC<ISubscriptionsPageProps> = ({
  onButtonClick,
  onSubscriptionSelect,
}) => {
  const scrollToElement = useRef<HTMLDivElement>(null)
  const timerTriggerElementRef = useRef(null)
  const currentVariantSubscriptions = useSelector(
    selectCurrentVariantSubscriptions,
  )
  const { getDataFromURL } = useURLSearchData()
  const hasAlternativeOptimizeVariant = useAlternativeOptimizeVariant(4)

  const gender = useMemo(
    () => getDataFromURL(GENDER_PARAM_KEY),
    [getDataFromURL],
  )

  useEffect(() => {
    eventLogger.logSalePageShown({
      productIds: currentVariantSubscriptions.map(
        ({ priceId, trialPriceId, trialPeriodDays }) =>
          getProductId({
            priceId,
            trialPriceId,
            trialPeriodDays,
          }),
      ),
      pageName: ScreenName.ONBOARDING,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTimerButtonClick = useCallback(() => {
    scrollToElement.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }, [])

  return (
    <>
      <SubheaderWithTimer
        elemForComparisonRef={timerTriggerElementRef}
        onClick={handleTimerButtonClick}
      />
      <S.Wrapper>
        <S.Column style={{ paddingTop: 40, marginBottom: 40 }}>
          {hasAlternativeOptimizeVariant ? (
            <S.Title style={{ marginBottom: 12 }}>
              {gender === UrlDataGender.MALE
                ? 'Make Sure She Still Chooses You'
                : `Make Sure You're Still The One`}
            </S.Title>
          ) : (
            <S.Title style={{ marginBottom: 12 }}>
              {gender === UrlDataGender.MALE
                ? 'Keep an eye on your loved one to make sure she is faithful'
                : 'Make Sure Your Partner Stays Loyal'}
            </S.Title>
          )}

          <S.Text style={{ marginBottom: 28 }}>
            Monitor Your Partner with Famio
          </S.Text>
          <Timer />
        </S.Column>
        {hasAlternativeOptimizeVariant ? (
          <img
            ref={timerTriggerElementRef}
            src={gender === UrlDataGender.MALE ? maleImg : femaleImg}
            alt="couple"
          />
        ) : (
          <img
            ref={timerTriggerElementRef}
            src={gender === UrlDataGender.MALE ? husbandCallImg : wifeCallImg}
            alt="calling partner"
          />
        )}
        <S.Column data-has-bg>
          <S.Subtitle style={{ marginBottom: 16, marginTop: 56 }}>
            {gender === UrlDataGender.MALE
              ? 'Famio benefits'
              : 'With Famio you can:'}
          </S.Subtitle>
          <ul style={{ marginBottom: 45 }}>
            <li>
              <S.ContentItem>
                {gender === UrlDataGender.MALE ? (
                  <strong>24/7 Monitoring</strong>
                ) : (
                  <>
                    <strong>Track</strong> your partner’s movement history
                  </>
                )}
              </S.ContentItem>
            </li>
            <li>
              <S.ContentItem>
                {gender === UrlDataGender.MALE ? (
                  <strong>Arrives or departs alerts</strong>
                ) : (
                  <>
                    <strong>Get real-time alerts</strong> of suspicious activity
                  </>
                )}
              </S.ContentItem>
            </li>
            <li>
              <S.ContentItem>
                Instantly <strong>detect SOS notifications</strong>
              </S.ContentItem>
            </li>
            <li>
              <S.ContentItem>
                {gender === UrlDataGender.MALE ? (
                  <strong>Driving mode</strong>
                ) : (
                  <>
                    <strong>Reduce</strong> anxiety and fear of infidelity
                  </>
                )}
              </S.ContentItem>
            </li>
            {gender === UrlDataGender.MALE && (
              <li>
                <S.ContentItem>
                  <strong>No one can make you look like a fool</strong>
                </S.ContentItem>
              </li>
            )}
          </ul>
          <S.Subtitle style={{ margin: '0 -1px 40px' }}>
            {hasAlternativeOptimizeVariant
              ? 'Invest in Your Relationships'
              : 'Invest in Your Peace'}
          </S.Subtitle>
          <SelectPlanBlockVariant1
            ref={scrollToElement}
            onSelect={onSubscriptionSelect}
            SelectPlanItem={PlanItemVariant1}
            style={{ marginBottom: 20 }}
          />
          <S.Button onClick={onButtonClick} style={{ marginBottom: 12 }}>
            Start My Plan
          </S.Button>
          <SubscriptionDescription style={{ marginBottom: 32 }} />
          <Guarantee style={{ marginBottom: 36 }} />
          <ReviewsSlider style={{ marginBottom: 24 }} />
          <S.Button onClick={onButtonClick} style={{ marginBottom: 24 }}>
            Start My Plan
          </S.Button>
          <Security />
        </S.Column>
      </S.Wrapper>
    </>
  )
}

const Component: TDynamicPageComponent = () => (
  <SubscriptionsWrapper>
    {(props: ISubscriptionsPageProps) => (
      <SubscriptionsPageVariant1Component {...props} />
    )}
  </SubscriptionsWrapper>
)

Component.hasHeader = true
Component.id = PageId.SUBSCRIPTIONS_1

export const SubscriptionsVariant1 = Component
