import { Option, OptionType } from 'components/Option'
import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'root-constants/pages'
import { setAnswersAction } from 'root-redux/actions/common'
import { QuestionButton } from 'components/QuestionButton'
import { StyledOption } from 'components/Option.styles'
import { FREQUENCY_OPTIONS_VALUES } from 'root-constants/common'
import { StyledStayOut as S } from './StayOut.styles'

const PAGE_NAME = 'Stay out late'
const QUESTION =
  'Does your partner stay out late without telling you where they are going?'

const Component: TDynamicPageComponent = () => {
  const { onNextStep, currentPageId } = useContext(FlowContext)
  const dispatch = useDispatch()

  const handleContinue = useCallback(
    (answer) => {
      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: answer,
          pageId: currentPageId,
          pageName: PAGE_NAME,
        }),
      )
      onNextStep()
    },
    [currentPageId, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      name: currentPageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option {...optionProps} value={FREQUENCY_OPTIONS_VALUES.quiteOften}>
            <QuestionButton>
              {FREQUENCY_OPTIONS_VALUES.quiteOften}
            </QuestionButton>
          </Option>
          <Option {...optionProps} value={FREQUENCY_OPTIONS_VALUES.sometimes}>
            <QuestionButton>
              {FREQUENCY_OPTIONS_VALUES.sometimes}
            </QuestionButton>
          </Option>
          <Option {...optionProps} value={FREQUENCY_OPTIONS_VALUES.rarely}>
            <QuestionButton>{FREQUENCY_OPTIONS_VALUES.rarely}</QuestionButton>
          </Option>
          <Option {...optionProps} value={FREQUENCY_OPTIONS_VALUES.never}>
            <QuestionButton>{FREQUENCY_OPTIONS_VALUES.never}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}

Component.id = PageId.STAY_OUT_1
Component.hasHeader = true
Component.hasProgressBar = true

export const StayOutVariant1 = Component
