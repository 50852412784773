import { Button } from 'components/Button'
import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'root-constants/pages'
import { setAnswersAction } from 'root-redux/actions/common'
import { StyledSocialProof as S } from './SocialProof.styles'

const PAGE_NAME = 'Social proof 1'

const Component: TDynamicPageComponent = () => {
  const { onNextStep, currentPageId } = useContext(FlowContext)
  const dispatch = useDispatch()

  const handleContinue = useCallback(() => {
    dispatch(
      setAnswersAction({
        question: '',
        answers: '',
        pageId: currentPageId,
        pageName: PAGE_NAME,
      }),
    )
    onNextStep()
  }, [currentPageId, dispatch, onNextStep])

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>Do you know that ...</S.Title>
        <S.Text>
          <strong>54%</strong> of those who have ever been in a monogamous
          relationship say they <strong>have been cheated</strong> on — either
          physically, emotionally, or both*
        </S.Text>
        <S.Note>*according to YouGov survey in 2022</S.Note>
        <Button onClick={handleContinue}>Continue</Button>
      </S.Column>
    </S.Wrapper>
  )
}

Component.id = PageId.SOCIAL_PROOF_1
Component.hasHeader = true

export const SocialProofVariant1 = Component
