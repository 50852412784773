import { useCallback, useEffect, useState } from 'react'
import { ValidationText } from 'root-constants/common'
import { IInputFieldState, TInputFieldHook } from 'models/inputField.model'

export const usePasswordInputField = (
  errorMessage: ValidationText | null,
  resetErrorMessage: () => void,
): TInputFieldHook => {
  const [password, setPassword] = useState<IInputFieldState>({
    value: '',
    isValid: true,
    validationText: ValidationText.SHORT_PASSWORD,
  })

  const validatePassword = useCallback((passwordValue) => {
    if (passwordValue.length < 8 && passwordValue !== '') {
      setPassword((prevState) => ({
        ...prevState,
        value: passwordValue,
        isValid: false,
        validationText: ValidationText.SHORT_PASSWORD,
      }))
      return
    }

    setPassword((prevState) => ({
      ...prevState,
      value: passwordValue,
      isValid: true,
      validationText: '',
    }))
  }, [])

  useEffect(() => {
    if (errorMessage) {
      setPassword((prevState) => ({
        ...prevState,
        isValid: false,
        validationText: errorMessage,
      }))

      resetErrorMessage()
    }
  }, [errorMessage, resetErrorMessage])

  return [password, setPassword, validatePassword]
}
