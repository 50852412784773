import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
  CurrentEnvironment,
  EventLoggerInstanceName,
  TEST_ENV_QUERY_PARAM,
} from 'root-constants/common'
import {
  selectAmplitudeApiKey,
  selectGiaApiKey,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { Amplitude } from 'services/amplitude.service'
import { eventLogger } from 'services/eventLogger.service'
import { UserFlowTracker } from 'services/userFlowTracker.service'

function getGIAAmplitudeOptions() {
  const btoaAuthCredentials = 'cHJveHktdXNlcjozQyk9Q3YncTd5L0A4Pjcp'

  return {
    apiEndpoint:
      getCurrentEnv() === CurrentEnvironment.PROD
        ? 'analytics.gismart.xyz/events'
        : 'analytics.gidev.xyz/events',
    headers: {
      Authorization: `Bearer ${btoaAuthCredentials}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    forceHttps: true,
  }
}

export function useEventLoggerInitialization({
  cohort,
}: {
  cohort: string
}): void {
  const uuid = useSelector(selectUUID)
  const amplitudeApiKey = useSelector(selectAmplitudeApiKey)
  const giaApiKey = useSelector(selectGiaApiKey)
  const { search } = useLocation()

  const URLParams = new URLSearchParams(search)
  const utmTags = Object.fromEntries(URLParams.entries())
  const isTestEnvironment = URLParams.has(TEST_ENV_QUERY_PARAM)
  const isDevEnvironment = getCurrentEnv() === CurrentEnvironment.DEV

  useEffect(() => {
    if (uuid && amplitudeApiKey && giaApiKey) {
      const amplitudeService = new Amplitude({
        apiKey: amplitudeApiKey,
        instanceName: EventLoggerInstanceName.AMPLITUDE,
        userId: uuid,
        utmTags,
        isTestEnvironment,
        isDevEnvironment,
      })

      const giaService = new Amplitude({
        apiKey: giaApiKey,
        instanceName: EventLoggerInstanceName.GIA,
        options: getGIAAmplitudeOptions(),
        userId: uuid,
        utmTags,
        isTestEnvironment,
        isDevEnvironment,
      })

      const userFlowTracker = new UserFlowTracker({
        instanceName: EventLoggerInstanceName.USER_FLOW_TRACKER,
        userId: uuid,
      })

      amplitudeService.configure({ cohort })
      giaService.configure({ cohort })
      userFlowTracker.configure({ cohort })

      eventLogger.init(amplitudeService, giaService, userFlowTracker)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, amplitudeApiKey, giaApiKey])
}
