import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  selectAbSegmentName,
  selectOptimizeExperimentIdByVariantIdMap,
  selectOptimizeExperimentMap,
  selectOptimizeMap,
} from 'root-redux/selects/common'
import { eventLogger } from 'services/eventLogger.service'

export const useLogAbSegmentName = (): void => {
  const optimizeMap = useSelector(selectOptimizeMap)
  const optimizeExperimentMap = useSelector(selectOptimizeExperimentMap)
  const staticAbSegmentName = useSelector(selectAbSegmentName)
  const optimizeExperimentIdByVariantIdMap = useSelector(
    selectOptimizeExperimentIdByVariantIdMap,
  )

  useEffect(() => {
    if (!optimizeExperimentMap || !optimizeExperimentIdByVariantIdMap) {
      eventLogger.logAbSegmentName({
        optimizeVariantId: '',
        optimizeExperimentId: '',
        optimizeSegmentName: staticAbSegmentName,
      })
      return
    }

    Object.entries(optimizeExperimentIdByVariantIdMap).forEach(
      ([optimizeExperimentId, optimizeVariantId]) => {
        eventLogger.logAbSegmentName({
          optimizeVariantId,
          optimizeExperimentId,
          optimizeSegmentName:
            staticAbSegmentName ||
            optimizeMap?.[optimizeExperimentId]?.[optimizeVariantId] ||
            '',
        })
      },
    )
  }, [
    optimizeExperimentMap,
    optimizeExperimentIdByVariantIdMap,
    optimizeMap,
    staticAbSegmentName,
  ])
}
