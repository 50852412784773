import { Option, OptionType } from 'components/Option'
import { FlowContext } from 'components/FlowContext'
import { PoliciesGroup } from 'components/PoliciesGroup'
import { useURLSearchData } from 'hooks/useURLSearchData'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { GENDER_PARAM_KEY } from 'root-constants/urlData'
import { setAnswersAction } from 'root-redux/actions/common'
import { StyledIntro as S } from './Intro.styles'

const PAGE_NAME = 'First question'
const QUESTION = 'Do you know where your partner is right now?'
const OPTIONS_VALUES = {
  yes: 'Yes',
  no: 'No',
  notSure: 'Not sure',
}

export const IntroVariant1 = () => {
  const { onNextStep, currentPageId } = useContext(FlowContext)
  const dispatch = useDispatch()
  const { removeDataFromUrl } = useURLSearchData()

  useEffect(() => {
    removeDataFromUrl([GENDER_PARAM_KEY])
  }, [removeDataFromUrl])

  const handleContinue = useCallback(
    (answer) => {
      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: answer,
          pageId: currentPageId,
          pageName: PAGE_NAME,
        }),
      )
      onNextStep()
    },
    [currentPageId, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      name: currentPageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <S.OptionsContainer>
          <Option {...optionProps} value={OPTIONS_VALUES.yes}>
            <S.QuestionButton>{OPTIONS_VALUES.yes}</S.QuestionButton>
          </Option>
          <Option {...optionProps} value={OPTIONS_VALUES.no}>
            <S.QuestionButton>{OPTIONS_VALUES.no}</S.QuestionButton>
          </Option>
        </S.OptionsContainer>
        <Option {...optionProps} value={OPTIONS_VALUES.notSure}>
          <S.NotSureButton>{OPTIONS_VALUES.notSure}</S.NotSureButton>
        </Option>
        <PoliciesGroup />
      </S.Column>
    </S.Wrapper>
  )
}
