export const easeInOutQuart = (elapsedTime: number): number => {
  if (elapsedTime < 0.5) return 8 * elapsedTime ** 4

  return 1 - 8 * (elapsedTime - 1) ** 4
}

export const easeInOutQuad = (elapsedTime: number): number => {
  if (elapsedTime < 0.5) return 2 * elapsedTime * elapsedTime

  return 1 - (-2 * elapsedTime + 2) ** 2 / 2
}

export const linear = (elapsedTime: number): number => elapsedTime
