import backIcon from 'assets/images/back.svg'
import crossIcon from 'assets/images/cross.svg'
import { BASE_COLUMN_WIDTH, Color } from 'root-constants/common'
import styled, { css } from 'styled-components'

export const basePageStyles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const baseColumnStyles = css`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: ${BASE_COLUMN_WIDTH}px;
`

export const baseTitleStyles = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
`

export const baseInputStyles = css`
  display: block;
  width: 100%;
  height: 56px;
  padding: 17px;
  color: ${Color.DEFAULT_TEXT};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  border-radius: 12px;
  border: 1px solid transparent;
  background-color: #eff5f7;
  outline: none;
  transition: border 0.2s ease-out;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(10, 50, 77, 0.3);
  }
  :-ms-input-placeholder {
    color: rgba(10, 50, 77, 0.3);
  }
`

export const ModalWrapperBase = css`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: scroll;
  padding: 40px 0;
`

export const ModalContainerBase = css`
  width: 288px;
  padding: 24px 24px 32px;
  margin: auto;
  background-color: white;
  border-radius: 16px;
`

export const BottomStickyBase = css`
  position: fixed;
  z-index: 1;
  bottom: 40px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  &[data-is-virtual-keypad-active='true'] {
    position: relative;
    bottom: 0;
  }

  &[data-is-auto-complete-shown='true'] {
    z-index: -1;
  }

  @media (orientation: landscape) {
    position: relative;
    bottom: 0;
  }
`

export const QuestionTitle = styled.h1`
  font-weight: 700;
  font-size: 24px;
  color: ${Color.DEFAULT_TEXT};
  line-height: 33px;
  text-align: center;
`

export const CloseButton = styled.button`
  box-sizing: content-box;
  width: 24px;
  height: 24px;
  display: block;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  background-image: url(${crossIcon});
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`

export const BackButton = styled.button`
  box-sizing: content-box;
  width: 24px;
  height: 24px;
  display: block;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  background-image: url(${backIcon});
  background-size: 34px 34px;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`
