import { Color } from 'root-constants/common'
import styled, { css } from 'styled-components'
import { StyledButtonBase } from '../../components/Button.styles'

const StyledAccountButtonBase = css`
  ${StyledButtonBase};
  color: #fff;
  padding: 0 32px;
  height: 56px;
  line-height: 56px;
  font-size: 15px;
  background-image: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  :not(:last-child) {
    margin-bottom: 16px;
  }

  img {
    position: absolute;
    left: 32px;
  }
`

export const StyledAccountButton = {
  Email: styled.button`
    ${StyledAccountButtonBase};
    background-color: #7bc27e;

    img {
      margin-right: 14px;
    }
  `,
  Google: styled.button`
    ${StyledAccountButtonBase};
    background-color: #eff5f7;
    color: ${Color.DEFAULT_TEXT};
  `,
  Facebook: styled.button`
    ${StyledAccountButtonBase};
    background-color: #395a98;
  `,
  Apple: styled.button`
    ${StyledAccountButtonBase};
    background-color: #041a28;
  `,
}
