import { userApi } from 'api'
import { IAppState, TAppDispatchThunk, IAction } from 'models/store.model'
import { IGetUUIDResponseRaw, IUserStatus } from 'models/user.model'
import { selectUUID } from 'root-redux/selects/user'
import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { getCookie } from 'helpers/getCookie'
import { selectAnswers } from 'root-redux/selects/common'
import { eventLogger } from 'services/eventLogger.service'
import { getUserStatusFromRawUserStatus } from 'helpers/getUserStatusFromRawUserStatus'
import { TAnswers } from 'models/common.model'
import { CUSTOM_TOKEN_LOCAL_STORAGE_KEY } from 'root-constants/common'

const MODULE_NAME = 'USER'

export const SET_UUID = `${MODULE_NAME}/SET_UUID`
export const SET_SECRET = `${MODULE_NAME}/SET_SECRET`
export const GET_STATUS = `${MODULE_NAME}/GET_STATUS`
export const SET_STATUS = `${MODULE_NAME}/SET_STATUS`
const SEND_USER_INFO = `${MODULE_NAME}/SEND_USER_INFO`
const BIND_USER = `${MODULE_NAME}/BIND_USER`

export function setUUIDAction(uuid: string): IAction<string> {
  return {
    type: SET_UUID,
    payload: uuid,
  }
}

export function setUserStatusAction(
  userStatus: IUserStatus,
): IAction<IUserStatus> {
  return {
    type: SET_STATUS,
    payload: userStatus,
  }
}

export function setSecretAction(secret: string): IAction<string> {
  return {
    type: SET_SECRET,
    payload: secret,
  }
}

export const setUpUUIDAction =
  ({
    cohort,
    uuid: uuidFromUrl,
    giaApiKey,
  }: {
    cohort: string
    uuid: string | null
    giaApiKey: string
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuidFromStore = selectUUID(state)
    const uuid = uuidFromUrl || uuidFromStore

    if (uuid) {
      dispatch(setUUIDAction(uuid))
      return
    }

    const response = await userApi.getUUID(cohort, giaApiKey)

    if (response.success) {
      const responseUUID = (response.data as IGetUUIDResponseRaw).value
      dispatch(setUUIDAction(responseUUID))
    } else {
      console.error('error')
    }
  }

export function bindUserAction(
  token: string,
  successCallback: () => void,
): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(BIND_USER))

    const response = await userApi.bindUser({ token, uuid })

    if (response.success && response.data) {
      response.data.custom_token &&
        localStorage.setItem(
          CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
          response.data.custom_token,
        )

      const userStatusResponse = await userApi.getUserStatus(uuid)
      const userStatus = getUserStatusFromRawUserStatus(
        userStatusResponse.data.status,
      )

      dispatch(setUserStatusAction(userStatus))
      eventLogger.logAccountCreated({
        method: userStatus.account.loginMethod,
      })
      successCallback()
    } else {
      eventLogger.logAccountCreationFailed({ error: response?.data?.error })

      if (response.status === 409) {
        const errorMessage = response?.data?.error || ''
        const message = errorMessage.toLowerCase().includes('subscription')
          ? 'Error: You already have a email. Your subscriptions was refunded.'
          : 'Error: You already have an account.'

        dispatch(setErrorAction(message))
        dispatch(stopFetching(BIND_USER))
        return
      }

      dispatch(setErrorAction('Error: Something went wrong.'))
    }

    dispatch(stopFetching(BIND_USER))
  }
}

export function getUserStatusAction(uuid: string): any {
  return async (dispatch: TAppDispatchThunk<any>) => {
    dispatch(startFetching(GET_STATUS))

    const response = await userApi.getUserStatus(uuid)

    if (response.success && response.data) {
      const { status } = response.data

      const userStatus = getUserStatusFromRawUserStatus(status)
      dispatch(setUserStatusAction(userStatus))
    }

    if (!response.success && response.status === 404) {
      const { search } = window.location
      const urlParams = new URLSearchParams(search)
      urlParams.delete('uuid')

      // use window.location.search to reload the page with updated query params
      window.location.search = `?${urlParams}`
    }

    dispatch(stopFetching(GET_STATUS))
  }
}

export const sendUserInfoAction =
  ({
    email,
    successCallback,
    unsuccessCallback,
  }: {
    email: string
    successCallback: () => void
    unsuccessCallback: () => void
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const answers = selectAnswers(state) as TAnswers
    const uuid = selectUUID(state)
    const fbp = getCookie('_fbp')
    const fbc = getCookie('_fbc')

    dispatch(startFetching(SEND_USER_INFO))

    const response = await userApi.sendUserInfo({
      uuid,
      email,
      answers,
      fbp,
      fbc,
    })

    if (!response.success) {
      if (response.status === 409) {
        dispatch(setErrorAction('Error: You already have an account.'))
      } else {
        dispatch(setErrorAction('Error: Something went wrong.'))
      }

      eventLogger.logEmailPageError({ error: JSON.stringify(response.data) })
      unsuccessCallback()
      dispatch(stopFetching(SEND_USER_INFO))
      return
    }

    eventLogger.logEmailPageCompleted({ email })
    window.fbq('track', 'Lead', {}, { eventID: uuid })
    window.ttq.identify({ email })
    window.ttq.track('CompleteRegistration')
    successCallback()
    dispatch(getUserStatusAction(uuid))
    dispatch(stopFetching(SEND_USER_INFO))
  }
