import { Color } from 'root-constants/common'
import styled from 'styled-components'

export const StyleSelectPlanBlockModal = {
  SelectPlanCard: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    gap: 12px;
    width: 100%;
    margin: 9px 0 58px;
  `,
  PlanItem: styled.div`
    position: relative;
    height: 70px;
    width: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    border-radius: 16px;
    background-color: ${Color.GREY};

    &[data-is-selected='true'] {
      background-color: ${Color.ACCENT_VIOLET};
      color: #fff;
    }

    &[data-is-default='true']::before {
      content: '| Most\\00a0popular choice*';
      position: absolute;
      width: 84px;
      bottom: -53px;
      left: 6px;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: #6e1fff;
    }
  `,
}
