import styled from 'styled-components'
import guaranteeStamp from 'assets/images/guarantee.svg'

export const GuaranteeCard = styled.div`
  padding: 32px 24px;
  background-color: #eff5f7;
  border-radius: 16px;
  background-image: url(${guaranteeStamp});
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) calc(100% - 10px);
  font-size: 13px;
  line-height: 20px;

  > h4 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    text-align: center;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
`
