import React from 'react'
import { PRIVACY_POLICY_LINK } from 'root-constants/common'
import { eventLogger } from 'services/eventLogger.service'
import { OuterLink } from './OuterLink'

export const PrivacyPolicyLink: React.FC = () => (
  <OuterLink
    href={PRIVACY_POLICY_LINK}
    onClick={() => eventLogger.logPrivacyPolicyClicked()}
  >
    Privacy Policy
  </OuterLink>
)
