import { useEffect } from 'react'
import { getUserStatusAction } from 'root-redux/actions/user'
import { useDispatch, useSelector } from 'react-redux'
import { selectUUID } from 'root-redux/selects/user'

export const useSetUserStatus = () => {
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  useEffect(() => {
    if (uuid) {
      dispatch(getUserStatusAction(uuid))
    }
  }, [dispatch, uuid])
}
