import { getCurrentOnboardingVariant } from 'helpers/getCurrentOnboardingVariant'
import { getCurrentOptimizeExperimentId } from 'helpers/getCurrentOptimizeExperimentId'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'
import { IEvent, IEventLogger, TQuestionPageParams } from 'models/events.model'
import { TProductId } from 'models/variant.model'
import { PaymentMethod } from 'modules/payment/constants'
import {
  EventLoggerInstanceName,
  LoginMethod,
  NO_NANE_AB_SEGMENT,
} from 'root-constants/common'

export const enum ScreenName {
  ONBOARDING = 'onboarding',
  CANCEL_OFFER = 'cancel_offer',
  UPSALE_SCREEN = 'upsale_screen',
}

export const enum Source {
  INITIAL = 'initial',
  UPSALE_OFFER = 'upsale_offer',
  CANCEL_OFFER = 'cancel_offer',
  EMAIL = 'email',
}

export const enum Events {
  SESSION_STARTED = 'session_start',
  AB_SEGMENT = 'ab_segment',
  QUESTION_COMPLETED = 'question_page_completed',
  PRESALE_PAGE_COMPLETED = 'presale_page_completed',
  PRESALE_PAGE_SHOW = 'presale_page_show',
  TRIAL_PRICE_PAGE_SHOW = 'trial_price_page_show',
  TRIAL_PRICE_PAGE_COMPLETED = 'trial_price_page_completed',
  EMAIL_PAGE_SHOW = 'email_page_show',
  EMAIL_PAGE_COMPLETED = 'email_page_completed',
  EMAIL_PAGE_ERROR = 'email_page_error',
  PAYMENT_METHOD_SELECTED = 'payment_method_selected',
  SALE_SCREEN_SHOW = 'plans_page_show',
  TERMS_OF_USE = 'terms_of_use_tap',
  PRIVACY_POLICY = 'privacy_policy_tap',
  CONTACT_US = 'contact_us_tap',
  NEED_HELP = 'need_help_tap',
  PURCHASE_SHOW = 'subs_purchase_show',
  PURCHASE_STARTED = 'subs_purchase_started',
  PURCHASE_COMPLETED = 'subs_purchase_completed',
  PURCHASE_FAILED = 'subs_purchase_failed',
  PURCHASE_SCREEN_CLOSE = 'subs_purchase_screen_close',
  CREATE_ACCOUNT_SHOW = 'create_account_page',
  LOGIN_METHOD_SELECTED = 'login_method_selected',
  ACCOUNT_CREATED = 'account_created',
  ACCOUNT_CREATION_FAILED = 'account_creation_failed',
  FINISH_ACCOUNT_SCREEN_VIEW = 'finish_account_screen_view',
  DOWNLOAD_BTN_PRESSED = 'download_button_press',
}

class EventLoggerService {
  private loggers?: Map<EventLoggerInstanceName, IEventLogger>
  private eventsQueue: IEvent[] = []

  init(...loggers: IEventLogger[]): void {
    const entriesArr = loggers.map(
      (logger) =>
        [logger.name, logger] as [EventLoggerInstanceName, IEventLogger],
    )
    this.loggers = new Map(entriesArr)
    this.notifyInitFinished()
  }

  logSessionStarted = (): void => {
    const event = Events.SESSION_STARTED
    const eventProperty = {
      device_type: getMobileOperatingSystem(),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logAbSegmentName({
    optimizeVariantId,
    optimizeExperimentId,
    optimizeSegmentName,
  }: {
    optimizeExperimentId: string
    optimizeVariantId: string
    optimizeSegmentName: string
  }): void {
    const event = Events.AB_SEGMENT
    const eventProperty = {
      optimize_experiment_id:
        getCurrentOptimizeExperimentId(optimizeExperimentId),
      ab_variant: getCurrentOnboardingVariant(optimizeVariantId),
      ab_segment_name: optimizeSegmentName || NO_NANE_AB_SEGMENT,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logQuestion({
    question,
    answers,
    pageName,
    level,
  }: TQuestionPageParams): void {
    const event = Events.QUESTION_COMPLETED

    const eventProperty = {
      question,
      answer: Array.isArray(answers) ? answers.join(',') : answers,
      screen_name: pageName,
      ...(level && { level }),
    }

    this.logEventOrPushToQueue(event, eventProperty)
  }

  // EmailWrapper Page Events
  logEmailPageShown(): void {
    const event = Events.EMAIL_PAGE_SHOW
    this.logEventOrPushToQueue(event)
  }

  logEmailPageCompleted(eventProperty: { email: string }): void {
    const event = Events.EMAIL_PAGE_COMPLETED
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logEmailPageError(eventProperty: { error: string }): void {
    const event = Events.EMAIL_PAGE_ERROR
    this.logEventOrPushToQueue(event, eventProperty)
  }

  // Presale Page Events
  logPresalePageShown({ pageName }: { pageName: string }): void {
    const eventProperty = {
      screen_name: pageName,
    }

    this.logEventOrPushToQueue(Events.PRESALE_PAGE_SHOW, eventProperty)
  }

  logPresalePageCompleted({ pageName }: { pageName: string }): void {
    const eventProperty = {
      screen_name: pageName,
    }

    this.logEventOrPushToQueue(Events.PRESALE_PAGE_COMPLETED, eventProperty)
  }

  // Sale Page Events
  logSalePageShown({
    productIds,
    pageName,
    onboardingId,
  }: {
    productIds: string[]
    pageName: ScreenName
    onboardingId?: string
  }): void {
    const event = Events.SALE_SCREEN_SHOW
    const eventProperty = {
      product_id: productIds.join(','),
      screen_name: pageName,
      onboarding_id: onboardingId || '',
    }

    this.logEventOrPushToQueue(event, eventProperty)
  }

  logTermsOfUseClicked(): void {
    const event = Events.TERMS_OF_USE
    this.logEventOrPushToQueue(event)
  }

  logPrivacyPolicyClicked(): void {
    const event = Events.PRIVACY_POLICY
    this.logEventOrPushToQueue(event)
  }

  logContactUsClicked(): void {
    const event = Events.CONTACT_US
    this.logEventOrPushToQueue(event)
  }

  logNeedHelpClicked(): void {
    const event = Events.NEED_HELP
    this.logEventOrPushToQueue(event)
  }

  // Trial modal events
  logTrialPricePageShown(): void {
    const event = Events.TRIAL_PRICE_PAGE_SHOW
    this.logEventOrPushToQueue(event)
  }

  logTrialPricePageCompleted(trialPrice: string): void {
    const event = Events.TRIAL_PRICE_PAGE_COMPLETED
    const eventProperty = {
      trial_price: trialPrice,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  // Account Page events
  logCreateAccountShown(): void {
    const event = Events.CREATE_ACCOUNT_SHOW
    this.logEventOrPushToQueue(event)
  }

  logLoginMethodSelected(eventProperty: { method: LoginMethod }): void {
    const event = Events.LOGIN_METHOD_SELECTED
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logAccountCreated(eventProperty: { method: LoginMethod | null }): void {
    const event = Events.ACCOUNT_CREATED
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logAccountCreationFailed({ error }: { error: string }): void {
    const event = Events.ACCOUNT_CREATION_FAILED
    const eventProperty = {
      error_reason: error,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  // Getting App Page Events
  logGettingAppShown(): void {
    const event = Events.FINISH_ACCOUNT_SCREEN_VIEW
    this.logEventOrPushToQueue(event)
  }

  logDownloadClicked(cb: () => void): void {
    const event = Events.DOWNLOAD_BTN_PRESSED
    this.logEventOrPushToQueue(event, {}, cb)
  }

  // Payment
  logPaymentMethodSelected({
    paymentMethod,
  }: {
    paymentMethod: PaymentMethod
  }): void {
    const event = Events.PAYMENT_METHOD_SELECTED
    const eventProperty = {
      payment_method: paymentMethod,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  // SubscriptionsWrapper Page events
  logPurchaseShown({
    productId,
    source = Source.INITIAL,
    isCancelOfferApplied = false,
  }: {
    productId: TProductId
    source?: Source
    isCancelOfferApplied?: boolean
  }): void {
    const event = Events.PURCHASE_SHOW
    const eventProperty = {
      source,
      onboarding_id: '',
      product_id: productId,
      screen_name: isCancelOfferApplied
        ? ScreenName.CANCEL_OFFER
        : ScreenName.ONBOARDING,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseStarted({
    productId,
    priceDetails: { price, trial = false, currency = 'USD' },
    paymentMethod,
    email,
    source = Source.INITIAL,
    isCancelOfferApplied = false,
  }: {
    productId: TProductId
    priceDetails: { price: number; trial?: boolean; currency?: string }
    paymentMethod: PaymentMethod
    email: string
    source?: Source
    isCancelOfferApplied?: boolean
  }): void {
    const event = Events.PURCHASE_STARTED
    const eventProperty = {
      email,
      source,
      onboarding_id: '',
      trial,
      price,
      currency,
      product_id: productId,
      screen_name: isCancelOfferApplied
        ? ScreenName.CANCEL_OFFER
        : ScreenName.ONBOARDING,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseCompleted({
    productId,
    priceDetails: { price, trial = false, currency = 'USD' },
    paymentMethod,
    discountApplied,
    transactionId,
    email,
    source = Source.INITIAL,
    isCancelOfferApplied,
  }: {
    productId: TProductId
    priceDetails: { price: number; trial?: boolean; currency?: string }
    email: string
    paymentMethod?: PaymentMethod
    discountApplied?: string
    transactionId?: string
    source?: Source
    isCancelOfferApplied: boolean
  }): void {
    const event = Events.PURCHASE_COMPLETED
    const eventProperty = {
      email,
      onboarding_id: '',
      source,
      trial,
      price,
      currency,
      product_id: productId,
      screen_name: isCancelOfferApplied
        ? ScreenName.CANCEL_OFFER
        : ScreenName.ONBOARDING,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      ...(transactionId && { transaction_id: transactionId }),
      ...(discountApplied && { discount_applied: discountApplied }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseFailed({
    productId,
    priceDetails: { price, trial = false, currency = 'USD' },
    error: { description, type, code },
    paymentMethod,
    source = Source.INITIAL,
    email,
    isCancelOfferApplied = false,
  }: {
    productId: TProductId
    priceDetails: { price: number; trial?: boolean; currency?: string }
    error: { type: string; description?: string; code?: string }
    paymentMethod?: PaymentMethod
    source?: Source
    email: string
    isCancelOfferApplied?: boolean
  }): void {
    const event = Events.PURCHASE_FAILED
    const eventProperty = {
      email,
      onboarding_id: '',
      source,
      trial,
      price,
      currency,
      error_type: type,
      ...(description && { error_description: description }),
      ...(code && { error_code: code }),
      product_id: productId,
      screen_name: isCancelOfferApplied
        ? ScreenName.CANCEL_OFFER
        : ScreenName.ONBOARDING,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseScreenClosed({ productId }: { productId: TProductId }): void {
    const event = Events.PURCHASE_SCREEN_CLOSE
    const eventProperty = {
      product_id: productId,
      screen_name: ScreenName.ONBOARDING,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  private logEventOrPushToQueue(
    event: Events,
    eventProperty?: Record<string, any>,
    cb?: () => void,
  ): void {
    if (this.loggers?.size) {
      this.logEvent(event, eventProperty, cb)
    } else {
      this.eventsQueue.push({ event, eventProperty })
    }
  }

  private notifyInitFinished() {
    if (this.eventsQueue.length) {
      this.eventsQueue.forEach(({ event, eventProperty }) =>
        this.logEvent(event, eventProperty),
      )
      this.eventsQueue = []
    }
  }

  private logEvent(
    event: Events,
    eventProperty?: Record<string, any>,
    cb?: () => void,
  ): void {
    this.loggers?.forEach((logger) => {
      // Send callback only for one amplitude analytic to avoid 2 callbacks
      const singleCb =
        logger.name === EventLoggerInstanceName.GIA ? undefined : cb

      logger.log(event, eventProperty, singleCb)
    })
  }
}

export const eventLogger = new EventLoggerService()
