import React from 'react'
import { StyledModal as S } from './Modal.styles'

type TProps = {
  isShown: boolean
  onClose: () => void
  children?: React.ReactNode
}

export const Modal: React.FC<TProps> = ({ isShown, onClose, children }) => {
  return isShown ? (
    <S.Wrapper>
      <S.Container>
        <S.TextWrapper>{children}</S.TextWrapper>
        <S.Button type="button" onClick={onClose}>
          Okay
        </S.Button>
      </S.Container>
    </S.Wrapper>
  ) : null
}
