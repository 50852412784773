import successIcon from 'assets/images/success.svg'
import { FlowContext } from 'components/FlowContext'
import React, { useContext, useEffect } from 'react'
import { StyledPaymentStatus } from '../styles'

export const PaymentSuccess: React.FC = () => {
  const { onNextStep } = useContext(FlowContext)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onNextStep()
    }, 2000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [onNextStep])

  return (
    <StyledPaymentStatus.Wrapper>
      <StyledPaymentStatus.Container>
        <img src={successIcon} width="108" alt="Success" />
        <StyledPaymentStatus.Title>
          Your payment was successful!
        </StyledPaymentStatus.Title>
        <StyledPaymentStatus.Subtitle>Thank you!</StyledPaymentStatus.Subtitle>
      </StyledPaymentStatus.Container>
    </StyledPaymentStatus.Wrapper>
  )
}
