import { FlowContext } from 'components/FlowContext'
import { ISubscription } from 'models/variant.model'
import { setSelectedSubscriptionAction } from 'modules/payment/redux/actions'
import { selectSubscription } from 'modules/payment/redux/selects'
import React, { useCallback, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PagePath } from 'root-constants/common'
import { selectCurrentVariantCohort } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

interface IProps {
  children: React.FC<any>
}

export const SubscriptionsWrapper: React.FC<IProps> = ({ children }) => {
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const selectedSubscription = useSelector(selectSubscription)
  const uuid = useSelector(selectUUID)
  const { onSkipSteps } = useContext(FlowContext)

  useEffect(() => {
    document.body.scrollTo({ top: 0, behavior: 'instant' as any })
  }, [])

  const handleShowPayment = useCallback(() => {
    window.fbq('track', 'AddToCart', {}, { eventID: uuid })
    googleAnalyticsLogger.logPageView(`${PagePath.SUBSCRIPTIONS}/${cohort}`)
    googleAnalyticsLogger.logAddingToCart(selectedSubscription as ISubscription)

    onSkipSteps()
  }, [uuid, cohort, selectedSubscription, onSkipSteps])

  const handleSelectSubscription = useCallback(
    (subscription?: ISubscription): void => {
      subscription && dispatch(setSelectedSubscriptionAction(subscription))
    },
    [dispatch],
  )

  return children({
    onButtonClick: handleShowPayment,
    onSubscriptionSelect: handleSelectSubscription,
  })
}
