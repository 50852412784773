import { TAnswers } from 'models/common.model'
import { IConfig } from 'models/config.model'
import { IVariant } from 'models/variant.model'
import { AnyAction } from 'redux'
import {
  RESET_ANSWERS,
  RESET_ERROR,
  SET_ANSWERS,
  SET_CONFIG,
  SET_ERROR,
  SET_OPTIMIZE_EXPERIMENT_ID_BY_VARIANT_ID_MAP,
  SET_VARIANT,
  SET_IS_PAID_TRIAL_MODAL_SHOWN,
  START_FETCHING,
  STOP_FETCHING,
} from 'root-redux/actions/common'

const initState: ICommonState = {
  isFetching: false,
  actionList: [],
  error: null,
  currentVariant: null,
  config: null,
  answers: null,
  isPaidTrialModalShown: true,
  optimizeExperimentIdByVariantIdMap: null,
}

export interface ICommonState {
  isFetching: boolean
  actionList: string[]
  error: any
  currentVariant: IVariant | null
  config: IConfig | null
  answers: TAnswers | null
  isPaidTrialModalShown: boolean
  optimizeExperimentIdByVariantIdMap: Record<string, string> | null
}

export function commonReducer(
  state: ICommonState = initState,
  { type, payload = null }: AnyAction,
): ICommonState {
  switch (type) {
    case START_FETCHING: {
      return {
        ...state,
        isFetching: true,
        actionList: state.actionList.concat(payload),
      }
    }
    case STOP_FETCHING: {
      return {
        ...state,
        isFetching: !!payload.length,
        actionList: payload,
      }
    }
    case SET_ERROR: {
      return {
        ...state,
        error: payload,
      }
    }
    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      }
    }
    case SET_VARIANT: {
      return {
        ...state,
        currentVariant: {
          ...payload,
          subscriptions: payload.subscriptions.sort(
            (a, b) => a.showOrder - b.showOrder,
          ),
        },
      }
    }
    case SET_CONFIG: {
      return {
        ...state,
        config: payload,
      }
    }
    case SET_ANSWERS: {
      return {
        ...state,
        answers: { ...state.answers, ...payload },
      }
    }
    case RESET_ANSWERS: {
      return {
        ...state,
        answers: null,
      }
    }
    case SET_IS_PAID_TRIAL_MODAL_SHOWN: {
      return {
        ...state,
        isPaidTrialModalShown: payload,
      }
    }
    case SET_OPTIMIZE_EXPERIMENT_ID_BY_VARIANT_ID_MAP: {
      return {
        ...state,
        optimizeExperimentIdByVariantIdMap: {
          ...state.optimizeExperimentIdByVariantIdMap,
          ...payload,
        },
      }
    }
    default:
      return state
  }
}
