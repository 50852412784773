import { ISubscription } from 'models/variant.model'
import { AnyAction } from 'redux'
import {
  RESET_3D_SECURE_IFRAME_URL,
  SET_3D_SECURE_IFRAME_URL,
  SET_IS_PAYMENT_FLOWS_SHOWN,
  SET_PAYMENT_CLIENT_SECRET,
  SET_SUBSCRIPTION,
  SET_SUBSCRIPTION_ID,
} from './actions'

export interface IPaymentState {
  selectedSubscription: ISubscription | null
  threeDSecureIframeURL: string
  paymentClientSecret: string
  subscriptionId: string
  isPaymentFlowsShown: boolean
}

const initState: IPaymentState = {
  selectedSubscription: null,
  threeDSecureIframeURL: '',
  paymentClientSecret: '',
  subscriptionId: '',
  isPaymentFlowsShown: false,
}

export function paymentReducer(
  state: IPaymentState = initState,
  { type, payload = null }: AnyAction,
): IPaymentState {
  switch (type) {
    case SET_SUBSCRIPTION: {
      return {
        ...state,
        selectedSubscription: payload,
      }
    }
    case SET_3D_SECURE_IFRAME_URL: {
      return {
        ...state,
        threeDSecureIframeURL: payload,
      }
    }
    case RESET_3D_SECURE_IFRAME_URL: {
      return {
        ...state,
        threeDSecureIframeURL: '',
      }
    }
    case SET_PAYMENT_CLIENT_SECRET: {
      return {
        ...state,
        paymentClientSecret: payload,
      }
    }
    case SET_SUBSCRIPTION_ID: {
      return {
        ...state,
        subscriptionId: payload,
      }
    }
    case SET_IS_PAYMENT_FLOWS_SHOWN: {
      return {
        ...state,
        isPaymentFlowsShown: payload,
      }
    }
    default:
      return state
  }
}
