import { Option, OptionType } from 'components/Option'
import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'root-constants/pages'
import { setAnswersAction } from 'root-redux/actions/common'
import { QuestionButton } from 'components/QuestionButton'
import { StyledOption } from 'components/Option.styles'
import { FixedContainer } from 'components/FixedContainer'
import { Button } from 'components/Button'
import { StyledData as S } from './Data.styles'

const PAGE_NAME = 'Data'
const QUESTION = 'What kind of data are you looking for during using Famio?'
const OPTIONS_VALUES = {
  movementHistory: 'Movement history',
  locationNotifications: 'Location notifications',
  batteryAlerts: 'Battery alerts',
  sosNotifications: 'SOS messages',
  drivingMode: 'Car-crash notifications',
  all: 'All of the above',
}

const Component: TDynamicPageComponent = () => {
  const { onNextStep, currentPageId } = useContext(FlowContext)
  const dispatch = useDispatch()

  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useCallback(() => {
    dispatch(
      setAnswersAction({
        question: QUESTION,
        answers,
        pageId: currentPageId,
        pageName: PAGE_NAME,
      }),
    )
    onNextStep()
  }, [answers, currentPageId, dispatch, onNextStep])

  const optionProps = useMemo(
    () => ({
      name: currentPageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked && value === OPTIONS_VALUES.all) {
          setAnswers([OPTIONS_VALUES.all])
          return
        }

        if (isChecked) {
          const newValues = [...answers, value].filter(
            (item) => item !== OPTIONS_VALUES.all,
          )
          setAnswers(newValues)
          return
        }

        const newValues = answers.filter((item) => item !== value)
        setAnswers(newValues)
      },
    }),
    [answers, currentPageId],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer style={{ paddingBottom: 4 }}>
          <Option
            {...optionProps}
            value={OPTIONS_VALUES.movementHistory}
            checked={answers.includes(OPTIONS_VALUES.movementHistory)}
          >
            <QuestionButton hasCheckboxIcon>
              {OPTIONS_VALUES.movementHistory}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTIONS_VALUES.locationNotifications}
            checked={answers.includes(OPTIONS_VALUES.locationNotifications)}
          >
            <QuestionButton hasCheckboxIcon>
              {OPTIONS_VALUES.locationNotifications}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTIONS_VALUES.sosNotifications}
            checked={answers.includes(OPTIONS_VALUES.sosNotifications)}
          >
            <QuestionButton hasCheckboxIcon>
              {OPTIONS_VALUES.sosNotifications}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTIONS_VALUES.batteryAlerts}
            checked={answers.includes(OPTIONS_VALUES.batteryAlerts)}
          >
            <QuestionButton hasCheckboxIcon>
              {OPTIONS_VALUES.batteryAlerts}
            </QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTIONS_VALUES.drivingMode}
            checked={answers.includes(OPTIONS_VALUES.drivingMode)}
          >
            <QuestionButton hasCheckboxIcon>
              {OPTIONS_VALUES.drivingMode}
            </QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTIONS_VALUES.all}
            checked={answers.includes(OPTIONS_VALUES.all)}
          >
            <QuestionButton hasCheckboxIcon>
              {OPTIONS_VALUES.all}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <FixedContainer>
          <Button onClick={handleContinue} disabled={!answers.length}>
            Next
          </Button>
        </FixedContainer>
      </S.Column>
    </S.Wrapper>
  )
}

Component.id = PageId.DATA_1
Component.hasHeader = true

export const DataVariant1 = Component
