import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { FREQUENCY_OPTIONS_VALUES } from 'root-constants/common'
import { PageId } from 'root-constants/pages'
import { selectAnswers } from 'root-redux/selects/common'

export const useHasConcernsAboutPartnersSafety = () => {
  const answers = useSelector(selectAnswers)
  return useMemo(
    () =>
      answers?.[PageId.CONCERNS_1] === FREQUENCY_OPTIONS_VALUES.quiteOften ||
      answers?.[PageId.CONCERNS_1] === FREQUENCY_OPTIONS_VALUES.sometimes,
    [answers],
  )
}
