import { IAppState } from 'models/store.model'
import { IUserStatus } from 'models/user.model'
import { LoginMethod } from 'root-constants/common'

export const selectUUID = (state: IAppState): string => state.user.uuid
export const selectUserStatus = (state: IAppState): IUserStatus | null =>
  state.user.status
export const selectHasUserSubscription = (state: IAppState): boolean | null =>
  state.user.status?.hasSubscription || null
export const selectUserAccountLoginMethod = (
  state: IAppState,
): LoginMethod | null => state.user.status?.account?.loginMethod || null
export const selectSecret = (state: IAppState): string => state.user.secret
export const selectEmail = (state: IAppState): string =>
  state.user.status?.email?.onboardingEmail || ''
