import { StyledOption } from 'components/Option.styles'
import { QuestionButton } from 'components/QuestionButton'
import { baseColumnStyles, basePageStyles } from 'components/styles'
import styled from 'styled-components'
import familyImg from 'assets/images/intro-3.jpg'
import { Color, HEADER_HEIGHT } from 'root-constants/common'

export const StyledIntroVariant3 = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100%;
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.8) 0%,
        #ffffff 8%,
        rgba(255, 255, 255, 0) 34%,
        #ffffff 58%,
        #ffffff 100%
      ),
      url(${familyImg});
    background-size: 100% auto, 414px auto;
    background-repeat: no-repeat;
    background-position: top center, top ${HEADER_HEIGHT}px center;
    padding-top: 72px;
  `,
  FixedContainer: styled.div`
    width: 100%;
    padding: 60px 0 0;

    @media (min-height: 620px) {
      position: fixed;
      bottom: 0;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    text-align: center;
    margin: 0 auto;
  `,
  Title: styled.h1`
    font-weight: 800;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 164px;
  `,
  Question: styled.h2`
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 8px;
  `,
  Note: styled.span`
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 7px;
    color: #bcc9d0;

    @media (min-height: 670px) {
      margin-bottom: 24px;
    }
  `,
  OptionsContainer: styled(StyledOption.OptionsContainer)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 16px;
    padding-bottom: 0;

    label {
      width: 147px;
      flex-shrink: 0;
    }

    label:last-child {
      width: 100%;
    }

    @media (min-height: 670px) {
      gap: 11px 16px;
    }
  `,
  QuestionButton: styled(QuestionButton)`
    height: 54px;
    justify-content: center;
    border-radius: 30px;
    border: 3px solid transparent;
    padding: 0;

    input:checked + & {
      border: 3px solid ${Color.ACCENT_VIOLET};
      background-color: ${Color.GREY};
      color: ${Color.DEFAULT_TEXT};
    }
  `,
}
