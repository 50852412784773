import { Button } from 'components/Button'
import { basePageStyles } from 'components/styles'
import { Color } from 'root-constants/common'
import styled, { createGlobalStyle, keyframes } from 'styled-components'

export const GlobalStyleForProcessing = createGlobalStyle`
   body {
     background-color: #6336FF;
   }
`
const animation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const StyledPreloader = {
  Wrapper: styled.div`
    ${basePageStyles};
    justify-content: space-between;
    min-height: 100%;
    padding-bottom: 35px;
  `,
  Header: styled.header`
    width: 100%;
    padding: 11px 0 6px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #ccdce5;
  `,
  Button: styled(Button)`
    opacity: 0;
    background-color: #fff;
    color: ${Color.ACCENT_VIOLET};

    &[data-is-shown='true'] {
      animation: ${animation} 1000ms ease-in 1 forwards;
    }
  `,
}
