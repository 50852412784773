import { useURLSearchData } from 'hooks/useURLSearchData'
import React, { useMemo } from 'react'
import { GOALS_PARAM_KEY, UrlDataGoals } from 'root-constants/urlData'
import { StyledBenefits as S } from './Benefits.styles'

export const Benefits: React.FC = () => {
  const { getDataFromURL } = useURLSearchData()

  const goals = useMemo(() => getDataFromURL(GOALS_PARAM_KEY), [getDataFromURL])

  const isOneGoal = useMemo(() => typeof goals === 'string', [goals])

  return (
    <S.Wrapper>
      <S.Title>
        {goals === UrlDataGoals.PARTNER
          ? 'With Famio you can:'
          : 'Famio benefits'}
      </S.Title>

      {goals === UrlDataGoals.PARTNER && (
        <ul>
          <S.ListItem>
            <strong>Track</strong> your partner’s movement history
          </S.ListItem>
          <S.ListItem>
            <strong>Get real-time alerts</strong> of suspicious activity
          </S.ListItem>
          <S.ListItem>
            Instantly <strong>detect SOS and car-crash notifications</strong>
          </S.ListItem>
          <S.ListItem>
            <strong>Reduce</strong> anxiety and fear of infidelity
          </S.ListItem>
        </ul>
      )}

      {goals === UrlDataGoals.KID && (
        <ul>
          <S.ListItem>
            <strong>
              Be notified when your child arrives or leaves a particular area
            </strong>
          </S.ListItem>
          <S.ListItem>
            <strong>Track their movement history</strong>
          </S.ListItem>
          <S.ListItem>
            Get <strong>real-time notifications with SOS alerts</strong>
          </S.ListItem>
          <S.ListItem>
            <strong>Driving mode</strong>
          </S.ListItem>
          <S.ListItem>
            <strong>A stress-free parenting</strong>
          </S.ListItem>
        </ul>
      )}

      {goals === UrlDataGoals.FRIEND && (
        <ul>
          <S.ListItem>
            <strong>Be informed with real-time tracking</strong>
          </S.ListItem>
          <S.ListItem>
            Instant <strong>notifications and alerts</strong>
          </S.ListItem>
          <S.ListItem>
            <strong>Driving mode</strong>
          </S.ListItem>
          <S.ListItem>
            <strong>Be connected</strong> with your family or friends
          </S.ListItem>
          <S.ListItem>
            <strong>Extra security with SOS alerts</strong>
          </S.ListItem>
        </ul>
      )}

      {!isOneGoal && (
        <ul style={{ fontWeight: 700 }}>
          <S.ListItem>Arrival/leaving notifications</S.ListItem>
          <S.ListItem>Track their movement history</S.ListItem>
          <S.ListItem>Instant notifications and alerts</S.ListItem>
          <S.ListItem>Driving mode</S.ListItem>
          <S.ListItem>Be connected with your family or friends</S.ListItem>
          <S.ListItem>A stress-free life</S.ListItem>
        </ul>
      )}
    </S.Wrapper>
  )
}
