import { getDinamicPageComponent } from 'helpers/getDinamicPageComponent'
import React, { useContext, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { selectCurrentVariantSteps } from 'root-redux/selects/common'
import { PageId } from '../constants/pages'
import { FlowContext } from './FlowContext'
import { StyledHeader as S } from './StripeFlowHeader.styles'

export const StripeFlowHeader: React.FC = () => {
  const { currentPageId } = useContext(FlowContext)
  const steps = useSelector(selectCurrentVariantSteps)
  const pagesIds = useMemo(() => {
    const stepsIds = steps.map(({ id }) => id)
    return [...stepsIds, PageId.ACCOUNT, PageId.DOWNLOAD]
  }, [steps])

  const pagesWithHeader = useMemo(() => {
    return pagesIds.filter((id) => getDinamicPageComponent(id)?.hasHeader)
  }, [pagesIds])

  const currentPageIndex = useMemo(
    () => pagesWithHeader.findIndex((id) => id === currentPageId),
    [currentPageId, pagesWithHeader],
  )

  return currentPageIndex < 0
    ? null
    : ReactDOM.createPortal(
        // eslint-disable-next-line react/jsx-indent
        <S.HeaderWrapper>
          <S.Header>Happy Famio</S.Header>
        </S.HeaderWrapper>,
        document.getElementById('root') as HTMLElement,
      )
}
