import { useEffect } from 'react'
import { setUpUUIDAction } from 'root-redux/actions/user'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentVariantCohort,
  selectGiaApiKey,
} from 'root-redux/selects/common'

export const useSetUuid = () => {
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const giaApiKey = useSelector(selectGiaApiKey)

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search)
    const uuidFromUrl = searchParams.get('uuid')

    if (cohort && giaApiKey) {
      dispatch(setUpUUIDAction({ cohort, giaApiKey, uuid: uuidFromUrl }))
    }
  }, [dispatch, cohort, giaApiKey])
}
