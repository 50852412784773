import { IResponseResult, IRequestOptions } from 'models/api.model'
import {
  IPurchaseResponseRaw,
  IPurchaseRetrySubscriptionResponseRaw,
} from 'models/payment.model'
import { RequestMethod } from 'root-constants/common'
import { ApiService } from 'services/api.service'
import { StripeError } from '@stripe/stripe-js'
import { TPriceId, TTrialPriceId } from 'models/variant.model'

export class PaymentApi {
  private readonly baseUrl = 'v1/flow/purchase'
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  createSubscription(payload: {
    uuid: string
    priceId: TPriceId
    trialPriceId: TTrialPriceId | null
    trialPeriodDays: number
    paymentMethodId: string
  }): Promise<IResponseResult<IPurchaseResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        uuid: payload.uuid,
        price_id: payload.priceId,
        trial_id: payload.trialPriceId,
        trial_period_days: payload.trialPeriodDays,
        ...(payload.paymentMethodId && {
          payment_method: payload.paymentMethodId,
        }),
      },
    }

    return this.api.makeRequest<IPurchaseResponseRaw>(this.baseUrl, options)
  }

  retryPayment(payload: {
    uuid: string
    stripeError: StripeError
  }): Promise<IResponseResult<IPurchaseRetrySubscriptionResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        uuid: payload.uuid,
        payment_error: payload.stripeError,
      },
    }
    return this.api.makeRequest<IPurchaseRetrySubscriptionResponseRaw>(
      `${this.baseUrl}/retry`,
      options,
    )
  }

  createPaypalSubscription(payload: {
    uuid: string
    paypalPlanId: string | null
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        uuid: payload.uuid,
        paypal_plan_id: payload.paypalPlanId,
      },
    }
    return this.api.makeRequest<IPurchaseRetrySubscriptionResponseRaw>(
      `${this.baseUrl}/paypal`,
      options,
    )
  }
}
