import { Button } from 'components/Button'
import styled from 'styled-components'

export const StyledSubheaderWithTimer = {
  TimerContainer: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 80px;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: #0a324d;

    &[data-is-visible='true'] {
      display: flex;
    }
  `,
  TimerContent: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  `,
  Timer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;

    strong {
      font-size: 38px;
      font-weight: 700;
      line-height: 40px;
    }

    em {
      font-size: 13px;
      line-height: 18px;
      font-style: normal;
    }
  `,
  Button: styled(Button)`
    width: 185px;
    height: 40px;
    line-height: 40px;
  `,
}
