import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'
import React, { useRef } from 'react'
import { useTimerForTarget } from '../hooks/useTimerForTarget'
import { StyledSubheaderWithTimer as S } from './SubheaderWithTimer.styles'

const FIFTEEN_MINUTES_IN_SECONDS = 900

interface IProps {
  elemForComparisonRef: React.RefObject<HTMLElement>
  onClick: () => void
  timeInSeconds?: number
}

export const SubheaderWithTimer: React.FC<IProps> = ({
  elemForComparisonRef,
  onClick,
  timeInSeconds = FIFTEEN_MINUTES_IN_SECONDS,
}) => {
  const timerElementRef = useRef<HTMLElement>(null)
  const isTargetHidden = useTargetHiddenOnScroll(elemForComparisonRef)

  useTimerForTarget(timerElementRef, timeInSeconds)

  return (
    <S.TimerContainer data-is-visible={isTargetHidden}>
      <S.TimerContent>
        <S.Timer>
          <strong ref={timerElementRef} />
          <em>minutes : seconds</em>
        </S.Timer>
        <S.Button onClick={onClick}>Get my plan</S.Button>
      </S.TimerContent>
    </S.TimerContainer>
  )
}
