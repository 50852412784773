import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'components/styles'
import { HEADER_HEIGHT } from 'root-constants/common'
import styled, { css } from 'styled-components'
import sadGirlImg from 'assets/images/proof-1.webp'
import usersImg from 'assets/images/proof-2.webp'
import maleImg from 'assets/images/proof-3-male.webp'
import femaleImg from 'assets/images/proof-3-female.webp'
import { UrlDataGender } from 'root-constants/urlData'

const baseWrapperStyles = css`
  ${basePageStyles};
  min-height: 100%;
  padding-top: ${HEADER_HEIGHT}px;
  background-repeat: no-repeat;
  background-position: top ${HEADER_HEIGHT}px center;
`

export const StyledSocialProof = {
  Wrapper: styled.div`
    ${baseWrapperStyles};
    background-image: url(${sadGirlImg});
    background-size: contain;
  `,
  Wrapper2: styled.div`
    ${baseWrapperStyles};
    background-image: url(${usersImg});
    background-size: 104%;
  `,
  Wrapper3: styled.div`
    ${baseWrapperStyles};
    background-size: contain;

    &[data-gender=${UrlDataGender.MALE}] {
      background-image: url(${femaleImg});
    }
    &[data-gender=${UrlDataGender.FEMALE}] {
      background-image: url(${maleImg});
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    position: fixed;
    bottom: 0;

    @media (min-height: 550px) {
      padding-bottom: 35px;
    }
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 16px;
  `,
  Text: styled.p`
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 16px;
  `,
  Description: styled.p`
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    margin-bottom: 20px;

    strong {
      display: block;
      font-weight: 800;
      font-size: 52px;
      line-height: 71px;
      margin-bottom: 10px;
    }

    @media (min-height: 550px) {
      margin-bottom: 51px;
    }
  `,
  Note: styled.span`
    font-style: italic;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #bcc9d0;
    margin-bottom: 21px;
  `,
}
