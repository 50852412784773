import { Button } from 'components/Button'
import { ModalContainerBase, ModalWrapperBase } from 'components/styles'
import styled from 'styled-components'

export const StyledPaymentStatus = {
  Wrapper: styled.div`
    ${ModalWrapperBase};
  `,
  Container: styled.div`
    ${ModalContainerBase};
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Title: styled.h2`
    margin: 16px 0 12px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  `,
  Subtitle: styled.h3`
    max-width: 288px;
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
  `,
  Button: styled(Button)`
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
  `,
}
