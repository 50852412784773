import { StickyButton } from 'components/StickyButton'
import { baseColumnStyles, basePageStyles } from 'components/styles'
import { BASE_COLUMN_WIDTH } from 'root-constants/common'
import styled from 'styled-components'

type TStyledModalProps = {
  isShown: boolean
}

export const StyledPaidTrialModal = {
  Wrapper: styled.div<TStyledModalProps>`
    ${basePageStyles};
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ isShown }) => (isShown ? 10 : -1)};
    width: max(100%, var(--min-screen-width));
    height: var(--full-height, 100vh);
    overflow-y: scroll;
    background-color: #ffffff;
    opacity: ${({ isShown }) => (isShown ? 1 : 0)};
    transition: opacity 0.2 ease-out;
    line-height: 1.3;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    align-items: flex-start;
    padding: 40px 0 35px;
  `,
  HeaderWrapper: styled.div`
    width: 100%;
    border-bottom: 1px solid #ccdce5;
  `,
  Header: styled.header`
    width: ${BASE_COLUMN_WIDTH}px;
    padding: 11px 0 6px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Title: styled.h2`
    align-self: center;
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    margin-bottom: 16px;
  `,
  Text: styled.p`
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
  `,
  Text2: styled.p`
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 16px;
  `,
  StickyButton: styled(StickyButton)`
    @media (max-height: 670px) {
      position: static;
    }
  `,
}
