import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { useConfirmationPasswordInputField } from 'hooks/useConfirmationPasswordInputField'
import { useEmailInputField } from 'hooks/useEmailInputField'
import { usePasswordInputField } from 'hooks/usePasswordInputField'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsFetching } from 'root-redux/selects/common'
import {
  registerWithEmailFirebaseAction,
  resetEmailErrorMessageAction,
  resetPasswordErrorMessageAction,
} from '../redux/actions'
import {
  selectEmailErrorMessage,
  selectPasswordErrorMessage,
} from '../redux/selects'
import { StyledEmailAccount as S } from './EmailAccount.styles'

type TProps = {
  onBack: () => void
}

export const EmailAccount: React.FC<TProps> = ({ onBack }) => {
  const dispatch = useDispatch()
  const isFetching = useSelector(selectIsFetching)
  const emailErrorMessage = useSelector(selectEmailErrorMessage)
  const passwordErrorMessage = useSelector(selectPasswordErrorMessage)
  const [email, setEmail, validateEmail] = useEmailInputField(
    emailErrorMessage,
    () => dispatch(resetEmailErrorMessageAction()),
  )
  const [password, setPassword, validatePassword] = usePasswordInputField(
    passwordErrorMessage,
    () => dispatch(resetPasswordErrorMessageAction()),
  )
  const [
    confirmationPassword,
    setConfirmationPassword,
    validateConfirmationPassword,
  ] = useConfirmationPasswordInputField(password)

  const isComplete = useMemo(
    () =>
      email.isValid &&
      email.value !== '' &&
      password.isValid &&
      password.value !== '' &&
      confirmationPassword.isValid &&
      confirmationPassword.value !== '',
    [
      confirmationPassword.isValid,
      confirmationPassword.value,
      email.isValid,
      email.value,
      password.isValid,
      password.value,
    ],
  )

  return (
    <S.Wrapper>
      <S.BackButton onClick={onBack} />
      <S.Title>Create your account</S.Title>
      <Input
        value={email.value}
        isValid={email.isValid}
        validationText={email.validationText}
        type="email"
        labelName="Email"
        placeholder="johnsmith@mail.com"
        onChange={({ target: { value } }) => {
          setEmail((prevState) => ({
            ...prevState,
            value,
            isValid: true,
            validationText: '',
          }))
        }}
        onBlur={({ target: { value } }) => validateEmail(value)}
      />
      <Input
        value={password.value}
        isValid={password.isValid}
        validationText={password.validationText}
        labelName="Password"
        placeholder="Your password"
        hasVisibilityControl
        onChange={({ target: { value } }) => {
          setPassword((prevState) => ({
            ...prevState,
            value,
            isValid: true,
            validationText: '',
          }))
        }}
        onBlur={({ target: { value } }) => validatePassword(value)}
      />
      <Input
        value={confirmationPassword.value}
        isValid={confirmationPassword.isValid}
        validationText={confirmationPassword.validationText}
        hasVisibilityControl
        labelName="Repeat password"
        placeholder="Your password"
        onChange={({ target: { value } }) => {
          setConfirmationPassword((prevState) => ({
            ...prevState,
            value,
            isValid: true,
            validationText: '',
          }))
        }}
        onBlur={({ target: { value } }) => validateConfirmationPassword(value)}
      />
      <S.Footer>
        <S.Description>
          By signing up you agree to our <TermsOfUseLink title="Terms" /> and{' '}
          <PrivacyPolicyLink />
        </S.Description>
        <Button
          disabled={!isComplete || isFetching}
          onClick={() => {
            dispatch(
              registerWithEmailFirebaseAction(
                email.value.toLowerCase(),
                password.value,
              ),
            )
          }}
        >
          Sign up
        </Button>
      </S.Footer>
    </S.Wrapper>
  )
}
