export const enum CurrentEnvironment {
  DEV = 'dev',
  PROD = 'prod',
}

export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
}

export const enum StripeFieldName {
  NAME = 'name',
  NUMBER = 'cardNumber',
  EXPIRY = 'cardExpiry',
  CVC = 'cardCvc',
}

export const BASE_COLUMN_WIDTH = 310

export const enum MediaBreakpoint {
  DEFAULT_PHONE = 375,
  MAX_PHONE = 415,
}

export const HEADER_HEIGHT = 56

export const TERMS_OF_USE_LINK = 'https://harmonybit.com/terms-of-use/'
export const PRIVACY_POLICY_LINK = 'https://harmonybit.com/privacy-policy/'

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const enum LoginMethod {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export const enum LoginMethodRaw {
  EMAIL = 'password',
  GOOGLE = 'google.com',
  FACEBOOK = 'facebook.com',
  APPLE = 'apple.com',
}

export const LOGIN_METHOD_RAW_MAP = {
  [LoginMethodRaw.EMAIL]: LoginMethod.EMAIL,
  [LoginMethodRaw.GOOGLE]: LoginMethod.GOOGLE,
  [LoginMethodRaw.FACEBOOK]: LoginMethod.FACEBOOK,
  [LoginMethodRaw.APPLE]: LoginMethod.APPLE,
}

export const enum FirebaseSignUpErrorCode {
  EMAIL_EXISTS = 'auth/email-already-in-use',
}

export const enum FirebaseSignInErrorCode {
  WRONG_PASSWORD = 'auth/wrong-password',
  USER_NOT_FOUND = 'auth/user-not-found',
  USER_DISABLED = 'auth/user-disabled',
  INVALID_EMAIL = 'auth/invalid-email',
}

export const enum ValidationText {
  EMAIL_EXISTS = `Account with this email already exists`,
  INVALID_EMAIL = `Your email doesn't look valid. Please, check`,
  SHORT_PASSWORD = 'At least 8 characters',
  WRONG_CONFIRMATION_PASSWORD = `Confirmation password doesn't equal password`,
  WRONG_PASSWORD = 'The password you entered is incorrect',
  WRONG_EMAIL = 'The email you entered is incorrect',
  NOT_AGREE_WITH_POLITICS = 'Please check that you agree with Terms of Use and Privacy Policy.',
}

export const enum Color {
  DEFAULT_TEXT = '#0A324D',
  LIGHT_TEXT = '#7B8898',
  ERROR = '#FE5959',
  GREY = '#EFF5F7',
  ACCENT_VIOLET = '#6336FF',
}

export const enum TimeInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const enum EventLoggerInstanceName {
  GIA = 'gia-amplitude',
  AMPLITUDE = 'amplitude',
  USER_FLOW_TRACKER = 'user-flow-tracker',
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum AppWithInAppBrowser {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export const enum UserAgent {
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  OTHER = 'other',
}

export const enum PagePath {
  LOCALHOST = 'localhost',
  SUBSCRIPTIONS = 'subscriptions',
}

export const TEST_ENV_QUERY_PARAM = 'is_test_environment'
export const STRIPE_COHORT = 'famio3'
export const DEFAULT_COHORT = STRIPE_COHORT
export const MAIN_COHORT = 'famio_main1'
export const APP_NAME = 'famio'
export const CENTS_IN_DOLLAR = 100
export const CUSTOM_TOKEN_LOCAL_STORAGE_KEY = 'CUSTOM_TOKEN'
export const DEFAULT_CURRENCY = 'usd'
export const NO_NANE_AB_SEGMENT = 'no_name'
export const NO_TEST_OPTIMIZE_VARIANT = 'no_test'
export const EXPERIMENT_ID_NOT_SET = 'experiment_id_not_set'

export const FREQUENCY_OPTIONS_VALUES = {
  quiteOften: 'Quite often',
  sometimes: 'Sometimes',
  rarely: 'Rarely',
  never: 'Never',
}

export const DISCOUNT_PERCENT = 50
