import { CurrentEnvironment } from 'root-constants/common'

export function getCurrentEnv(): CurrentEnvironment {
  const env = (
    document.querySelector('meta[name="current-env"]') as HTMLMetaElement
  ).content

  if (env.startsWith('prod')) return CurrentEnvironment.PROD

  return CurrentEnvironment.DEV
}
