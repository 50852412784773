import { Option, OptionType } from 'components/Option'
import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'root-constants/pages'
import { setAnswersAction } from 'root-redux/actions/common'
import { QuestionButton } from 'components/QuestionButton'
import { StyledOption } from 'components/Option.styles'
import { StyledChildInTrouble as S } from './ChildInTrouble.styles'

const PAGE_NAME = 'Child in trouble'
const QUESTION =
  'Do you want to receive notifications when your child is in trouble?'
const OPTIONS_VALUES = {
  yes: 'Yes',
  no: 'No',
  notSure: 'Not sure',
}

const Component: TDynamicPageComponent = () => {
  const { onNextStep, currentPageId } = useContext(FlowContext)
  const dispatch = useDispatch()

  const handleContinue = useCallback(
    (answer) => {
      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: answer,
          pageId: currentPageId,
          pageName: PAGE_NAME,
        }),
      )
      onNextStep()
    },
    [currentPageId, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      name: currentPageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option {...optionProps} value={OPTIONS_VALUES.yes}>
            <QuestionButton>{OPTIONS_VALUES.yes}</QuestionButton>
          </Option>
          <Option {...optionProps} value={OPTIONS_VALUES.no}>
            <QuestionButton>{OPTIONS_VALUES.no}</QuestionButton>
          </Option>
          <Option {...optionProps} value={OPTIONS_VALUES.notSure}>
            <QuestionButton>{OPTIONS_VALUES.notSure}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </S.Wrapper>
  )
}

Component.id = PageId.CHILD_IN_TROUBLE_1
Component.hasHeader = true

export const ChildInTroubleVariant1 = Component
