import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectUUID } from 'root-redux/selects/user'

export const useFacebookPixelInitialization = (
  facebookPixelId: string,
): void => {
  const uuid = useSelector(selectUUID)

  useLayoutEffect(() => {
    window.fbq('init', facebookPixelId, { external_id: uuid })
    window.fbq('track', 'PageView')
  }, [facebookPixelId, uuid])
}
