import { ISelectPlanItemProps } from 'modules/subscriptions/types'
import React, { useMemo } from 'react'
import { StyledPlanItemVariant1 as S } from './PlanItemVariant1.styles'

export const PlanItemVariant1: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    periodQuantity,
    periodName,
    customPrice,
    customPriceInterval,
    oldPrice,
    isDefault,
  },
  isSelected,
}) => {
  const integerCustomPrice = useMemo(
    () => (customPrice ? Math.trunc(customPrice) : 0),
    [customPrice],
  )

  const fractionalCustomPrice = useMemo(
    () =>
      customPrice
        ? Number((customPrice - integerCustomPrice).toFixed(2)) * 100
        : '00',
    [customPrice, integerCustomPrice],
  )

  return (
    <S.PlanItem data-is-selected={isSelected} data-is-default={isDefault}>
      <S.PlanTitle>
        {periodQuantity}-{periodName}
        <br />
        plan
      </S.PlanTitle>
      {oldPrice && <S.OldPlanPrice>${oldPrice.toFixed(2)}</S.OldPlanPrice>}
      {customPrice && (
        <S.PlanPrice>
          <strong data-fractional={fractionalCustomPrice}>
            {integerCustomPrice}
          </strong>
          <sub>per {customPriceInterval}</sub>
        </S.PlanPrice>
      )}
    </S.PlanItem>
  )
}
