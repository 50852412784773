import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'components/styles'
import { HEADER_HEIGHT, MediaBreakpoint } from 'root-constants/common'
import styled from 'styled-components'
import checkboxViolet from 'assets/images/checkbox-violet.svg'
import bgImg from 'assets/images/subscriptions-bg.svg'
import { Button } from 'components/Button'
import { SelectPlanBlockVariant1 } from 'modules/subscriptions/components/variant1/SelectPlanBlockVariant1'
import { SubscriptionDescription } from 'modules/subscriptions/components/SubscriptionDescription'
import { Guarantee } from 'modules/subscriptions/components/Guarantee'
import { Security } from 'modules/subscriptions/components/Security'

export const StyledSubscriptions = {
  Wrapper: styled.div`
    ${basePageStyles};
    min-height: 100%;
    padding-top: ${HEADER_HEIGHT}px;
    overflow-x: hidden;
  `,
  Column: styled.div`
    ${baseColumnStyles};

    &[data-has-bg] {
      position: relative;

      ::before {
        content: '';
        display: block;
        width: 100vw;
        aspect-ratio: 1/1;
        background-image: url(${bgImg});
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        z-index: -1;
      }
    }
  `,
  Sharing: styled.p`
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    padding: 19px 0;
    margin-bottom: 37px;
    background-image: linear-gradient(
      266deg,
      rgba(225, 204, 252, 0.5) 45%,
      rgba(239, 208, 226, 0.5) 100%
    );
    border-radius: 16px;

    strong {
      font-weight: 800;
    }
  `,
  Title: styled.h1`
    font-size: 32px;
    line-height: 44px;
    font-weight: 800;
    text-align: center;
  `,
  Title2: styled.h1`
    padding: 30px 0 16px;
    font-size: 32px;
    line-height: 44px;
    font-weight: 800;
    text-align: center;
  `,
  SpecialOfferText: styled.div`
    display: inline-block;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 15px;
    padding: 10px;
    color: #fc5774;
    border: 2px solid #f8cad2;
    border-radius: 8px;

    strong {
      font-weight: 800;
    }
  `,
  SpecialOfferContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #fc5774;
    margin: 0 -10px 37px;
  `,
  Text: styled.span`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  `,
  Subtitle: styled.h2`
    ${baseTitleStyles};
  `,
  Subtitle2: styled.h2`
    padding-bottom: 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  `,
  ImageContainer: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin-top: 40px;
    aspect-ratio: 375/387;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
    }
  `,
  SubscriptionsTitle: styled.h2`
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  `,
  ContentItem: styled.div`
    position: relative;
    margin-bottom: 20px;
    padding-left: 35px;
    font-size: 16px;
    line-height: 24px;

    strong {
      font-weight: 700;
    }

    ::before {
      content: url(${checkboxViolet});
      position: absolute;
      left: 0;
      top: 0;
      display: block;
    }
  `,
  Button: styled(Button)`
    height: 56px;
    line-height: 56px;
  `,
  SelectPlanBlock: styled(SelectPlanBlockVariant1)`
    margin-bottom: 28px;
  `,
  SubscriptionDescription: styled(SubscriptionDescription)`
    margin: 12px 0 40px;
  `,
  Guarantee: styled(Guarantee)`
    margin-bottom: 40px;
  `,
  Security: styled(Security)`
    margin-top: 40px;
  `,
}
