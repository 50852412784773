import {
  baseColumnStyles,
  basePageStyles,
  baseTitleStyles,
} from 'components/styles'
import { HEADER_HEIGHT } from 'root-constants/common'
import styled, { css } from 'styled-components'

const progressValueStyles = css`
  border-radius: 3px;
  transition: all 0.2s ease-out;
`

export const StyledPreparingFlow = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: ${HEADER_HEIGHT}px;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  Title: styled.h1`
    ${baseTitleStyles};
    margin-bottom: 112px;
  `,
  ProgressBarsList: styled.ul`
    width: 100%;
  `,
  ProgressContainer: styled.li`
    width: 100%;
    margin-bottom: 32px;
  `,
  ProgressHeader: styled.header`
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  ProgressTitle: styled.span`
    font-weight: 800;
    font-size: 14px;
    line-height: 22px;
  `,

  ProgressPercent: styled.span`
    font-size: 11px;
    line-height: 20px;
    color: #7b8898;

    ::after {
      content: '%';
    }
  `,
  ProgressBar: styled.progress<{ color: string }>`
    width: 100%;
    height: 6px;
    appearance: none;
    border: none;
    display: block;
    border-radius: 3px;
    overflow: hidden;
    background-color: #eff5f7;

    &::-webkit-progress-bar {
      background-color: #eff5f7;
    }
    &::-webkit-progress-value {
      ${progressValueStyles};
      background-color: ${({ color }) => color};
    }
    &::-moz-progress-bar {
      ${progressValueStyles};
      background-color: ${({ color }) => color};
    }
    &::-ms-fill {
      ${progressValueStyles};
      background-color: ${({ color }) => color};
    }
  `,
}
