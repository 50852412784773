import React, { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ID_TO_PAGE_MAP } from '../constants/pages'
import { selectCurrentVariantSteps } from '../redux/selects/common'
import { FlowContext } from './FlowContext'
import { StyledProgressBar as S } from './ProgressBar.styles'

export const ProgressBar: React.FC = () => {
  const { currentPageId } = useContext(FlowContext)
  const steps = useSelector(selectCurrentVariantSteps)

  const pagesWithProgressBar = useMemo(
    () =>
      steps.filter(
        ({ id }) =>
          Object.values(ID_TO_PAGE_MAP).find((component) => component.id === id)
            ?.hasProgressBar,
      ),
    [steps],
  )

  const currentPageIndex = useMemo(
    () => pagesWithProgressBar.findIndex(({ id }) => id === currentPageId),
    [currentPageId, pagesWithProgressBar],
  )

  return currentPageIndex < 0 ? null : (
    <S.Wrapper>
      <S.Progress
        max={pagesWithProgressBar.length + 1}
        value={currentPageIndex + 1}
      />
    </S.Wrapper>
  )
}
