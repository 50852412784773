import { BASE_COLUMN_WIDTH, Color } from 'root-constants/common'
import styled, { css } from 'styled-components'

export const StyledButtonBase = css`
  width: ${BASE_COLUMN_WIDTH}px;
  height: 62px;
  margin: 0 auto;
  display: block;
  padding: 0 20px;
  cursor: pointer;
  border: none;
  border-radius: 30px;
  outline: none;
  font-size: 20px;
  font-weight: 700;
  line-height: 62px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  background-color: ${Color.ACCENT_VIOLET};
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  transition: background-color 0.2s ease-out;

  &:disabled {
    background-color: rgba(99, 54, 255, 0.3);
  }
`

export const StyledButton = styled.button`
  ${StyledButtonBase};
`
