import React, { useContext } from 'react'
import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import { PageId } from 'root-constants/pages'
import onePlanForEveryone from 'assets/images/one-plan-for-everyone.webp'
import { Button } from 'components/Button'
import { StyledOnePlanForEveryone as S } from './OnePlanForEveryone.styles'

const Component: TDynamicPageComponent = () => {
  const { onNextStep } = useContext(FlowContext)

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <img src={onePlanForEveryone} alt="one-plan-for-everyone" />
      </S.ImageContainer>
      <S.Column>
        <S.Text>One family plan for everyone you care</S.Text>
        <Button onClick={onNextStep}>Continue</Button>
      </S.Column>
    </S.Wrapper>
  )
}

Component.id = PageId.ONE_PLAN_FOR_EVERYONE_1
Component.hasHeader = true

export const OnePlanForEveryoneVariant1 = Component
