import React, { useContext } from 'react'
import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import { PageId } from 'root-constants/pages'
import stayConnected from 'assets/images/stay-connected.webp'
import { Button } from 'components/Button'
import { StyledStayConnected as S } from './StayConnected.styles'

const Component: TDynamicPageComponent = () => {
  const { onNextStep } = useContext(FlowContext)

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <img src={stayConnected} alt="stay-connected" />
      </S.ImageContainer>
      <S.Column>
        <S.Text>Stay connected with your family</S.Text>
        <Button onClick={onNextStep}>Continue</Button>
      </S.Column>
    </S.Wrapper>
  )
}

Component.id = PageId.STAY_CONNECTED_1
Component.hasHeader = true

export const StayConnectedVariant1 = Component
