import reviewAvatar1 from 'assets/images/review-avatar-1.jpg'
import reviewAvatar2 from 'assets/images/review-avatar-2.jpg'
import reviewAvatar3 from 'assets/images/review-avatar-3.jpg'
import reviewAvatar4 from 'assets/images/review-avatar-4.webp'
import reviewAvatar5 from 'assets/images/review-avatar-5.webp'
import reviewAvatar6 from 'assets/images/review-avatar-6.webp'
import reviewAvatar7 from 'assets/images/review-avatar-7.webp'
import reviewAvatar8 from 'assets/images/review-avatar-8.webp'
import reviewAvatar9 from 'assets/images/review-avatar-9.webp'
import { useURLSearchData } from 'hooks/useURLSearchData'
import React, { useMemo } from 'react'
import { GOALS_PARAM_KEY, UrlDataGoals } from 'root-constants/urlData'
import { Swiper, SwiperSlide } from 'swiper/react'
import { StyledReviewsSlider as S } from './ReviewSlider.styles'

const REVIEWS_DATA = {
  default: [
    {
      avatar: reviewAvatar1,
      userName: 'LinaXoxo',
      reviewText: `“I’ve been looking for apps that could track effectively, ever since my fiance started acting suspicious. It came to me as a disappointment when i found out that he of over six years was an infidel.” `,
      borderColor: '#EED0E4',
    },
    {
      avatar: reviewAvatar2,
      userName: 'David Eide',
      reviewText: `“I am very curious about this APP,  ex-wife and best friend had a couple year romance together, I know this is exactly what would have saved me years of mental anguish finding out the truth sooner.” `,
      borderColor: '#D9D8F6',
    },
    {
      avatar: reviewAvatar3,
      userName: 'Kaung Min Tin',
      reviewText: `“It's working properly and can track approximate location.” `,
      borderColor: '#F0DACE',
    },
  ],
  kidGoalReviews: [
    {
      avatar: reviewAvatar4,
      userName: 'Suzie',
      reviewText: `“Famio allows me to keep up with my teenage daughter due to safety concerns and the uncertainties of the world today. There is too much going on not to know where your kids are.” `,
      borderColor: '#EED0E4',
    },
    {
      avatar: reviewAvatar5,
      userName: 'Maria S.',
      reviewText: `“I have two teenagers that drive. This is the best app – I feel comfortable knowing where my kids are and love that it also tracks speed.” `,
      borderColor: '#D9D8F6',
    },
    {
      avatar: reviewAvatar6,
      userName: 'David Eide',
      reviewText: `“Love this app! It allows me to make sure my kids are safe and where they are supposed to be.” `,
      borderColor: '#F0DACE',
    },
  ],
  friendGoalReviews: [
    {
      avatar: reviewAvatar7,
      userName: 'Suzie',
      reviewText: `“Basically I use this with my friends and it is honestly one of the best ways to keep track of each others with how things are in the world right now & just makes us feel safe!” `,
      borderColor: '#EED0E4',
    },
    {
      avatar: reviewAvatar8,
      userName: 'Maria S.',
      reviewText: `“Famio allows you to locate a person in an emergency situation and increases security for family members or friends.” `,
      borderColor: '#D9D8F6',
    },
    {
      avatar: reviewAvatar9,
      userName: 'Mark',
      reviewText: `“Very helpful to stay in touch with family members when on trips and know they are ok, when they get home, use it often.” `,
      borderColor: '#F0DACE',
    },
  ],
  severalGoalsReviews: [
    {
      avatar: reviewAvatar7,
      userName: 'Suzie',
      reviewText: `“Famio allows me to keep up with my teenage daughter due to safety concerns and the uncertainties of the world today. There is too much going on not to know where your kids are.” `,
      borderColor: '#EED0E4',
    },
    {
      avatar: reviewAvatar8,
      userName: 'Maria S.',
      reviewText: `“Famio allows you to locate a person in an emergency situation and increases security for family members or friends.” `,
      borderColor: '#D9D8F6',
    },
    {
      avatar: reviewAvatar9,
      userName: 'Mark',
      reviewText: `“Very helpful to stay in touch with family members when on trips and know they are ok, when they get home, use it often.” `,
      borderColor: '#F0DACE',
    },
  ],
}

type TProps = React.HTMLAttributes<HTMLDivElement>

export const ReviewsSlider: React.FC<TProps> = (props) => {
  const { getDataFromURL } = useURLSearchData()

  const goals = useMemo(() => getDataFromURL(GOALS_PARAM_KEY), [getDataFromURL])

  const currentReviewsData = useMemo(() => {
    if (!goals || goals === UrlDataGoals.PARTNER) {
      return REVIEWS_DATA.default
    }
    if (goals === UrlDataGoals.FRIEND) {
      return REVIEWS_DATA.friendGoalReviews
    }
    if (goals === UrlDataGoals.KID) {
      return REVIEWS_DATA.kidGoalReviews
    }
    return REVIEWS_DATA.severalGoalsReviews
  }, [goals])

  return (
    <S.Wrapper {...props}>
      <Swiper slidesPerView="auto" spaceBetween={20} centeredSlides>
        {currentReviewsData.map((reviewData) => (
          <SwiperSlide key={reviewData.userName}>
            <S.Card style={{ borderColor: `${reviewData.borderColor}` }}>
              <S.Avatar
                width="66"
                height="66"
                src={reviewData.avatar}
                loading="lazy"
              />
              <S.CardTitle>{reviewData.userName}</S.CardTitle>
              <S.Recommendation />
              <S.CardText>{reviewData.reviewText}</S.CardText>
            </S.Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </S.Wrapper>
  )
}
